article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block
}

audio, canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1
}

audio:not([controls]) {
    display: none
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

a:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

a:hover, a:active {
    outline: 0
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

img {
    max-width: 100%;
    width: auto\9;
    height: auto;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic
}

#map_canvas img, .google-maps img {
    max-width: none
}

button, input, select, textarea {
    margin: 0;
    font-size: 100%;
    vertical-align: middle
}

button, input {
    *overflow: visible;
    line-height: normal
}

button::-moz-focus-inner, input::-moz-focus-inner {
    padding: 0;
    border: 0
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer
}

label, select, button, input[type="button"], input[type="reset"], input[type="submit"], input[type="radio"], input[type="checkbox"] {
    cursor: pointer
}

input[type="search"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none
}

textarea {
    overflow: auto;
    vertical-align: top
}

@media print {
    * {
        text-shadow: none !important;
        color: #000 !important;
        background: transparent !important;
        box-shadow: none !important
    }

    a, a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " (" attr(href) ")"
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
        content: ""
    }

    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    tr, img {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    @page {
        margin: .5cm
    }

    p, h2, h3 {
        orphans: 3;
        widows: 3
    }

    h2, h3 {
        page-break-after: avoid
    }
}

body {
    height: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    background-color: white
}

a {
    color: #0088cc;
    text-decoration: none
}

a:hover, a:focus {
    color: #005580;
    text-decoration: underline
}

.img-rounded {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.img-polaroid {
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1)
}

.img-circle {
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    border-radius: 500px
}

.row {
    margin-left: -20px;
    *zoom: 1
}

.row:before, .row:after {
    display: table;
    content: "";
    line-height: 0
}

.row:after {
    clear: both
}

[class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 20px
}

.container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
    width: 940px
}

.span12 {
    width: 940px
}

.span11 {
    width: 860px
}

.span10 {
    width: 780px
}

.span9 {
    width: 700px
}

.span8 {
    width: 620px
}

.span7 {
    width: 540px
}

.span6 {
    width: 460px
}

.span5 {
    width: 380px
}

.span4 {
    width: 300px
}

.span3 {
    width: 220px
}

.span2 {
    width: 140px
}

.span1 {
    width: 60px
}

.offset12 {
    margin-left: 980px
}

.offset11 {
    margin-left: 900px
}

.offset10 {
    margin-left: 820px
}

.offset9 {
    margin-left: 740px
}

.offset8 {
    margin-left: 660px
}

.offset7 {
    margin-left: 580px
}

.offset6 {
    margin-left: 500px
}

.offset5 {
    margin-left: 420px
}

.offset4 {
    margin-left: 340px
}

.offset3 {
    margin-left: 260px
}

.offset2 {
    margin-left: 180px
}

.offset1 {
    margin-left: 100px
}

.row-fluid {
    width: 100%;
    *zoom: 1
}

.row-fluid:before, .row-fluid:after {
    display: table;
    content: "";
    line-height: 0
}

.row-fluid:after {
    clear: both
}

.row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.12766%;
    *margin-left: 2.07447%
}

.row-fluid [class*="span"]:first-child {
    margin-left: 0
}

.row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.12766%
}

.row-fluid .span12 {
    width: 100%;
    *width: 99.94681%
}

.row-fluid .span11 {
    width: 91.48936%;
    *width: 91.43617%
}

.row-fluid .span10 {
    width: 82.97872%;
    *width: 82.92553%
}

.row-fluid .span9 {
    width: 74.46809%;
    *width: 74.41489%
}

.row-fluid .span8 {
    width: 65.95745%;
    *width: 65.90426%
}

.row-fluid .span7 {
    width: 57.44681%;
    *width: 57.39362%
}

.row-fluid .span6 {
    width: 48.93617%;
    *width: 48.88298%
}

.row-fluid .span5 {
    width: 40.42553%;
    *width: 40.37234%
}

.row-fluid .span4 {
    width: 31.91489%;
    *width: 31.8617%
}

.row-fluid .span3 {
    width: 23.40426%;
    *width: 23.35106%
}

.row-fluid .span2 {
    width: 14.89362%;
    *width: 14.84043%
}

.row-fluid .span1 {
    width: 6.38298%;
    *width: 6.32979%
}

.row-fluid .offset12 {
    margin-left: 104.25532%;
    *margin-left: 104.14894%
}

.row-fluid .offset12:first-child {
    margin-left: 102.12766%;
    *margin-left: 102.02128%
}

.row-fluid .offset11 {
    margin-left: 95.74468%;
    *margin-left: 95.6383%
}

.row-fluid .offset11:first-child {
    margin-left: 93.61702%;
    *margin-left: 93.51064%
}

.row-fluid .offset10 {
    margin-left: 87.23404%;
    *margin-left: 87.12766%
}

.row-fluid .offset10:first-child {
    margin-left: 85.10638%;
    *margin-left: 85.0%
}

.row-fluid .offset9 {
    margin-left: 78.7234%;
    *margin-left: 78.61702%
}

.row-fluid .offset9:first-child {
    margin-left: 76.59574%;
    *margin-left: 76.48936%
}

.row-fluid .offset8 {
    margin-left: 70.21277%;
    *margin-left: 70.10638%
}

.row-fluid .offset8:first-child {
    margin-left: 68.08511%;
    *margin-left: 67.97872%
}

.row-fluid .offset7 {
    margin-left: 61.70213%;
    *margin-left: 61.59574%
}

.row-fluid .offset7:first-child {
    margin-left: 59.57447%;
    *margin-left: 59.46809%
}

.row-fluid .offset6 {
    margin-left: 53.19149%;
    *margin-left: 53.08511%
}

.row-fluid .offset6:first-child {
    margin-left: 51.06383%;
    *margin-left: 50.95745%
}

.row-fluid .offset5 {
    margin-left: 44.68085%;
    *margin-left: 44.57447%
}

.row-fluid .offset5:first-child {
    margin-left: 42.55319%;
    *margin-left: 42.44681%
}

.row-fluid .offset4 {
    margin-left: 36.17021%;
    *margin-left: 36.06383%
}

.row-fluid .offset4:first-child {
    margin-left: 34.04255%;
    *margin-left: 33.93617%
}

.row-fluid .offset3 {
    margin-left: 27.65957%;
    *margin-left: 27.55319%
}

.row-fluid .offset3:first-child {
    margin-left: 25.53191%;
    *margin-left: 25.42553%
}

.row-fluid .offset2 {
    margin-left: 19.14894%;
    *margin-left: 19.04255%
}

.row-fluid .offset2:first-child {
    margin-left: 17.02128%;
    *margin-left: 16.91489%
}

.row-fluid .offset1 {
    margin-left: 10.6383%;
    *margin-left: 10.53191%
}

.row-fluid .offset1:first-child {
    margin-left: 8.51064%;
    *margin-left: 8.40426%
}

[class*="span"].hide, .row-fluid [class*="span"].hide {
    display: none
}

[class*="span"].pull-right, .row-fluid [class*="span"].pull-right {
    float: right
}

.container {
    margin-right: auto;
    margin-left: auto;
    *zoom: 1
}

.container:before, .container:after {
    display: table;
    content: "";
    line-height: 0
}

.container:after {
    clear: both
}

.container-fluid {
    padding-right: 20px;
    padding-left: 20px;
    *zoom: 1
}

.container-fluid:before, .container-fluid:after {
    display: table;
    content: "";
    line-height: 0
}

.container-fluid:after {
    clear: both
}

p {
    margin: 0 0 10px
}

.lead {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 200;
    line-height: 30px
}

small {
    font-size: 85%
}

strong {
    font-weight: 700
}

em {
    font-style: italic
}

cite {
    font-style: normal
}

.muted {
    color: #999999
}

a.muted:hover, a.muted:focus {
    color: gray
}

.text-warning {
    color: #c09853
}

a.text-warning:hover, a.text-warning:focus {
    color: #a47e3c
}

.text-error {
    color: #b94a48
}

a.text-error:hover, a.text-error:focus {
    color: #953b39
}

.text-info {
    color: #3a87ad
}

a.text-info:hover, a.text-info:focus {
    color: #2d6987
}

.text-success {
    color: #468847
}

a.text-success:hover, a.text-success:focus {
    color: #356635
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
    font-family: inherit;
    font-weight: 700;
    line-height: 20px;
    color: inherit;
    text-rendering: optimizelegibility
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-weight: 400;
    line-height: 1;
    color: #999999
}

h1, h2, h3 {
    line-height: 40px
}

h1 {
    font-size: 38.5px
}

h2 {
    font-size: 31.5px
}

h3 {
    font-size: 24.5px
}

h4 {
    font-size: 17.5px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 11.9px
}

h1 small {
    font-size: 24.5px
}

h2 small {
    font-size: 17.5px
}

h3 small {
    font-size: 14px
}

h4 small {
    font-size: 14px
}

.page-header {
    padding-bottom: 9px;
    margin: 20px 0 30px;
    border-bottom: 1px solid #eeeeee
}

ul, ol {
    padding: 0;
    margin: 0 0 10px 25px
}

ul ul, ul ol, ol ol, ol ul {
    margin-bottom: 0
}

li {
    line-height: 20px
}

ul.unstyled, ol.unstyled {
    margin-left: 0;
    list-style: none
}

ul.inline, ol.inline {
    margin-left: 0;
    list-style: none
}

ul.inline > li, ol.inline > li {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding-left: 5px;
    padding-right: 5px
}

dl {
    margin-bottom: 20px
}

dt, dd {
    line-height: 20px
}

dt {
    font-weight: 700
}

dd {
    margin-left: 10px
}

.dl-horizontal {
    *zoom: 1
}

.dl-horizontal:before, .dl-horizontal:after {
    display: table;
    content: "";
    line-height: 0
}

.dl-horizontal:after {
    clear: both
}

.dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.dl-horizontal dd {
    margin-left: 180px
}

hr {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid white
}

abbr[title], abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted #999999
}

abbr.initialism {
    font-size: 90%;
    text-transform: uppercase
}

blockquote {
    padding: 0 0 0 15px;
    margin: 0 0 20px;
    border-left: 5px solid #eeeeee
}

blockquote p {
    margin-bottom: 0;
    font-size: 17.5px;
    font-weight: 300;
    line-height: 1.25
}

blockquote small {
    display: block;
    line-height: 20px;
    color: #999999
}

blockquote small:before {
    content: '\2014 \00A0'
}

blockquote.pull-right {
    float: right;
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eeeeee;
    border-left: 0
}

blockquote.pull-right p, blockquote.pull-right small {
    text-align: right
}

blockquote.pull-right small:before {
    content: ''
}

blockquote.pull-right small:after {
    content: '\00A0 \2014'
}

q:before, q:after, blockquote:before, blockquote:after {
    content: ""
}

address {
    display: block;
    margin-bottom: 20px;
    font-style: normal;
    line-height: 20px
}

code, pre {
    padding: 0 3px 2px;
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
    font-size: 12px;
    color: #333333;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

code {
    padding: 2px 4px;
    color: #d14;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    white-space: nowrap
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 20px;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre;
    white-space: pre-wrap;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

pre.prettyprint {
    margin-bottom: 20px
}

pre code {
    padding: 0;
    color: inherit;
    white-space: pre;
    white-space: pre-wrap;
    background-color: transparent;
    border: 0
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

form {
    margin: 0 0 20px
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 40px;
    color: #333333;
    border: 0;
    border-bottom: 1px solid #e5e5e5
}

legend small {
    font-size: 15px;
    color: #999999
}

label, input, button, select, textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px
}

label {
    display: block;
    margin-bottom: 5px
}

select, textarea, input[type="text"]:not(.m-input__field), input[type="password"]:not(.m-input__field), input[type="datetime"]:not(.m-input__field), input[type="datetime-local"]:not(.m-input__field), input[type="date"]:not(.m-input__field), input[type="month"]:not(.m-input__field), input[type="time"]:not(.m-input__field), input[type="week"]:not(.m-input__field), input[type="number"]:not(.m-input__field), input[type="email"]:not(.m-input__field), input[type="url"]:not(.m-input__field), input[type="search"]:not(.m-input__field), input[type="tel"]:not(.m-input__field), input[type="color"]:not(.m-input__field), .uneditable-input {
    display: inline-block;
    height: 20px;
    padding: 4px 6px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #555555;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    vertical-align: middle
}

input, textarea, .uneditable-input {
    width: 206px
}

textarea {
    height: auto
}

textarea, input[type="text"]:not(.m-input__field), input[type="password"]:not(.m-input__field), input[type="datetime"]:not(.m-input__field), input[type="datetime-local"]:not(.m-input__field), input[type="date"]:not(.m-input__field), input[type="month"]:not(.m-input__field), input[type="time"]:not(.m-input__field), input[type="week"]:not(.m-input__field), input[type="number"]:not(.m-input__field), input[type="email"]:not(.m-input__field), input[type="url"]:not(.m-input__field), input[type="search"]:not(.m-input__field), input[type="tel"]:not(.m-input__field), input[type="color"]:not(.m-input__field), .uneditable-input {
    background-color: white;
    border: 1px solid #cccccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    -moz-transition: border linear .2s, box-shadow linear .2s;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    outline: thin dotted  \9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)
}

input[type="radio"], input[type="checkbox"] {
    margin: 4px 0 0;
    *margin-top: 0;
    margin-top: 1px  \9;
    line-height: normal
}

input[type="file"], input[type="image"], input[type="submit"], input[type="reset"], input[type="button"], input[type="radio"], input[type="checkbox"] {
    width: auto
}

select, input[type="file"] {
    height: 30px;
    *margin-top: 4px;
    line-height: 30px
}

select {
    width: 220px;
    border: 1px solid #cccccc;
    background-color: white
}

select[multiple], select[size] {
    height: auto
}

select:focus, input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.uneditable-input, .uneditable-textarea {
    color: #999999;
    background-color: #fcfcfc;
    border-color: #cccccc;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    cursor: not-allowed
}

.uneditable-input {
    overflow: hidden;
    white-space: nowrap
}

.uneditable-textarea {
    width: auto;
    height: auto
}

input:-moz-placeholder, textarea:-moz-placeholder {
    color: #999999
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #999999
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #999999
}

.radio, .checkbox {
    min-height: 20px;
    padding-left: 20px
}

.radio input[type="radio"], .checkbox input[type="checkbox"] {
    float: left;
    margin-left: -20px
}

.controls > .radio:first-child, .controls > .checkbox:first-child {
    padding-top: 5px
}

.radio.inline, .checkbox.inline {
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 0;
    vertical-align: middle
}

.radio.inline + .radio.inline, .checkbox.inline + .checkbox.inline {
    margin-left: 10px
}

.input-mini {
    width: 60px
}

.input-small {
    width: 90px
}

.input-medium {
    width: 150px
}

.input-large {
    width: 210px
}

.input-xlarge {
    width: 270px
}

.input-xxlarge {
    width: 530px
}

input[class*="span"], select[class*="span"], textarea[class*="span"], .uneditable-input[class*="span"], .row-fluid input[class*="span"], .row-fluid select[class*="span"], .row-fluid textarea[class*="span"], .row-fluid .uneditable-input[class*="span"] {
    float: none;
    margin-left: 0
}

.input-append input[class*="span"], .input-append .uneditable-input[class*="span"], .input-prepend input[class*="span"], .input-prepend .uneditable-input[class*="span"], .row-fluid input[class*="span"], .row-fluid select[class*="span"], .row-fluid textarea[class*="span"], .row-fluid .uneditable-input[class*="span"], .row-fluid .input-prepend [class*="span"], .row-fluid .input-append [class*="span"] {
    display: inline-block
}

input, textarea, .uneditable-input {
    margin-left: 0
}

.controls-row [class*="span"] + [class*="span"] {
    margin-left: 20px
}

input.span12, textarea.span12, .uneditable-input.span12 {
    width: 926px
}

input.span11, textarea.span11, .uneditable-input.span11 {
    width: 846px
}

input.span10, textarea.span10, .uneditable-input.span10 {
    width: 766px
}

input.span9, textarea.span9, .uneditable-input.span9 {
    width: 686px
}

input.span8, textarea.span8, .uneditable-input.span8 {
    width: 606px
}

input.span7, textarea.span7, .uneditable-input.span7 {
    width: 526px
}

input.span6, textarea.span6, .uneditable-input.span6 {
    width: 446px
}

input.span5, textarea.span5, .uneditable-input.span5 {
    width: 366px
}

input.span4, textarea.span4, .uneditable-input.span4 {
    width: 286px
}

input.span3, textarea.span3, .uneditable-input.span3 {
    width: 206px
}

input.span2, textarea.span2, .uneditable-input.span2 {
    width: 126px
}

input.span1, textarea.span1, .uneditable-input.span1 {
    width: 46px
}

.controls-row {
    *zoom: 1
}

.controls-row:before, .controls-row:after {
    display: table;
    content: "";
    line-height: 0
}

.controls-row:after {
    clear: both
}

.controls-row [class*="span"], .row-fluid .controls-row [class*="span"] {
    float: left
}

.controls-row .checkbox[class*="span"], .controls-row .radio[class*="span"] {
    padding-top: 5px
}

input[disabled], select[disabled], textarea[disabled], input[readonly], select[readonly], textarea[readonly] {
    cursor: not-allowed;
    background-color: #eeeeee
}

input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"][readonly], input[type="checkbox"][readonly] {
    background-color: transparent
}

.control-group.warning .control-label, .control-group.warning .help-block, .control-group.warning .help-inline {
    color: #c09853
}

.control-group.warning .checkbox, .control-group.warning .radio, .control-group.warning input, .control-group.warning select, .control-group.warning textarea {
    color: #c09853
}

.control-group.warning input, .control-group.warning select, .control-group.warning textarea {
    border-color: #c09853;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.control-group.warning input:focus, .control-group.warning select:focus, .control-group.warning textarea:focus {
    border-color: #a47e3c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e
}

.control-group.warning .input-prepend .add-on, .control-group.warning .input-append .add-on {
    color: #c09853;
    background-color: #fcf8e3;
    border-color: #c09853
}

.control-group.error .control-label, .control-group.error .help-block, .control-group.error .help-inline {
    color: #b94a48
}

.control-group.error .checkbox, .control-group.error .radio, .control-group.error input, .control-group.error select, .control-group.error textarea {
    color: #b94a48
}

.control-group.error input, .control-group.error select, .control-group.error textarea {
    border-color: #b94a48;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.control-group.error input:focus, .control-group.error select:focus, .control-group.error textarea:focus {
    border-color: #953b39;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392
}

.control-group.error .input-prepend .add-on, .control-group.error .input-append .add-on {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #b94a48
}

.control-group.success .control-label, .control-group.success .help-block, .control-group.success .help-inline {
    color: #468847
}

.control-group.success .checkbox, .control-group.success .radio, .control-group.success input, .control-group.success select, .control-group.success textarea {
    color: #468847
}

.control-group.success input, .control-group.success select, .control-group.success textarea {
    border-color: #468847;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.control-group.success input:focus, .control-group.success select:focus, .control-group.success textarea:focus {
    border-color: #356635;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b
}

.control-group.success .input-prepend .add-on, .control-group.success .input-append .add-on {
    color: #468847;
    background-color: #dff0d8;
    border-color: #468847
}

.control-group.info .control-label, .control-group.info .help-block, .control-group.info .help-inline {
    color: #3a87ad
}

.control-group.info .checkbox, .control-group.info .radio, .control-group.info input, .control-group.info select, .control-group.info textarea {
    color: #3a87ad
}

.control-group.info input, .control-group.info select, .control-group.info textarea {
    border-color: #3a87ad;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.control-group.info input:focus, .control-group.info select:focus, .control-group.info textarea:focus {
    border-color: #2d6987;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3
}

.control-group.info .input-prepend .add-on, .control-group.info .input-append .add-on {
    color: #3a87ad;
    background-color: #d9edf7;
    border-color: #3a87ad
}

input:focus:invalid, textarea:focus:invalid, select:focus:invalid {
    color: #b94a48;
    border-color: #ee5f5b
}

input:focus:invalid:focus, textarea:focus:invalid:focus, select:focus:invalid:focus {
    border-color: #e9322d;
    -webkit-box-shadow: 0 0 6px #f8b9b7;
    -moz-box-shadow: 0 0 6px #f8b9b7;
    box-shadow: 0 0 6px #f8b9b7
}

.form-actions {
    padding: 19px 20px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: whitesmoke;
    border-top: 1px solid #e5e5e5;
    *zoom: 1
}

.form-actions:before, .form-actions:after {
    display: table;
    content: "";
    line-height: 0
}

.form-actions:after {
    clear: both
}

.help-block, .help-inline {
    color: #595959
}

.help-block {
    display: block;
    margin-bottom: 10px
}

.help-inline {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
    padding-left: 5px
}

.input-append, .input-prepend {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: middle;
    font-size: 0;
    white-space: nowrap
}

.input-append input, .input-append select, .input-append .uneditable-input, .input-append .dropdown-menu, .input-append .popover, .input-prepend input, .input-prepend select, .input-prepend .uneditable-input, .input-prepend .dropdown-menu, .input-prepend .popover {
    font-size: 14px
}

.input-append input, .input-append select, .input-append .uneditable-input, .input-prepend input, .input-prepend select, .input-prepend .uneditable-input {
    position: relative;
    margin-bottom: 0;
    *margin-left: 0;
    vertical-align: top;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0
}

.input-append input:focus, .input-append select:focus, .input-append .uneditable-input:focus, .input-prepend input:focus, .input-prepend select:focus, .input-prepend .uneditable-input:focus {
    z-index: 2
}

.input-append .add-on, .input-prepend .add-on {
    display: inline-block;
    width: auto;
    height: 20px;
    min-width: 16px;
    padding: 4px 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    text-shadow: 0 1px 0 white;
    background-color: #eeeeee;
    border: 1px solid #ccc
}

.input-append .add-on, .input-append .btn, .input-append .btn-group > .dropdown-toggle, .input-prepend .add-on, .input-prepend .btn, .input-prepend .btn-group > .dropdown-toggle {
    vertical-align: top;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.input-append .active, .input-prepend .active {
    background-color: #a9dba9;
    border-color: #46a546
}

.input-prepend .add-on, .input-prepend .btn {
    margin-right: -1px
}

.input-prepend .add-on:first-child, .input-prepend .btn:first-child {
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px
}

.input-append input, .input-append select, .input-append .uneditable-input {
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px
}

.input-append input + .btn-group .btn:last-child, .input-append select + .btn-group .btn:last-child, .input-append .uneditable-input + .btn-group .btn:last-child {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0
}

.input-append .add-on, .input-append .btn, .input-append .btn-group {
    margin-left: -1px
}

.input-append .add-on:last-child, .input-append .btn:last-child, .input-append .btn-group:last-child > .dropdown-toggle {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0
}

.input-prepend.input-append input, .input-prepend.input-append select, .input-prepend.input-append .uneditable-input {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.input-prepend.input-append input + .btn-group .btn, .input-prepend.input-append select + .btn-group .btn, .input-prepend.input-append .uneditable-input + .btn-group .btn {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0
}

.input-prepend.input-append .add-on:first-child, .input-prepend.input-append .btn:first-child {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px
}

.input-prepend.input-append .add-on:last-child, .input-prepend.input-append .btn:last-child {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0
}

.input-prepend.input-append .btn-group:first-child {
    margin-left: 0
}

input.search-query {
    padding-right: 14px;
    padding-right: 4px  \9;
    padding-left: 14px;
    padding-left: 4px  \9;
    margin-bottom: 0;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px
}

.form-search .input-append .search-query, .form-search .input-prepend .search-query {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.form-search .input-append .search-query {
    -webkit-border-radius: 14px 0 0 14px;
    -moz-border-radius: 14px 0 0 14px;
    border-radius: 14px 0 0 14px
}

.form-search .input-append .btn {
    -webkit-border-radius: 0 14px 14px 0;
    -moz-border-radius: 0 14px 14px 0;
    border-radius: 0 14px 14px 0
}

.form-search .input-prepend .search-query {
    -webkit-border-radius: 0 14px 14px 0;
    -moz-border-radius: 0 14px 14px 0;
    border-radius: 0 14px 14px 0
}

.form-search .input-prepend .btn {
    -webkit-border-radius: 14px 0 0 14px;
    -moz-border-radius: 14px 0 0 14px;
    border-radius: 14px 0 0 14px
}

.form-search input, .form-search textarea, .form-search select, .form-search .help-inline, .form-search .uneditable-input, .form-search .input-prepend, .form-search .input-append, .form-inline input, .form-inline textarea, .form-inline select, .form-inline .help-inline, .form-inline .uneditable-input, .form-inline .input-prepend, .form-inline .input-append, .form-horizontal input, .form-horizontal textarea, .form-horizontal select, .form-horizontal .help-inline, .form-horizontal .uneditable-input, .form-horizontal .input-prepend, .form-horizontal .input-append {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-bottom: 0;
    vertical-align: middle
}

.form-search .hide, .form-inline .hide, .form-horizontal .hide {
    display: none
}

.form-search label, .form-inline label, .form-search .btn-group, .form-inline .btn-group {
    display: inline-block
}

.form-search .input-append, .form-inline .input-append, .form-search .input-prepend, .form-inline .input-prepend {
    margin-bottom: 0
}

.form-search .radio, .form-search .checkbox, .form-inline .radio, .form-inline .checkbox {
    padding-left: 0;
    margin-bottom: 0;
    vertical-align: middle
}

.form-search .radio input[type="radio"], .form-search .checkbox input[type="checkbox"], .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
    float: left;
    margin-right: 3px;
    margin-left: 0
}

.control-group {
    margin-bottom: 10px
}

legend + .control-group {
    margin-top: 20px;
    -webkit-margin-top-collapse: separate
}

.form-horizontal .control-group {
    margin-bottom: 20px;
    *zoom: 1
}

.form-horizontal .control-group:before, .form-horizontal .control-group:after {
    display: table;
    content: "";
    line-height: 0
}

.form-horizontal .control-group:after {
    clear: both
}

.form-horizontal .control-label {
    float: left;
    width: 160px;
    padding-top: 5px;
    text-align: right
}

.form-horizontal .controls {
    *display: inline-block;
    *padding-left: 20px;
    margin-left: 180px;
    *margin-left: 0
}

.form-horizontal .controls:first-child {
    *padding-left: 180px
}

.form-horizontal .help-block {
    margin-bottom: 0
}

.form-horizontal input + .help-block, .form-horizontal select + .help-block, .form-horizontal textarea + .help-block, .form-horizontal .uneditable-input + .help-block, .form-horizontal .input-prepend + .help-block, .form-horizontal .input-append + .help-block {
    margin-top: 10px
}

.form-horizontal .form-actions {
    padding-left: 180px
}

table {
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0
}

.table {
    width: 100%;
    margin-bottom: 20px
}

.table th, .table td {
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid var(--table-border-color);
}

.table th {
    font-weight: 700
}

.table thead th {
    vertical-align: bottom
}

.table caption + thead tr:first-child th, .table caption + thead tr:first-child td, .table colgroup + thead tr:first-child th, .table colgroup + thead tr:first-child td, .table thead:first-child tr:first-child th, .table thead:first-child tr:first-child td {
    border-top: 0
}

.table tbody + tbody {
    border-top: 2px solid var(--table-border-color)
}

.table .table {
    background-color: white
}

.table-condensed th, .table-condensed td {
    padding: 4px 5px
}

.table-bordered {
    border: 1px solid var(--table-border-color);
    border-collapse: separate;
    *border-collapse: collapse;
    border-left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.table-bordered th, .table-bordered td {
    border-left: 1px solid var(--table-border-color)
}

.table-bordered caption + thead tr:first-child th, .table-bordered caption + tbody tr:first-child th, .table-bordered caption + tbody tr:first-child td, .table-bordered colgroup + thead tr:first-child th, .table-bordered colgroup + tbody tr:first-child th, .table-bordered colgroup + tbody tr:first-child td, .table-bordered thead:first-child tr:first-child th, .table-bordered tbody:first-child tr:first-child th, .table-bordered tbody:first-child tr:first-child td {
    border-top: 0
}

.table-bordered thead:first-child tr:first-child > th:first-child, .table-bordered tbody:first-child tr:first-child > td:first-child, .table-bordered tbody:first-child tr:first-child > th:first-child {
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px
}

.table-bordered thead:first-child tr:first-child > th:last-child, .table-bordered tbody:first-child tr:first-child > td:last-child, .table-bordered tbody:first-child tr:first-child > th:last-child {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px
}

.table-bordered thead:last-child tr:last-child > th:first-child, .table-bordered tbody:last-child tr:last-child > td:first-child, .table-bordered tbody:last-child tr:last-child > th:first-child, .table-bordered tfoot:last-child tr:last-child > td:first-child, .table-bordered tfoot:last-child tr:last-child > th:first-child {
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px
}

.table-bordered thead:last-child tr:last-child > th:last-child, .table-bordered tbody:last-child tr:last-child > td:last-child, .table-bordered tbody:last-child tr:last-child > th:last-child, .table-bordered tfoot:last-child tr:last-child > td:last-child, .table-bordered tfoot:last-child tr:last-child > th:last-child {
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0
}

.table-bordered caption + thead tr:first-child th:first-child, .table-bordered caption + tbody tr:first-child td:first-child, .table-bordered colgroup + thead tr:first-child th:first-child, .table-bordered colgroup + tbody tr:first-child td:first-child {
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px
}

.table-bordered caption + thead tr:first-child th:last-child, .table-bordered caption + tbody tr:first-child td:last-child, .table-bordered colgroup + thead tr:first-child th:last-child, .table-bordered colgroup + tbody tr:first-child td:last-child {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px
}

.table-striped tbody > tr:nth-child(odd) > td, .table-striped tbody > tr:nth-child(odd) > th {
    background-color: #f9f9f9;
}

.table-striped tbody > tr:nth-child(even) > td, .table-striped tbody > tr:nth-child(even) > th {
    background-color: #ffffff;
}

.table-hover tbody tr:hover > td, .table-hover tbody tr:hover > th {
    background-color: whitesmoke
}

table td[class*="span"], table th[class*="span"], .row-fluid table td[class*="span"], .row-fluid table th[class*="span"] {
    display: table-cell;
    float: none;
    margin-left: 0
}

.table td.span1, .table th.span1 {
    float: none;
    width: 44px;
    margin-left: 0
}

.table td.span2, .table th.span2 {
    float: none;
    width: 124px;
    margin-left: 0
}

.table td.span3, .table th.span3 {
    float: none;
    width: 204px;
    margin-left: 0
}

.table td.span4, .table th.span4 {
    float: none;
    width: 284px;
    margin-left: 0
}

.table td.span5, .table th.span5 {
    float: none;
    width: 364px;
    margin-left: 0
}

.table td.span6, .table th.span6 {
    float: none;
    width: 444px;
    margin-left: 0
}

.table td.span7, .table th.span7 {
    float: none;
    width: 524px;
    margin-left: 0
}

.table td.span8, .table th.span8 {
    float: none;
    width: 604px;
    margin-left: 0
}

.table td.span9, .table th.span9 {
    float: none;
    width: 684px;
    margin-left: 0
}

.table td.span10, .table th.span10 {
    float: none;
    width: 764px;
    margin-left: 0
}

.table td.span11, .table th.span11 {
    float: none;
    width: 844px;
    margin-left: 0
}

.table td.span12, .table th.span12 {
    float: none;
    width: 924px;
    margin-left: 0
}

.table tbody tr.success > td {
    background-color: #dff0d8
}

.table tbody tr.error > td {
    background-color: #f2dede
}

.table tbody tr.warning > td {
    background-color: #fcf8e3
}

.table tbody tr.info > td {
    background-color: #d9edf7
}

.table-hover tbody tr.success:hover > td {
    background-color: #d0e9c6
}

.table-hover tbody tr.error:hover > td {
    background-color: #ebcccc
}

.table-hover tbody tr.warning:hover > td {
    background-color: #faf2cc
}

.table-hover tbody tr.info:hover > td {
    background-color: #c4e3f3
}

[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    *margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url("../_legacyAssets/glyphicons-halflings.png");
    background-position: 14px 14px;
    background-repeat: no-repeat;
    margin-top: 1px
}

.icon-white, .nav-pills > .active > a > [class^="icon-"], .nav-pills > .active > a > [class*=" icon-"], .nav-list > .active > a > [class^="icon-"], .nav-list > .active > a > [class*=" icon-"], .navbar-inverse .nav > .active > a > [class^="icon-"], .navbar-inverse .nav > .active > a > [class*=" icon-"], .dropdown-menu > li > a:hover > [class^="icon-"], .dropdown-menu > li > a:focus > [class^="icon-"], .dropdown-menu > li > a:hover > [class*=" icon-"], .dropdown-menu > li > a:focus > [class*=" icon-"], .dropdown-menu > .active > a > [class^="icon-"], .dropdown-menu > .active > a > [class*=" icon-"], .dropdown-submenu:hover > a > [class^="icon-"], .dropdown-submenu:focus > a > [class^="icon-"], .dropdown-submenu:hover > a > [class*=" icon-"], .dropdown-submenu:focus > a > [class*=" icon-"] {
    background-image: url("../_legacyAssets/glyphicons-halflings-white.png")
}

.icon-glass {
    background-position: 0 0
}

.icon-music {
    background-position: -24px 0
}

.icon-search {
    background-position: -48px 0
}

.icon-envelope {
    background-position: -72px 0
}

.icon-heart {
    background-position: -96px 0
}

.icon-star {
    background-position: -120px 0
}

.icon-star-empty {
    background-position: -144px 0
}

.icon-user {
    background-position: -168px 0
}

.icon-film {
    background-position: -192px 0
}

.icon-th-large {
    background-position: -216px 0
}

.icon-th {
    background-position: -240px 0
}

.icon-th-list {
    background-position: -264px 0
}

.icon-ok {
    background-position: -288px 0
}

.icon-remove {
    background-position: -312px 0
}

.icon-zoom-in {
    background-position: -336px 0
}

.icon-zoom-out {
    background-position: -360px 0
}

.icon-off {
    background-position: -384px 0
}

.icon-signal {
    background-position: -408px 0
}

.icon-cog {
    background-position: -432px 0
}

.icon-trash {
    background-position: -456px 0
}

.icon-home {
    background-position: 0 -24px
}

.icon-file {
    background-position: -24px -24px
}

.icon-time {
    background-position: -48px -24px
}

.icon-road {
    background-position: -72px -24px
}

.icon-download-alt {
    background-position: -96px -24px
}

.icon-download {
    background-position: -120px -24px
}

.icon-upload {
    background-position: -144px -24px
}

.icon-inbox {
    background-position: -168px -24px
}

.icon-play-circle {
    background-position: -192px -24px
}

.icon-repeat {
    background-position: -216px -24px
}

.icon-refresh {
    background-position: -240px -24px
}

.icon-list-alt {
    background-position: -264px -24px
}

.icon-lock {
    background-position: -287px -24px
}

.icon-flag {
    background-position: -312px -24px
}

.icon-headphones {
    background-position: -336px -24px
}

.icon-volume-off {
    background-position: -360px -24px
}

.icon-volume-down {
    background-position: -384px -24px
}

.icon-volume-up {
    background-position: -408px -24px
}

.icon-qrcode {
    background-position: -432px -24px
}

.icon-barcode {
    background-position: -456px -24px
}

.icon-tag {
    background-position: 0 -48px
}

.icon-tags {
    background-position: -25px -48px
}

.icon-book {
    background-position: -48px -48px
}

.icon-bookmark {
    background-position: -72px -48px
}

.icon-print {
    background-position: -96px -48px
}

.icon-camera {
    background-position: -120px -48px
}

.icon-font {
    background-position: -144px -48px
}

.icon-bold {
    background-position: -167px -48px
}

.icon-italic {
    background-position: -192px -48px
}

.icon-text-height {
    background-position: -216px -48px
}

.icon-text-width {
    background-position: -240px -48px
}

.icon-align-left {
    background-position: -264px -48px
}

.icon-align-center {
    background-position: -288px -48px
}

.icon-align-right {
    background-position: -312px -48px
}

.icon-align-justify {
    background-position: -336px -48px
}

.icon-list {
    background-position: -360px -48px
}

.icon-indent-left {
    background-position: -384px -48px
}

.icon-indent-right {
    background-position: -408px -48px
}

.icon-facetime-video {
    background-position: -432px -48px
}

.icon-picture {
    background-position: -456px -48px
}

.icon-pencil {
    background-position: 0 -72px
}

.icon-map-marker {
    background-position: -24px -72px
}

.icon-adjust {
    background-position: -48px -72px
}

.icon-tint {
    background-position: -72px -72px
}

.icon-edit {
    background-position: -96px -72px
}

.icon-share {
    background-position: -120px -72px
}

.icon-check {
    background-position: -144px -72px
}

.icon-move {
    background-position: -168px -72px
}

.icon-step-backward {
    background-position: -192px -72px
}

.icon-fast-backward {
    background-position: -216px -72px
}

.icon-backward {
    background-position: -240px -72px
}

.icon-play {
    background-position: -264px -72px
}

.icon-pause {
    background-position: -288px -72px
}

.icon-stop {
    background-position: -312px -72px
}

.icon-forward {
    background-position: -336px -72px
}

.icon-fast-forward {
    background-position: -360px -72px
}

.icon-step-forward {
    background-position: -384px -72px
}

.icon-eject {
    background-position: -408px -72px
}

.icon-chevron-left {
    background-position: -432px -72px
}

.icon-chevron-right {
    background-position: -456px -72px
}

.icon-plus-sign {
    background-position: 0 -96px
}

.icon-minus-sign {
    background-position: -24px -96px
}

.icon-remove-sign {
    background-position: -48px -96px
}

.icon-ok-sign {
    background-position: -72px -96px
}

.icon-question-sign {
    background-position: -96px -96px
}

.icon-info-sign {
    background-position: -120px -96px
}

.icon-screenshot {
    background-position: -144px -96px
}

.icon-remove-circle {
    background-position: -168px -96px
}

.icon-ok-circle {
    background-position: -192px -96px
}

.icon-ban-circle {
    background-position: -216px -96px
}

.icon-arrow-left {
    background-position: -240px -96px
}

.icon-arrow-right {
    background-position: -264px -96px
}

.icon-arrow-up {
    background-position: -289px -96px
}

.icon-arrow-down {
    background-position: -312px -96px
}

.icon-share-alt {
    background-position: -336px -96px
}

.icon-resize-full {
    background-position: -360px -96px
}

.icon-resize-small {
    background-position: -384px -96px
}

.icon-plus {
    background-position: -408px -96px
}

.icon-minus {
    background-position: -433px -96px
}

.icon-asterisk {
    background-position: -456px -96px
}

.icon-exclamation-sign {
    background-position: 0 -120px
}

.icon-gift {
    background-position: -24px -120px
}

.icon-leaf {
    background-position: -48px -120px
}

.icon-fire {
    background-position: -72px -120px
}

.icon-eye-open {
    background-position: -96px -120px
}

.icon-eye-close {
    background-position: -120px -120px
}

.icon-warning-sign {
    background-position: -144px -120px
}

.icon-plane {
    background-position: -168px -120px
}

.icon-calendar {
    background-position: -192px -120px
}

.icon-random {
    background-position: -216px -120px;
    width: 16px
}

.icon-comment {
    background-position: -240px -120px
}

.icon-magnet {
    background-position: -264px -120px
}

.icon-chevron-up {
    background-position: -288px -120px
}

.icon-chevron-down {
    background-position: -313px -119px
}

.icon-retweet {
    background-position: -336px -120px
}

.icon-shopping-cart {
    background-position: -360px -120px
}

.icon-folder-close {
    background-position: -384px -120px;
    width: 16px
}

.icon-folder-open {
    background-position: -408px -120px;
    width: 16px
}

.icon-resize-vertical {
    background-position: -432px -119px
}

.icon-resize-horizontal {
    background-position: -456px -118px
}

.icon-hdd {
    background-position: 0 -144px
}

.icon-bullhorn {
    background-position: -24px -144px
}

.icon-bell {
    background-position: -48px -144px
}

.icon-certificate {
    background-position: -72px -144px
}

.icon-thumbs-up {
    background-position: -96px -144px
}

.icon-thumbs-down {
    background-position: -120px -144px
}

.icon-hand-right {
    background-position: -144px -144px
}

.icon-hand-left {
    background-position: -168px -144px
}

.icon-hand-up {
    background-position: -192px -144px
}

.icon-hand-down {
    background-position: -216px -144px
}

.icon-circle-arrow-right {
    background-position: -240px -144px
}

.icon-circle-arrow-left {
    background-position: -264px -144px
}

.icon-circle-arrow-up {
    background-position: -288px -144px
}

.icon-circle-arrow-down {
    background-position: -312px -144px
}

.icon-globe {
    background-position: -336px -144px
}

.icon-wrench {
    background-position: -360px -144px
}

.icon-tasks {
    background-position: -384px -144px
}

.icon-filter {
    background-position: -408px -144px
}

.icon-briefcase {
    background-position: -432px -144px
}

.icon-fullscreen {
    background-position: -456px -144px
}

.dropup, .dropdown {
    position: relative
}

.dropdown-toggle {
    *margin-bottom: -3px
}

.dropdown-toggle:active, .open .dropdown-toggle {
    outline: 0
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: top;
    border-top: 4px solid black;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: ""
}

.dropdown .caret {
    margin-top: 8px;
    margin-left: 2px
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: white;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto
}

.dropdown-menu .divider {
    *width: 100%;
    height: 1px;
    margin: 9px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 1px solid white
}

.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
    white-space: nowrap
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus, .dropdown-submenu:hover > a, .dropdown-submenu:focus > a {
    text-decoration: none;
    color: white;
    background-color: #0081c2;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0077b3);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0077b3);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0077b3);
    background-image: linear-gradient(to bottom, #0088cc, #0077b3);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF0088CC', endColorstr='#FF0077B3', GradientType=0)
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    color: white;
    text-decoration: none;
    outline: 0;
    background-color: #0081c2;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0077b3);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0077b3);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0077b3);
    background-image: linear-gradient(to bottom, #0088cc, #0077b3);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF0088CC', endColorstr='#FF0077B3', GradientType=0)
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
    color: #999999
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    cursor: default
}

.table__dropdown-menu {
    top: auto;
    left: auto;
    position: absolute;
}

.table__dropdown {
    position: static;
}

.open {
    *z-index: 1000
}

.open > .dropdown-menu {
    display: block
}

.pull-right > .dropdown-menu {
    right: 0;
    left: auto
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px solid black;
    content: ""
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px
}

.dropdown-submenu {
    position: relative
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block
}

.dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
    -webkit-border-radius: 5px 5px 5px 0;
    -moz-border-radius: 5px 5px 5px 0;
    border-radius: 5px 5px 5px 0
}

.dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
    margin-top: 5px;
    margin-right: -10px
}

.dropdown-submenu:hover > a:after {
    border-left-color: white
}

.dropdown-submenu.pull-left {
    float: none
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px
}

.dropdown .dropdown-menu .nav-header {
    padding-left: 20px;
    padding-right: 20px
}

.typeahead {
    z-index: 1051;
    margin-top: 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: whitesmoke;
    border: 1px solid #e3e3e3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05)
}

.well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15)
}

.well-large {
    padding: 24px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.well-small {
    padding: 9px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -moz-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.in {
    opacity: 1
}

.collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    -moz-transition: height .35s ease;
    -o-transition: height .35s ease;
    transition: height .35s ease
}

.collapse.in {
    height: auto
}

.close {
    float: right;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: black;
    text-shadow: 0 1px 0 white;
    opacity: .2;
    filter: alpha(opacity=20)
}

.close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
    opacity: .4;
    filter: alpha(opacity=40)
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none
}

.btn {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #333333;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    background-color: whitesmoke;
    background-image: -moz-linear-gradient(to bottom, white, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#e6e6e6));
    background-image: -webkit-linear-gradient(to bottom, white, #e6e6e6);
    background-image: -o-linear-gradient(to bottom, white, #e6e6e6);
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFE6E6E6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #e6e6e6;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    border: 1px solid #cccccc;
    *border: 0;
    border-bottom-color: #b3b3b3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    *margin-left: .3em;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
}

.btn:hover, .btn:focus, .btn:active, .btn.active, .btn.disabled, .btn[disabled] {
    /*color: #333333;*/
    background-color: #e6e6e6;
    *background-color: #d9d9d9
}

.btn:active, .btn.active {
    background-color: #cccccc  \9
}

.btn:first-child {
    *margin-left: 0
}

.btn:hover, .btn:focus {
    color: #333333;
    text-decoration: none;
    background-position: 0 -15px;
    -webkit-transition: background-position .1s linear;
    -moz-transition: background-position .1s linear;
    -o-transition: background-position .1s linear;
    transition: background-position .1s linear
}

.btn:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.btn.active, .btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05)
}

.btn.disabled, .btn[disabled] {
    cursor: default;
    background-image: none;
    opacity: .65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-large {
    padding: 11px 19px;
    font-size: 17.5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.btn-large [class^="icon-"], .btn-large [class*=" icon-"] {
    margin-top: 4px
}

.btn-small {
    padding: 2px 10px;
    font-size: 11.9px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.btn-small [class^="icon-"], .btn-small [class*=" icon-"] {
    margin-top: 0
}

.btn-mini [class^="icon-"], .btn-mini [class*=" icon-"] {
    margin-top: -1px
}

.btn-mini {
    padding: 0 6px;
    font-size: 10.5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.btn-block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.btn-block + .btn-block {
    margin-top: 5px
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
    width: 100%
}

.btn-primary.active, .btn-warning.active, .btn-danger.active, .btn-success.active, .btn-info.active, .btn-inverse.active {
    color: rgba(255, 255, 255, 0.75)
}

.btn-primary {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #006ccc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF0088CC', endColorstr='#FF0044CC', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #0044cc;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
    color: white;
    background-color: #0044cc;
    *background-color: #003bb3
}

.btn-primary:active, .btn-primary.active {
    background-color: #003399  \9
}

.btn-warning {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #f9a732;
    background-image: -moz-linear-gradient(to bottom, #fbb450, #f89406);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
    background-image: -webkit-linear-gradient(to bottom, #fbb450, #f89406);
    background-image: -o-linear-gradient(to bottom, #fbb450, #f89406);
    background-image: linear-gradient(to bottom, #fbb450, #f89406);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFBB450', endColorstr='#FFF89406', GradientType=0);
    border-color: #f89406 #f89406 #ad6704;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #f89406;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
    color: white;
    background-color: #f89406;
    *background-color: #df8505
}

.btn-warning:active, .btn-warning.active {
    background-color: #c67605  \9
}

.btn-danger {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #da4e49;
    background-image: -moz-linear-gradient(to bottom, #ee5f5b, #bd362f);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
    background-image: -webkit-linear-gradient(to bottom, #ee5f5b, #bd362f);
    background-image: -o-linear-gradient(to bottom, #ee5f5b, #bd362f);
    background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEE5F5B', endColorstr='#FFBD362F', GradientType=0);
    border-color: #bd362f #bd362f #802420;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #bd362f;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled] {
    color: white;
    background-color: #bd362f;
    *background-color: #a9302a
}

.btn-danger:active, .btn-danger.active {
    background-color: #942a25  \9
}

.btn-success {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #5bb65b;
    background-image: -moz-linear-gradient(to bottom, #62c462, #51a351);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
    background-image: -webkit-linear-gradient(to bottom, #62c462, #51a351);
    background-image: -o-linear-gradient(to bottom, #62c462, #51a351);
    background-image: linear-gradient(to bottom, #62c462, #51a351);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF62C462', endColorstr='#FF51A351', GradientType=0);
    border-color: #51a351 #51a351 #387038;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #51a351;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
    color: white;
    background-color: #51a351;
    *background-color: #499249
}

.btn-success:active, .btn-success.active {
    background-color: #408140  \9
}

.btn-info {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #49afcd;
    background-image: -moz-linear-gradient(to bottom, #5bc0de, #2f96b4);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
    background-image: -webkit-linear-gradient(to bottom, #5bc0de, #2f96b4);
    background-image: -o-linear-gradient(to bottom, #5bc0de, #2f96b4);
    background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF5BC0DE', endColorstr='#FF2F96B4', GradientType=0);
    border-color: #2f96b4 #2f96b4 #1f6377;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #2f96b4;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
    color: white;
    background-color: #2f96b4;
    *background-color: #2a85a0
}

.btn-info:active, .btn-info.active {
    background-color: #24748c  \9
}

.btn-inverse {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #363636;
    background-image: -moz-linear-gradient(to bottom, #444444, #222222);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
    background-image: -webkit-linear-gradient(to bottom, #444444, #222222);
    background-image: -o-linear-gradient(to bottom, #444444, #222222);
    background-image: linear-gradient(to bottom, #444444, #222222);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF444444', endColorstr='#FF222222', GradientType=0);
    border-color: #222222 #222222 black;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #222222;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.btn-inverse:hover, .btn-inverse:focus, .btn-inverse:active, .btn-inverse.active, .btn-inverse.disabled, .btn-inverse[disabled] {
    color: white;
    background-color: #222222;
    *background-color: #151515
}

.btn-inverse:active, .btn-inverse.active {
    background-color: #090909  \9
}

button.btn, input[type="submit"].btn {
    *padding-top: 3px;
    *padding-bottom: 3px
}

button.btn::-moz-focus-inner, input[type="submit"].btn::-moz-focus-inner {
    padding: 0;
    border: 0
}

button.btn.btn-large, input[type="submit"].btn.btn-large {
    *padding-top: 7px;
    *padding-bottom: 7px
}

button.btn.btn-small, input[type="submit"].btn.btn-small {
    *padding-top: 3px;
    *padding-bottom: 3px
}

button.btn.btn-mini, input[type="submit"].btn.btn-mini {
    *padding-top: 1px;
    *padding-bottom: 1px
}

.btn-link, .btn-link:active, .btn-link[disabled] {
    background-color: transparent;
    background-image: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-link {
    border-color: transparent;
    cursor: pointer;
    color: #0088cc;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.btn-link:hover, .btn-link:focus {
    color: #005580;
    text-decoration: underline;
    background-color: transparent
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus {
    color: #333333;
    text-decoration: none
}

.btn-group {
    position: relative;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    font-size: 0;
    vertical-align: middle;
    white-space: nowrap;
    *margin-left: .3em
}

.btn-group:first-child {
    *margin-left: 0
}

.btn-group + .btn-group {
    margin-left: 5px
}

.btn-toolbar {
    font-size: 0;
    margin-top: 10px;
    margin-bottom: 10px
}

.btn-toolbar > .btn + .btn, .btn-toolbar > .btn-group + .btn, .btn-toolbar > .btn + .btn-group {
    margin-left: 5px
}

.btn-group > .btn {
    position: relative;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.btn-group > .btn + .btn {
    margin-left: -1px
}

.btn-group > .btn, .btn-group > .dropdown-menu, .btn-group > .popover {
    font-size: 14px
}

.btn-group > .btn-mini {
    font-size: 10.5px
}

.btn-group > .btn-small {
    font-size: 11.9px
}

.btn-group > .btn-large {
    font-size: 17.5px
}

.btn-group > .btn:first-child {
    margin-left: 0;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px
}

.btn-group > .btn:last-child, .btn-group > .dropdown-toggle {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px
}

.btn-group > .btn.large:first-child {
    margin-left: 0;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px
}

.btn-group > .btn.large:last-child, .btn-group > .large.dropdown-toggle {
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    border-bottom-right-radius: 6px
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active {
    z-index: 2
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
    outline: 0
}

.btn-group > .btn + .dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px;
    -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    *padding-top: 5px;
    *padding-bottom: 5px
}

.btn-group > .btn-mini + .dropdown-toggle {
    padding-left: 5px;
    padding-right: 5px;
    *padding-top: 2px;
    *padding-bottom: 2px
}

.btn-group > .btn-small + .dropdown-toggle {
    *padding-top: 5px;
    *padding-bottom: 4px
}

.btn-group > .btn-large + .dropdown-toggle {
    padding-left: 12px;
    padding-right: 12px;
    *padding-top: 7px;
    *padding-bottom: 7px
}

.btn-group.open .dropdown-toggle {
    background-image: none;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05)
}

.btn-group.open .btn.dropdown-toggle {
    background-color: #e6e6e6
}

.btn-group.open .btn-primary.dropdown-toggle {
    background-color: #0044cc
}

.btn-group.open .btn-warning.dropdown-toggle {
    background-color: #f89406
}

.btn-group.open .btn-danger.dropdown-toggle {
    background-color: #bd362f
}

.btn-group.open .btn-success.dropdown-toggle {
    background-color: #51a351
}

.btn-group.open .btn-info.dropdown-toggle {
    background-color: #2f96b4
}

.btn-group.open .btn-inverse.dropdown-toggle {
    background-color: #222222
}

.btn .caret {
    margin-top: 8px;
    margin-left: 0
}

.btn-large .caret {
    margin-top: 6px
}

.btn-large .caret {
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 5px
}

.btn-mini .caret, .btn-small .caret {
    margin-top: 8px
}

.dropup .btn-large .caret {
    border-bottom-width: 5px
}

.btn-primary .caret, .btn-warning .caret, .btn-danger .caret, .btn-info .caret, .btn-success .caret, .btn-inverse .caret {
    border-top-color: white;
    border-bottom-color: white
}

.btn-group-vertical {
    display: inline-block;
    *display: inline;
    *zoom: 1
}

.btn-group-vertical > .btn {
    display: block;
    float: none;
    max-width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.btn-group-vertical > .btn + .btn {
    margin-left: 0;
    margin-top: -1px
}

.btn-group-vertical > .btn:first-child {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0
}

.btn-group-vertical > .btn:last-child {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px
}

.btn-group-vertical > .btn-large:first-child {
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0
}

.btn-group-vertical > .btn-large:last-child {
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px
}

.alert {
    padding: 8px 35px 8px 14px;
    margin-bottom: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.alert, .alert h4 {
    color: #c09853
}

.alert h4 {
    margin: 0
}

.alert .close {
    position: relative;
    top: -2px;
    right: -21px;
    line-height: 20px
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #468847
}

.alert-success h4 {
    color: #468847
}

.alert-danger, .alert-error {
    background-color: #f2dede;
    border-color: #eed3d7;
    color: #b94a48
}

.alert-danger h4, .alert-error h4 {
    color: #b94a48
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #3a87ad
}

.alert-info h4 {
    color: #3a87ad
}

.alert-block {
    padding-top: 14px;
    padding-bottom: 14px
}

.alert-block > p, .alert-block > ul {
    margin-bottom: 0
}

.alert-block p + p {
    margin-top: 5px
}

.nav {
    margin-left: 0;
    margin-bottom: 20px;
    list-style: none
}

.nav > li > a {
    display: block
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #eeeeee
}

.nav > li > a > img {
    max-width: none
}

.nav > .pull-right {
    float: right
}

.nav-header {
    display: block;
    padding: 3px 15px;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
    color: #999999;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    text-transform: uppercase
}

.nav li + .nav-header {
    margin-top: 9px
}

.nav-list {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0
}

.nav-list > li > a, .nav-list .nav-header {
    margin-left: -15px;
    margin-right: -15px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5)
}

.nav-list > li > a {
    padding: 3px 15px
}

.nav-list > .active > a, .nav-list > .active > a:hover, .nav-list > .active > a:focus {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #0088cc
}

.nav-list [class^="icon-"], .nav-list [class*=" icon-"] {
    margin-right: 2px
}

.nav-list .divider {
    *width: 100%;
    height: 1px;
    margin: 9px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 1px solid white
}

.nav-tabs, .nav-pills {
    *zoom: 1
}

.nav-tabs:before, .nav-tabs:after, .nav-pills:before, .nav-pills:after {
    display: table;
    content: "";
    line-height: 0
}

.nav-tabs:after, .nav-pills:after {
    clear: both
}

.nav-tabs > li, .nav-pills > li {
    float: left
}

.nav-tabs > li > a, .nav-pills > li > a {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 2px;
    line-height: 14px
}

.nav-tabs {
    border-bottom: 1px solid #ddd
}

.nav-tabs > li {
    margin-bottom: -1px
}

.nav-tabs > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 20px;
    border: 1px solid transparent;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee var(--table-border-color)
}

.nav-tabs > .active > a, .nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus {
    color: #555555;
    background-color: white;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    cursor: default
}

.nav-pills > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.nav-pills > .active > a, .nav-pills > .active > a:hover, .nav-pills > .active > a:focus {
    color: white;
    background-color: #0088cc
}

.nav-stacked > li {
    float: none
}

.nav-stacked > li > a {
    margin-right: 0
}

.nav-tabs.nav-stacked {
    border-bottom: 0
}

.nav-tabs.nav-stacked > li > a {
    border: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.nav-tabs.nav-stacked > li:first-child > a {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px
}

.nav-tabs.nav-stacked > li:last-child > a {
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px
}

.nav-tabs.nav-stacked > li > a:hover, .nav-tabs.nav-stacked > li > a:focus {
    border-color: #ddd;
    z-index: 2
}

.nav-pills.nav-stacked > li > a {
    margin-bottom: 3px
}

.nav-pills.nav-stacked > li:last-child > a {
    margin-bottom: 1px
}

.nav-tabs .dropdown-menu {
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px
}

.nav-pills .dropdown-menu {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.nav .dropdown-toggle .caret {
    border-top-color: #0088cc;
    border-bottom-color: #0088cc;
    margin-top: 6px
}

.nav .dropdown-toggle:hover .caret, .nav .dropdown-toggle:focus .caret {
    border-top-color: #005580;
    border-bottom-color: #005580
}

.nav-tabs .dropdown-toggle .caret {
    margin-top: 8px
}

.nav .active .dropdown-toggle .caret {
    border-top-color: #fff;
    border-bottom-color: #fff
}

.nav-tabs .active .dropdown-toggle .caret {
    border-top-color: #555555;
    border-bottom-color: #555555
}

.nav > .dropdown.active > a:hover, .nav > .dropdown.active > a:focus {
    cursor: pointer
}

.nav-tabs .open .dropdown-toggle, .nav-pills .open .dropdown-toggle, .nav > li.dropdown.open.active > a:hover, .nav > li.dropdown.open.active > a:focus {
    color: white;
    background-color: #999999;
    border-color: #999999
}

.nav li.dropdown.open .caret, .nav li.dropdown.open.active .caret, .nav li.dropdown.open a:hover .caret, .nav li.dropdown.open a:focus .caret {
    border-top-color: white;
    border-bottom-color: white;
    opacity: 1;
    filter: alpha(opacity=100)
}

.tabs-stacked .open > a:hover, .tabs-stacked .open > a:focus {
    border-color: #999999
}

.tabbable {
    *zoom: 1
}

.tabbable:before, .tabbable:after {
    display: table;
    content: "";
    line-height: 0
}

.tabbable:after {
    clear: both
}

.tab-content {
    overflow: auto
}

.tabs-below > .nav-tabs, .tabs-right > .nav-tabs, .tabs-left > .nav-tabs {
    border-bottom: 0
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
    display: none
}

.tab-content > .active, .pill-content > .active {
    display: block
}

.tabs-below > .nav-tabs {
    border-top: 1px solid #ddd
}

.tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0
}

.tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px
}

.tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
    border-bottom-color: transparent;
    border-top-color: #ddd
}

.tabs-below > .nav-tabs > .active > a, .tabs-below > .nav-tabs > .active > a:hover, .tabs-below > .nav-tabs > .active > a:focus {
    border-color: transparent #ddd #ddd #ddd
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li {
    float: none
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd
}

.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px
}

.tabs-left > .nav-tabs > li > a:hover, .tabs-left > .nav-tabs > li > a:focus {
    border-color: #eeeeee var(--table-border-color) #eeeeee #eeeeee
}

.tabs-left > .nav-tabs .active > a, .tabs-left > .nav-tabs .active > a:hover, .tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: white
}

.tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd
}

.tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0
}

.tabs-right > .nav-tabs > li > a:hover, .tabs-right > .nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee #eeeeee var(--table-border-color)
}

.tabs-right > .nav-tabs .active > a, .tabs-right > .nav-tabs .active > a:hover, .tabs-right > .nav-tabs .active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    *border-left-color: white
}

.nav > .disabled > a {
    color: #999999
}

.nav > .disabled > a:hover, .nav > .disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    cursor: default
}

.navbar {
    overflow: visible;
    margin-bottom: 20px;
    *position: relative;
    *z-index: 2
}

.navbar-inner {
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f9f9f9;
    background-image: -moz-linear-gradient(to bottom, white, #f2f2f2);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#f2f2f2));
    background-image: -webkit-linear-gradient(to bottom, white, #f2f2f2);
    background-image: -o-linear-gradient(to bottom, white, #f2f2f2);
    background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFF2F2F2', GradientType=0);
    border: 1px solid #d4d4d4;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
    *zoom: 1
}

.navbar-inner:before, .navbar-inner:after {
    display: table;
    content: "";
    line-height: 0
}

.navbar-inner:after {
    clear: both
}

.navbar .container {
    width: auto
}

.nav-collapse.collapse {
    height: auto;
    overflow: visible
}

.navbar .brand {
    float: left;
    display: block;
    padding: 10px 20px 10px;
    margin-left: -20px;
    font-size: 20px;
    font-weight: 200;
    color: #777777;
    text-shadow: 0 1px 0 white
}

.navbar .brand:hover, .navbar .brand:focus {
    text-decoration: none
}

.navbar-text {
    margin-bottom: 0;
    line-height: 40px;
    color: #777777
}

.navbar-link {
    color: #777777
}

.navbar-link:hover, .navbar-link:focus {
    color: #333333
}

.navbar .divider-vertical {
    height: 40px;
    margin: 0 9px;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid white
}

.navbar .btn, .navbar .btn-group {
    margin-top: 5px
}

.navbar .btn-group .btn, .navbar .input-prepend .btn, .navbar .input-append .btn, .navbar .input-prepend .btn-group, .navbar .input-append .btn-group {
    margin-top: 0
}

.navbar-form {
    margin-bottom: 0;
    *zoom: 1
}

.navbar-form:before, .navbar-form:after {
    display: table;
    content: "";
    line-height: 0
}

.navbar-form:after {
    clear: both
}

.navbar-form input, .navbar-form select, .navbar-form .radio, .navbar-form .checkbox {
    margin-top: 5px
}

.navbar-form input, .navbar-form select, .navbar-form .btn {
    display: inline-block;
    margin-bottom: 0
}

.navbar-form input[type="image"], .navbar-form input[type="checkbox"], .navbar-form input[type="radio"] {
    margin-top: 3px
}

.navbar-form .input-append, .navbar-form .input-prepend {
    margin-top: 5px;
    white-space: nowrap
}

.navbar-form .input-append input, .navbar-form .input-prepend input {
    margin-top: 0
}

.navbar-search {
    position: relative;
    float: left;
    margin-top: 3px;
    margin-bottom: 0;
}

.navbar-search .search-query {
    margin-bottom: 0;
    padding: 4px 14px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
}

.navbar-static-top {
    position: static;
    margin-bottom: 0
}

.navbar-static-top .navbar-inner {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.navbar-fixed-top, .navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    margin-bottom: 0
}

.navbar-fixed-top .navbar-inner, .navbar-static-top .navbar-inner {
    border-width: 0 0 1px
}

.navbar-fixed-bottom .navbar-inner {
    border-width: 1px 0 0
}

.navbar-fixed-top .navbar-inner, .navbar-fixed-bottom .navbar-inner {
    padding-left: 0;
    padding-right: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
    width: 940px
}

.navbar-fixed-top {
    top: 0
}

.navbar-fixed-top .navbar-inner, .navbar-static-top .navbar-inner {
    -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1)
}

.navbar-fixed-bottom {
    bottom: 0
}

.navbar-fixed-bottom .navbar-inner {
    -webkit-box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1)
}

.navbar .nav {
    position: relative;
    left: 0;
    display: block;
    float: left;
    margin: 0 10px 0 0
}

.navbar .nav.pull-right {
    float: right;
    margin-right: 0
}

.navbar .nav > li {
    float: left
}

.navbar .nav > li > a {
    float: none;
    padding: 10px 15px 10px;
    color: #777777;
    text-decoration: none;
    text-shadow: 0 1px 0 white
}

.navbar .nav .dropdown-toggle .caret {
    margin-top: 8px
}

.navbar .nav > li > a:focus, .navbar .nav > li > a:hover {
    background-color: transparent;
    color: #333333;
    text-decoration: none
}

.navbar .nav > .active > a, .navbar .nav > .active > a:hover, .navbar .nav > .active > a:focus {
    color: #555555;
    text-decoration: none;
    background-color: #e6e6e6;
    -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125)
}

.navbar .btn-navbar {
    display: none;
    float: right;
    padding: 7px 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #ededed;
    background-image: -moz-linear-gradient(to bottom, #f2f2f2, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#e6e6e6));
    background-image: -webkit-linear-gradient(to bottom, #f2f2f2, #e6e6e6);
    background-image: -o-linear-gradient(to bottom, #f2f2f2, #e6e6e6);
    background-image: linear-gradient(to bottom, #f2f2f2, #e6e6e6);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF2F2F2', endColorstr='#FFE6E6E6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #e6e6e6;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.075);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.075)
}

.navbar .btn-navbar:hover, .navbar .btn-navbar:focus, .navbar .btn-navbar:active, .navbar .btn-navbar.active, .navbar .btn-navbar.disabled, .navbar .btn-navbar[disabled] {
    color: white;
    background-color: #e6e6e6;
    *background-color: #d9d9d9
}

.navbar .btn-navbar:active, .navbar .btn-navbar.active {
    background-color: #cccccc  \9
}

.navbar .btn-navbar .icon-bar {
    display: block;
    width: 18px;
    height: 2px;
    background-color: #f5f5f5;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25)
}

.btn-navbar .icon-bar + .icon-bar {
    margin-top: 3px
}

.navbar .nav > li > .dropdown-menu:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 9px
}

.navbar .nav > li > .dropdown-menu:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    top: -6px;
    left: 10px
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
    border-top: 7px solid #ccc;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    bottom: -7px;
    top: auto
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
    border-top: 6px solid white;
    border-bottom: 0;
    bottom: -6px;
    top: auto
}

.navbar .nav li.dropdown > a:hover .caret, .navbar .nav li.dropdown > a:focus .caret {
    border-top-color: #555555;
    border-bottom-color: #555555
}

.navbar .nav li.dropdown.open > .dropdown-toggle, .navbar .nav li.dropdown.active > .dropdown-toggle, .navbar .nav li.dropdown.open.active > .dropdown-toggle {
    background-color: #e6e6e6;
    color: #555555
}

.navbar .nav li.dropdown > .dropdown-toggle .caret {
    border-top-color: #777777;
    border-bottom-color: #777777
}

.navbar .nav li.dropdown.open > .dropdown-toggle .caret, .navbar .nav li.dropdown.active > .dropdown-toggle .caret, .navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
    border-top-color: #555555;
    border-bottom-color: #555555
}

.navbar .pull-right > li > .dropdown-menu, .navbar .nav > li > .dropdown-menu.pull-right {
    left: auto;
    right: 0
}

.navbar .pull-right > li > .dropdown-menu:before, .navbar .nav > li > .dropdown-menu.pull-right:before {
    left: auto;
    right: 12px
}

.navbar .pull-right > li > .dropdown-menu:after, .navbar .nav > li > .dropdown-menu.pull-right:after {
    left: auto;
    right: 13px
}

.navbar .pull-right > li > .dropdown-menu .dropdown-menu, .navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: -1px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px
}

.navbar-inverse .navbar-inner {
    background-color: #1b1b1b;
    background-image: -moz-linear-gradient(to bottom, #222222, #111111);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
    background-image: -webkit-linear-gradient(to bottom, #222222, #111111);
    background-image: -o-linear-gradient(to bottom, #222222, #111111);
    background-image: linear-gradient(to bottom, #222222, #111111);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF222222', endColorstr='#FF111111', GradientType=0);
    border-color: #252525
}

.navbar-inverse .brand, .navbar-inverse .nav > li > a {
    color: #999999;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.navbar-inverse .brand:hover, .navbar-inverse .brand:focus, .navbar-inverse .nav > li > a:hover, .navbar-inverse .nav > li > a:focus {
    color: white
}

.navbar-inverse .brand {
    color: #999999
}

.navbar-inverse .navbar-text {
    color: #999999
}

.navbar-inverse .nav > li > a:focus, .navbar-inverse .nav > li > a:hover {
    background-color: transparent;
    color: white
}

.navbar-inverse .nav .active > a, .navbar-inverse .nav .active > a:hover, .navbar-inverse .nav .active > a:focus {
    color: white;
    background-color: #111111
}

.navbar-inverse .navbar-link {
    color: #999999
}

.navbar-inverse .navbar-link:hover, .navbar-inverse .navbar-link:focus {
    color: white
}

.navbar-inverse .divider-vertical {
    border-left-color: #111111;
    border-right-color: #222222
}

.navbar-inverse .nav li.dropdown.open > .dropdown-toggle, .navbar-inverse .nav li.dropdown.active > .dropdown-toggle, .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
    background-color: #111111;
    color: white
}

.navbar-inverse .nav li.dropdown > a:hover .caret, .navbar-inverse .nav li.dropdown > a:focus .caret {
    border-top-color: white;
    color: white
}

.navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
    border-top-color: #999999;
    border-bottom-color: #999999
}

.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret, .navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret, .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
    border-top-color: white;
    border-bottom-color: white
}

.navbar-inverse .navbar-search .search-query {
    color: white;
    background-color: #515151;
    border-color: #111111;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none
}

.navbar-inverse .navbar-search .search-query:-moz-placeholder {
    color: #cccccc
}

.navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
    color: #cccccc
}

.navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
    color: #cccccc
}

.navbar-inverse .navbar-search .search-query:focus, .navbar-inverse .navbar-search .search-query.focused {
    padding: 5px 15px;
    color: #333333;
    text-shadow: 0 1px 0 white;
    background-color: white;
    border: 0;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    outline: 0
}

.navbar-inverse .btn-navbar {
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0e0e0e;
    background-image: -moz-linear-gradient(to bottom, #151515, #040404);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#151515), to(#040404));
    background-image: -webkit-linear-gradient(to bottom, #151515, #040404);
    background-image: -o-linear-gradient(to bottom, #151515, #040404);
    background-image: linear-gradient(to bottom, #151515, #040404);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF151515', endColorstr='#FF040404', GradientType=0);
    border-color: #040404 #040404 black;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #040404;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.navbar-inverse .btn-navbar:hover, .navbar-inverse .btn-navbar:focus, .navbar-inverse .btn-navbar:active, .navbar-inverse .btn-navbar.active, .navbar-inverse .btn-navbar.disabled, .navbar-inverse .btn-navbar[disabled] {
    color: white;
    background-color: #040404;
    *background-color: black
}

.navbar-inverse .btn-navbar:active, .navbar-inverse .btn-navbar.active {
    background-color: black  \9
}

.breadcrumb {
    padding: 8px 15px;
    margin: 0 0 20px;
    list-style: none;
    background-color: #f5f5f5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.breadcrumb > li {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    text-shadow: 0 1px 0 white
}

.breadcrumb > li > .divider {
    padding: 0 5px;
    color: #ccc
}

.breadcrumb .active {
    color: #999999
}

.pagination {
    margin: 20px 0
}

.pagination ul {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-left: 0;
    margin-bottom: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05)
}

.pagination ul > li {
    display: inline
}

.pagination ul > li > a, .pagination ul > li > span {
    float: left;
    padding: 4px 12px;
    line-height: 20px;
    text-decoration: none;
    background-color: white;
    border: 1px solid var(--table-border-color);
    border-left-width: 0
}

.pagination ul > li > a:hover, .pagination ul > li > a:focus, .pagination ul > .active > a, .pagination ul > .active > span {
    background-color: whitesmoke
}

.pagination ul > .active > a, .pagination ul > .active > span {
    color: #999999;
    cursor: default
}

.pagination ul > .disabled > span, .pagination ul > .disabled > a, .pagination ul > .disabled > a:hover, .pagination ul > .disabled > a:focus {
    color: #999999;
    background-color: transparent;
    cursor: default
}

.pagination ul > li:first-child > a, .pagination ul > li:first-child > span {
    border-left-width: 1px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px
}

.pagination ul > li:last-child > a, .pagination ul > li:last-child > span {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px
}

.pagination-centered {
    text-align: center
}

.pagination-right {
    text-align: right
}

.pagination-large ul > li > a, .pagination-large ul > li > span {
    padding: 11px 19px;
    font-size: 17.5px
}

.pagination-large ul > li:first-child > a, .pagination-large ul > li:first-child > span {
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px
}

.pagination-large ul > li:last-child > a, .pagination-large ul > li:last-child > span {
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    border-bottom-right-radius: 6px
}

.pagination-mini ul > li:first-child > a, .pagination-mini ul > li:first-child > span, .pagination-small ul > li:first-child > a, .pagination-small ul > li:first-child > span {
    -webkit-border-top-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-left-radius: 3px
}

.pagination-mini ul > li:last-child > a, .pagination-mini ul > li:last-child > span, .pagination-small ul > li:last-child > a, .pagination-small ul > li:last-child > span {
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    border-bottom-right-radius: 3px
}

.pagination-small ul > li > a, .pagination-small ul > li > span {
    padding: 2px 10px;
    font-size: 11.9px
}

.pagination-mini ul > li > a, .pagination-mini ul > li > span {
    padding: 0 6px;
    font-size: 10.5px
}

.pager {
    margin: 20px 0;
    list-style: none;
    text-align: center;
    *zoom: 1
}

.pager:before, .pager:after {
    display: table;
    content: "";
    line-height: 0
}

.pager:after {
    clear: both
}

.pager li {
    display: inline
}

.pager li > a, .pager li > span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px
}

.pager li > a:hover, .pager li > a:focus {
    text-decoration: none;
    background-color: #f5f5f5
}

.pager .next > a, .pager .next > span {
    float: right
}

.pager .previous > a, .pager .previous > span {
    float: left
}

.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
    color: #999999;
    background-color: #fff;
    cursor: default
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: black
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop, .modal-backdrop.fade.in {
    opacity: .8;
    filter: alpha(opacity=80)
}

.modal {
    position: fixed;
    top: 10%;
    left: 50%;
    z-index: 1050;
    width: 560px;
    margin-left: -280px;
    background-color: white;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.3);
    *border: 1px solid #999;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    outline: none
}

.modal.fade {
    -webkit-transition: opacity .3s linear, top .3s ease-out;
    -moz-transition: opacity .3s linear, top .3s ease-out;
    -o-transition: opacity .3s linear, top .3s ease-out;
    transition: opacity .3s linear, top .3s ease-out;
    top: -25%
}

.modal.fade.in {
    top: 10%
}

.modal-header {
    padding: 9px 15px;
    border-bottom: 1px solid #eee
}

.modal-header .close {
    margin-top: 2px
}

.modal-header h3 {
    margin: 0;
    line-height: 30px
}

.modal-body {
    position: relative;
    overflow-y: auto;
    max-height: 400px;
    padding: 15px;
}

.modal-form {
    margin-bottom: 0
}

.modal-footer {
    padding: 14px 15px 15px;
    margin-bottom: 0;
    text-align: right;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
    -webkit-box-shadow: inset 0 1px 0 white;
    -moz-box-shadow: inset 0 1px 0 white;
    box-shadow: inset 0 1px 0 white;
    *zoom: 1
}

.modal-footer:before, .modal-footer:after {
    display: table;
    content: "";
    line-height: 0
}

.modal-footer:after {
    clear: both
}

.modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0
}

.modal-footer .btn-group .btn + .btn {
    margin-left: -1px
}

.modal-footer .btn-block + .btn-block {
    margin-left: 0
}

.tooltip {
    position: absolute;
    z-index: 1030;
    display: block;
    visibility: visible;
    font-size: 11px;
    line-height: 1.4;
    opacity: 0;
    filter: alpha(opacity=0)
}

.tooltip.in {
    opacity: .8;
    filter: alpha(opacity=80)
}

.tooltip.top {
    margin-top: -3px;
    padding: 5px 0
}

.tooltip.right {
    margin-left: 3px;
    padding: 0 5px
}

.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0
}

.tooltip.left {
    margin-left: -3px;
    padding: 0 5px
}

.tooltip-inner {
    max-width: 200px;
    padding: 8px;
    color: white;
    text-align: center;
    text-decoration: none;
    background-color: black;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: black
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: black
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: black
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: black
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1100;
    display: none;
    max-width: 300px;
    padding: 1px;
    text-align: left;
    background-color: white;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    white-space: normal
}

.popover.top {
    margin-top: -10px
}

.popover.right {
    margin-left: 10px
}

.popover.bottom {
    margin-top: 10px
}

.popover.left {
    margin-left: -10px
}

.popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0
}

.popover-title:empty {
    display: none
}

.popover-content {
    padding: 9px 14px
}

.popover .arrow, .popover .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.popover .arrow {
    border-width: 11px
}

.popover .arrow:after {
    border-width: 10px;
    content: ""
}

.popover.top .arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    bottom: -11px
}

.popover.top .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: white
}

.popover.right .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25)
}

.popover.right .arrow:after {
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: white
}

.popover.bottom .arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    top: -11px
}

.popover.bottom .arrow:after {
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: white
}

.popover.left .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25)
}

.popover.left .arrow:after {
    right: 1px;
    border-right-width: 0;
    border-left-color: white;
    bottom: -10px
}

.thumbnails {
    margin-left: -20px;
    list-style: none;
    *zoom: 1
}

.thumbnails:before, .thumbnails:after {
    display: table;
    content: "";
    line-height: 0
}

.thumbnails:after {
    clear: both
}

.row-fluid .thumbnails {
    margin-left: 0
}

.thumbnails > li {
    float: left;
    margin-bottom: 20px;
    margin-left: 20px
}

.thumbnail {
    display: block;
    padding: 4px;
    line-height: 20px;
    border: 1px solid #ddd;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

a.thumbnail:hover, a.thumbnail:focus {
    border-color: #0088cc;
    -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
    -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
    box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25)
}

.thumbnail > img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto
}

.thumbnail .caption {
    padding: 9px;
    color: #555555
}

.media, .media-body {
    overflow: hidden;
    *overflow: visible;
    zoom: 1
}

.media, .media .media {
    margin-top: 15px
}

.media:first-child {
    margin-top: 0
}

.media-object {
    display: block
}

.media-heading {
    margin: 0 0 5px
}

.media > .pull-left {
    margin-right: 10px
}

.media > .pull-right {
    margin-left: 10px
}

.media-list {
    margin-left: 0;
    list-style: none
}

.label, .badge {
    display: inline-block;
    padding: 2px 4px;
    font-size: 11.844px;
    font-weight: 700;
    line-height: 14px;
    color: white;
    vertical-align: baseline;
    white-space: nowrap;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #999999
}

.label {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px
}

.label:empty, .badge:empty {
    display: none
}

a.label:hover, a.label:focus, a.badge:hover, a.badge:focus {
    color: white;
    text-decoration: none;
    cursor: pointer
}

.label-important {
    background-color: #b94a48
}

.label-important[href] {
    background-color: #953b39
}

.label-warning {
    background-color: #f89406
}

.label-warning[href] {
    background-color: #c67605
}

.label-success {
    background-color: #468847
}

.label-success[href] {
    background-color: #356635
}

.label-info {
    background-color: #3a87ad
}

.label-info[href] {
    background-color: #2d6987
}

.label-inverse {
    background-color: #333333
}

.label-inverse[href] {
    background-color: #1a1a1a
}

.badge-important {
    background-color: #b94a48
}

.badge-important[href] {
    background-color: #953b39
}

.badge-warning {
    background-color: #f89406
}

.badge-warning[href] {
    background-color: #c67605
}

.badge-success {
    background-color: #468847
}

.badge-success[href] {
    background-color: #356635
}

.badge-info {
    background-color: #3a87ad
}

.badge-info[href] {
    background-color: #2d6987
}

.badge-inverse {
    background-color: #333333
}

.badge-inverse[href] {
    background-color: #1a1a1a
}

.btn .label, .btn .badge {
    position: relative;
    top: -1px
}

.btn-mini .label, .btn-mini .badge {
    top: 0
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

@-moz-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

@-ms-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

@-o-keyframes progress-bar-stripes {
    from {
        background-position: 0 0
    }
    to {
        background-position: 40px 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

.progress {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background-color: #f6f6f6;
    background-image: -moz-linear-gradient(to bottom, whitesmoke, #f9f9f9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(whitesmoke), to(#f9f9f9));
    background-image: -webkit-linear-gradient(to bottom, whitesmoke, #f9f9f9);
    background-image: -o-linear-gradient(to bottom, whitesmoke, #f9f9f9);
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF5F5F5', endColorstr='#FFF9F9F9', GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.progress .bar {
    width: 0;
    height: 100%;
    color: white;
    float: left;
    font-size: 12px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0d90d1;
    background-image: -moz-linear-gradient(to bottom, #149bdf, #0480be);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
    background-image: -webkit-linear-gradient(to bottom, #149bdf, #0480be);
    background-image: -o-linear-gradient(to bottom, #149bdf, #0480be);
    background-image: linear-gradient(to bottom, #149bdf, #0480be);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF149BDF', endColorstr='#FF0480BE', GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: width .6s ease;
    -moz-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease
}

.progress .bar + .bar {
    -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15)
}

.progress-striped .bar {
    background-color: #149bdf;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    -webkit-background-size: 40px 40px;
    -moz-background-size: 40px 40px;
    -o-background-size: 40px 40px;
    background-size: 40px 40px
}

.progress.active .bar {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -moz-animation: progress-bar-stripes 2s linear infinite;
    -ms-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite
}

.progress-danger .bar, .progress .bar-danger {
    background-color: #dd514b;
    background-image: -moz-linear-gradient(to bottom, #ee5f5b, #c43c35);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
    background-image: -webkit-linear-gradient(to bottom, #ee5f5b, #c43c35);
    background-image: -o-linear-gradient(to bottom, #ee5f5b, #c43c35);
    background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEE5F5B', endColorstr='#FFC43C35', GradientType=0)
}

.progress-danger.progress-striped .bar, .progress-striped .bar-danger {
    background-color: #ee5f5b;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-success .bar, .progress .bar-success {
    background-color: #5db95d;
    background-image: -moz-linear-gradient(to bottom, #62c462, #57a957);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
    background-image: -webkit-linear-gradient(to bottom, #62c462, #57a957);
    background-image: -o-linear-gradient(to bottom, #62c462, #57a957);
    background-image: linear-gradient(to bottom, #62c462, #57a957);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF62C462', endColorstr='#FF57A957', GradientType=0)
}

.progress-success.progress-striped .bar, .progress-striped .bar-success {
    background-color: #62c462;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-info .bar, .progress .bar-info {
    background-color: #4bb1cf;
    background-image: -moz-linear-gradient(to bottom, #5bc0de, #339bb9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
    background-image: -webkit-linear-gradient(to bottom, #5bc0de, #339bb9);
    background-image: -o-linear-gradient(to bottom, #5bc0de, #339bb9);
    background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF5BC0DE', endColorstr='#FF339BB9', GradientType=0)
}

.progress-info.progress-striped .bar, .progress-striped .bar-info {
    background-color: #5bc0de;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-warning .bar, .progress .bar-warning {
    background-color: #f9a732;
    background-image: -moz-linear-gradient(to bottom, #fbb450, #f89406);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
    background-image: -webkit-linear-gradient(to bottom, #fbb450, #f89406);
    background-image: -o-linear-gradient(to bottom, #fbb450, #f89406);
    background-image: linear-gradient(to bottom, #fbb450, #f89406);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFBB450', endColorstr='#FFF89406', GradientType=0)
}

.progress-warning.progress-striped .bar, .progress-striped .bar-warning {
    background-color: #fbb450;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.accordion {
    margin-bottom: 20px
}

.accordion-group {
    margin-bottom: 2px;
    border: 1px solid #e5e5e5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.accordion-heading {
    border-bottom: 0
}

.accordion-heading .accordion-toggle {
    display: block;
    padding: 8px 15px
}

.accordion-toggle {
    cursor: pointer
}

.accordion-inner {
    padding: 9px 15px;
    border-top: 1px solid #e5e5e5
}

.carousel {
    position: relative;
    margin-bottom: 20px;
    line-height: 1
}

.carousel-inner {
    overflow: hidden;
    width: 100%;
    position: relative
}

.carousel-inner > .item {
    display: none;
    position: relative;
    -webkit-transition: .6s ease-in-out left;
    -moz-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
    display: block;
    line-height: 1
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
    display: block
}

.carousel-inner > .active {
    left: 0
}

.carousel-inner > .next, .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%
}

.carousel-inner > .next {
    left: 100%
}

.carousel-inner > .prev {
    left: -100%
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
    left: 0
}

.carousel-inner > .active.left {
    left: -100%
}

.carousel-inner > .active.right {
    left: 100%
}

.carousel-control {
    position: absolute;
    top: 40%;
    left: 15px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    font-size: 60px;
    font-weight: 100;
    line-height: 30px;
    color: white;
    text-align: center;
    background: #222222;
    border: 3px solid white;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    border-radius: 23px;
    opacity: .5;
    filter: alpha(opacity=50)
}

.carousel-control.right {
    left: auto;
    right: 15px
}

.carousel-control:hover, .carousel-control:focus {
    color: white;
    text-decoration: none;
    opacity: .9;
    filter: alpha(opacity=90)
}

.carousel-indicators {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5;
    margin: 0;
    list-style: none
}

.carousel-indicators li {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    text-indent: -999px;
    background-color: #ccc;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 5px
}

.carousel-indicators .active {
    background-color: #fff
}

.carousel-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    background: #333333;
    background: rgba(0, 0, 0, 0.75)
}

.carousel-caption h4, .carousel-caption p {
    color: white;
    line-height: 20px
}

.carousel-caption h4 {
    margin: 0 0 5px
}

.carousel-caption p {
    margin-bottom: 0
}

.hero-unit {
    padding: 60px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    color: inherit;
    background-color: #eeeeee;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.hero-unit h1 {
    margin-bottom: 0;
    font-size: 60px;
    line-height: 1;
    color: inherit;
    letter-spacing: -1px
}

.hero-unit li {
    line-height: 30px
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.hide {
    display: none
}

.show {
    display: block
}

.invisible {
    visibility: hidden
}

.affix {
    position: fixed
}

.clearfix {
    *zoom: 1
}

.clearfix:before, .clearfix:after {
    display: table;
    content: "";
    line-height: 0
}

.clearfix:after {
    clear: both
}

.hide-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.input-block-level {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

@-ms-viewport{width:device-width} .hidden {
                                      display: none;
                                      visibility: hidden
                                  }

.visible-phone {
    display: none !important
}

.visible-tablet {
    display: none !important
}

.hidden-desktop {
    display: none !important
}

.visible-desktop {
    display: inherit !important
}

@media (min-width:768px) and (max-width:979px) {
    .hidden-desktop {
        display: inherit !important
    }

    .visible-desktop {
        display: none !important
    }

    .visible-tablet {
        display: inherit !important
    }

    .hidden-tablet {
        display: none !important
    }
}

@media (max-width:767px) {
    .hidden-desktop {
        display: inherit !important
    }

    .visible-desktop {
        display: none !important
    }

    .visible-phone {
        display: inherit !important
    }

    .hidden-phone {
        display: none !important
    }
}

.visible-print {
    display: none !important
}

@media print {
    .visible-print {
        display: inherit !important
    }

    .hidden-print {
        display: none !important
    }
}

.clearfix {
    *zoom: 1
}

.clearfix:before, .clearfix:after {
    display: table;
    content: "";
    line-height: 0
}

.clearfix:after {
    clear: both
}

.hide-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.input-block-level {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

@media (min-width:1200px) {
    .row {
        margin-left: -30px;
        *zoom: 1
    }

    .row:before, .row:after {
        display: table;
        content: "";
        line-height: 0
    }

    .row:after {
        clear: both
    }

    [class*="span"] {
        float: left;
        min-height: 1px;
        margin-left: 30px
    }

    .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
        width: 1170px
    }

    .span12 {
        width: 1170px
    }

    .span11 {
        width: 1070px
    }

    .span10 {
        width: 970px
    }

    .span9 {
        width: 870px
    }

    .span8 {
        width: 770px
    }

    .span7 {
        width: 670px
    }

    .span6 {
        width: 570px
    }

    .span5 {
        width: 470px
    }

    .span4 {
        width: 370px
    }

    .span3 {
        width: 270px
    }

    .span2 {
        width: 170px
    }

    .span1 {
        width: 70px
    }

    .offset12 {
        margin-left: 1230px
    }

    .offset11 {
        margin-left: 1130px
    }

    .offset10 {
        margin-left: 1030px
    }

    .offset9 {
        margin-left: 930px
    }

    .offset8 {
        margin-left: 830px
    }

    .offset7 {
        margin-left: 730px
    }

    .offset6 {
        margin-left: 630px
    }

    .offset5 {
        margin-left: 530px
    }

    .offset4 {
        margin-left: 430px
    }

    .offset3 {
        margin-left: 330px
    }

    .offset2 {
        margin-left: 230px
    }

    .offset1 {
        margin-left: 130px
    }

    .row-fluid {
        width: 100%;
        *zoom: 1
    }

    .row-fluid:before, .row-fluid:after {
        display: table;
        content: "";
        line-height: 0
    }

    .row-fluid:after {
        clear: both
    }

    .row-fluid [class*="span"] {
        display: block;
        width: 100%;
        min-height: 30px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        float: left;
        margin-left: 2.5641%;
        *margin-left: 2.51091%
    }

    .row-fluid [class*="span"]:first-child {
        margin-left: 0
    }

    .row-fluid .controls-row [class*="span"] + [class*="span"] {
        margin-left: 2.5641%
    }

    .row-fluid .span12 {
        width: 100%;
        *width: 99.94681%
    }

    .row-fluid .span11 {
        width: 91.45299%;
        *width: 91.3998%
    }

    .row-fluid .span10 {
        width: 82.90598%;
        *width: 82.85279%
    }

    .row-fluid .span9 {
        width: 74.35897%;
        *width: 74.30578%
    }

    .row-fluid .span8 {
        width: 65.81197%;
        *width: 65.75877%
    }

    .row-fluid .span7 {
        width: 57.26496%;
        *width: 57.21177%
    }

    .row-fluid .span6 {
        width: 48.71795%;
        *width: 48.66476%
    }

    .row-fluid .span5 {
        width: 40.17094%;
        *width: 40.11775%
    }

    .row-fluid .span4 {
        width: 31.62393%;
        *width: 31.57074%
    }

    .row-fluid .span3 {
        width: 23.07692%;
        *width: 23.02373%
    }

    .row-fluid .span2 {
        width: 14.52991%;
        *width: 14.47672%
    }

    .row-fluid .span1 {
        width: 5.98291%;
        *width: 5.92971%
    }

    .row-fluid .offset12 {
        margin-left: 105.12821%;
        *margin-left: 105.02182%
    }

    .row-fluid .offset12:first-child {
        margin-left: 102.5641%;
        *margin-left: 102.45772%
    }

    .row-fluid .offset11 {
        margin-left: 96.5812%;
        *margin-left: 96.47481%
    }

    .row-fluid .offset11:first-child {
        margin-left: 94.01709%;
        *margin-left: 93.91071%
    }

    .row-fluid .offset10 {
        margin-left: 88.03419%;
        *margin-left: 87.92781%
    }

    .row-fluid .offset10:first-child {
        margin-left: 85.47009%;
        *margin-left: 85.3637%
    }

    .row-fluid .offset9 {
        margin-left: 79.48718%;
        *margin-left: 79.3808%
    }

    .row-fluid .offset9:first-child {
        margin-left: 76.92308%;
        *margin-left: 76.81669%
    }

    .row-fluid .offset8 {
        margin-left: 70.94017%;
        *margin-left: 70.83379%
    }

    .row-fluid .offset8:first-child {
        margin-left: 68.37607%;
        *margin-left: 68.26969%
    }

    .row-fluid .offset7 {
        margin-left: 62.39316%;
        *margin-left: 62.28678%
    }

    .row-fluid .offset7:first-child {
        margin-left: 59.82906%;
        *margin-left: 59.72268%
    }

    .row-fluid .offset6 {
        margin-left: 53.84615%;
        *margin-left: 53.73977%
    }

    .row-fluid .offset6:first-child {
        margin-left: 51.28205%;
        *margin-left: 51.17567%
    }

    .row-fluid .offset5 {
        margin-left: 45.29915%;
        *margin-left: 45.19276%
    }

    .row-fluid .offset5:first-child {
        margin-left: 42.73504%;
        *margin-left: 42.62866%
    }

    .row-fluid .offset4 {
        margin-left: 36.75214%;
        *margin-left: 36.64575%
    }

    .row-fluid .offset4:first-child {
        margin-left: 34.18803%;
        *margin-left: 34.08165%
    }

    .row-fluid .offset3 {
        margin-left: 28.20513%;
        *margin-left: 28.09875%
    }

    .row-fluid .offset3:first-child {
        margin-left: 25.64103%;
        *margin-left: 25.53464%
    }

    .row-fluid .offset2 {
        margin-left: 19.65812%;
        *margin-left: 19.55174%
    }

    .row-fluid .offset2:first-child {
        margin-left: 17.09402%;
        *margin-left: 16.98763%
    }

    .row-fluid .offset1 {
        margin-left: 11.11111%;
        *margin-left: 11.00473%
    }

    .row-fluid .offset1:first-child {
        margin-left: 8.54701%;
        *margin-left: 8.44063%
    }

    input, textarea, .uneditable-input {
        margin-left: 0
    }

    .controls-row [class*="span"] + [class*="span"] {
        margin-left: 30px
    }

    input.span12, textarea.span12, .uneditable-input.span12 {
        width: 1156px
    }

    input.span11, textarea.span11, .uneditable-input.span11 {
        width: 1056px
    }

    input.span10, textarea.span10, .uneditable-input.span10 {
        width: 956px
    }

    input.span9, textarea.span9, .uneditable-input.span9 {
        width: 856px
    }

    input.span8, textarea.span8, .uneditable-input.span8 {
        width: 756px
    }

    input.span7, textarea.span7, .uneditable-input.span7 {
        width: 656px
    }

    input.span6, textarea.span6, .uneditable-input.span6 {
        width: 556px
    }

    input.span5, textarea.span5, .uneditable-input.span5 {
        width: 456px
    }

    input.span4, textarea.span4, .uneditable-input.span4 {
        width: 356px
    }

    input.span3, textarea.span3, .uneditable-input.span3 {
        width: 256px
    }

    input.span2, textarea.span2, .uneditable-input.span2 {
        width: 156px
    }

    input.span1, textarea.span1, .uneditable-input.span1 {
        width: 56px
    }

    .thumbnails {
        margin-left: -30px
    }

    .thumbnails > li {
        margin-left: 30px
    }

    .row-fluid .thumbnails {
        margin-left: 0
    }
}

@media (min-width:768px) and (max-width:979px) {
    .row {
        margin-left: -20px;
        *zoom: 1
    }

    .row:before, .row:after {
        display: table;
        content: "";
        line-height: 0
    }

    .row:after {
        clear: both
    }

    [class*="span"] {
        float: left;
        min-height: 1px;
        margin-left: 20px
    }

    .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
        width: 724px
    }

    .span12 {
        width: 724px
    }

    .span11 {
        width: 662px
    }

    .span10 {
        width: 600px
    }

    .span9 {
        width: 538px
    }

    .span8 {
        width: 476px
    }

    .span7 {
        width: 414px
    }

    .span6 {
        width: 352px
    }

    .span5 {
        width: 290px
    }

    .span4 {
        width: 228px
    }

    .span3 {
        width: 166px
    }

    .span2 {
        width: 104px
    }

    .span1 {
        width: 42px
    }

    .offset12 {
        margin-left: 764px
    }

    .offset11 {
        margin-left: 702px
    }

    .offset10 {
        margin-left: 640px
    }

    .offset9 {
        margin-left: 578px
    }

    .offset8 {
        margin-left: 516px
    }

    .offset7 {
        margin-left: 454px
    }

    .offset6 {
        margin-left: 392px
    }

    .offset5 {
        margin-left: 330px
    }

    .offset4 {
        margin-left: 268px
    }

    .offset3 {
        margin-left: 206px
    }

    .offset2 {
        margin-left: 144px
    }

    .offset1 {
        margin-left: 82px
    }

    .row-fluid {
        width: 100%;
        *zoom: 1
    }

    .row-fluid:before, .row-fluid:after {
        display: table;
        content: "";
        line-height: 0
    }

    .row-fluid:after {
        clear: both
    }

    .row-fluid [class*="span"] {
        display: block;
        width: 100%;
        min-height: 30px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        float: left;
        margin-left: 2.76243%;
        *margin-left: 2.70924%
    }

    .row-fluid [class*="span"]:first-child {
        margin-left: 0
    }

    .row-fluid .controls-row [class*="span"] + [class*="span"] {
        margin-left: 2.76243%
    }

    .row-fluid .span12 {
        width: 100%;
        *width: 99.94681%
    }

    .row-fluid .span11 {
        width: 91.43646%;
        *width: 91.38327%
    }

    .row-fluid .span10 {
        width: 82.87293%;
        *width: 82.81974%
    }

    .row-fluid .span9 {
        width: 74.30939%;
        *width: 74.2562%
    }

    .row-fluid .span8 {
        width: 65.74586%;
        *width: 65.69266%
    }

    .row-fluid .span7 {
        width: 57.18232%;
        *width: 57.12913%
    }

    .row-fluid .span6 {
        width: 48.61878%;
        *width: 48.56559%
    }

    .row-fluid .span5 {
        width: 40.05525%;
        *width: 40.00206%
    }

    .row-fluid .span4 {
        width: 31.49171%;
        *width: 31.43852%
    }

    .row-fluid .span3 {
        width: 22.92818%;
        *width: 22.87499%
    }

    .row-fluid .span2 {
        width: 14.36464%;
        *width: 14.31145%
    }

    .row-fluid .span1 {
        width: 5.8011%;
        *width: 5.74791%
    }

    .row-fluid .offset12 {
        margin-left: 105.52486%;
        *margin-left: 105.41848%
    }

    .row-fluid .offset12:first-child {
        margin-left: 102.76243%;
        *margin-left: 102.65605%
    }

    .row-fluid .offset11 {
        margin-left: 96.96133%;
        *margin-left: 96.85494%
    }

    .row-fluid .offset11:first-child {
        margin-left: 94.1989%;
        *margin-left: 94.09251%
    }

    .row-fluid .offset10 {
        margin-left: 88.39779%;
        *margin-left: 88.29141%
    }

    .row-fluid .offset10:first-child {
        margin-left: 85.63536%;
        *margin-left: 85.52898%
    }

    .row-fluid .offset9 {
        margin-left: 79.83425%;
        *margin-left: 79.72787%
    }

    .row-fluid .offset9:first-child {
        margin-left: 77.07182%;
        *margin-left: 76.96544%
    }

    .row-fluid .offset8 {
        margin-left: 71.27072%;
        *margin-left: 71.16434%
    }

    .row-fluid .offset8:first-child {
        margin-left: 68.50829%;
        *margin-left: 68.4019%
    }

    .row-fluid .offset7 {
        margin-left: 62.70718%;
        *margin-left: 62.6008%
    }

    .row-fluid .offset7:first-child {
        margin-left: 59.94475%;
        *margin-left: 59.83837%
    }

    .row-fluid .offset6 {
        margin-left: 54.14365%;
        *margin-left: 54.03726%
    }

    .row-fluid .offset6:first-child {
        margin-left: 51.38122%;
        *margin-left: 51.27483%
    }

    .row-fluid .offset5 {
        margin-left: 45.58011%;
        *margin-left: 45.47373%
    }

    .row-fluid .offset5:first-child {
        margin-left: 42.81768%;
        *margin-left: 42.7113%
    }

    .row-fluid .offset4 {
        margin-left: 37.01657%;
        *margin-left: 36.91019%
    }

    .row-fluid .offset4:first-child {
        margin-left: 34.25414%;
        *margin-left: 34.14776%
    }

    .row-fluid .offset3 {
        margin-left: 28.45304%;
        *margin-left: 28.34666%
    }

    .row-fluid .offset3:first-child {
        margin-left: 25.69061%;
        *margin-left: 25.58422%
    }

    .row-fluid .offset2 {
        margin-left: 19.8895%;
        *margin-left: 19.78312%
    }

    .row-fluid .offset2:first-child {
        margin-left: 17.12707%;
        *margin-left: 17.02069%
    }

    .row-fluid .offset1 {
        margin-left: 11.32597%;
        *margin-left: 11.21958%
    }

    .row-fluid .offset1:first-child {
        margin-left: 8.56354%;
        *margin-left: 8.45715%
    }

    input, textarea, .uneditable-input {
        margin-left: 0
    }

    .controls-row [class*="span"] + [class*="span"] {
        margin-left: 20px
    }

    input.span12, textarea.span12, .uneditable-input.span12 {
        width: 710px
    }

    input.span11, textarea.span11, .uneditable-input.span11 {
        width: 648px
    }

    input.span10, textarea.span10, .uneditable-input.span10 {
        width: 586px
    }

    input.span9, textarea.span9, .uneditable-input.span9 {
        width: 524px
    }

    input.span8, textarea.span8, .uneditable-input.span8 {
        width: 462px
    }

    input.span7, textarea.span7, .uneditable-input.span7 {
        width: 400px
    }

    input.span6, textarea.span6, .uneditable-input.span6 {
        width: 338px
    }

    input.span5, textarea.span5, .uneditable-input.span5 {
        width: 276px
    }

    input.span4, textarea.span4, .uneditable-input.span4 {
        width: 214px
    }

    input.span3, textarea.span3, .uneditable-input.span3 {
        width: 152px
    }

    input.span2, textarea.span2, .uneditable-input.span2 {
        width: 90px
    }

    input.span1, textarea.span1, .uneditable-input.span1 {
        width: 28px
    }
}

@media (max-width:767px) {
    body {
        padding-left: 20px;
        padding-right: 20px
    }

    .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
        margin-left: -20px;
        margin-right: -20px
    }

    .container-fluid {
        padding: 0
    }

    .dl-horizontal dt {
        float: none;
        clear: none;
        width: auto;
        text-align: left
    }

    .dl-horizontal dd {
        margin-left: 0
    }

    .container {
        width: auto
    }

    .row-fluid {
        width: 100%
    }

    .row, .thumbnails {
        margin-left: 0
    }

    .thumbnails > li {
        float: none;
        margin-left: 0
    }

    [class*="span"], .uneditable-input[class*="span"], .row-fluid [class*="span"] {
        float: none;
        display: block;
        width: 100%;
        margin-left: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .span12, .row-fluid .span12 {
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .row-fluid [class*="offset"]:first-child {
        margin-left: 0
    }

    .input-large, .input-xlarge, .input-xxlarge, input[class*="span"], select[class*="span"], textarea[class*="span"], .uneditable-input {
        display: block;
        width: 100%;
        min-height: 30px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .input-prepend input, .input-append input, .input-prepend input[class*="span"], .input-append input[class*="span"] {
        display: inline-block;
        width: auto
    }

    .controls-row [class*="span"] + [class*="span"] {
        margin-left: 0
    }

    .modal {
        position: fixed;
        top: 20px;
        left: 20px;
        right: 20px;
        width: auto;
        margin: 0
    }

    .modal.fade {
        top: -100px
    }

    .modal.fade.in {
        top: 20px
    }
}

@media (max-width:480px) {
    .nav-collapse {
        -webkit-transform: translate3d(0, 0, 0)
    }

    .page-header h1 small {
        display: block;
        line-height: 20px
    }

    input[type="checkbox"], input[type="radio"] {
        border: 1px solid #ccc
    }

    .form-horizontal .control-label {
        float: none;
        width: auto;
        padding-top: 0;
        text-align: left
    }

    .form-horizontal .controls {
        margin-left: 0
    }

    .form-horizontal .control-list {
        padding-top: 0
    }

    .form-horizontal .form-actions {
        padding-left: 10px;
        padding-right: 10px
    }

    .media .pull-left, .media .pull-right {
        float: none;
        display: block;
        margin-bottom: 10px
    }

    .media-object {
        margin-right: 0;
        margin-left: 0
    }

    .modal {
        top: 10px;
        left: 10px;
        right: 10px
    }

    .modal-header .close {
        padding: 10px;
        margin: -10px
    }

    .carousel-caption {
        position: static
    }
}

@media (max-width:979px) {
    body {
        padding-top: 0
    }

    .navbar-fixed-top, .navbar-fixed-bottom {
        position: static
    }

    .navbar-fixed-top {
        margin-bottom: 20px
    }

    .navbar-fixed-bottom {
        margin-top: 20px
    }

    .navbar-fixed-top .navbar-inner, .navbar-fixed-bottom .navbar-inner {
        padding: 5px
    }

    .navbar .container {
        width: auto;
        padding: 0
    }

    .navbar .brand {
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 0 0 -5px
    }

    .nav-collapse {
        clear: both
    }

    .nav-collapse .nav {
        float: none;
        margin: 0 0 10px
    }

    .nav-collapse .nav > li {
        float: none
    }

    .nav-collapse .nav > li > a {
        margin-bottom: 2px
    }

    .nav-collapse .nav > .divider-vertical {
        display: none
    }

    .nav-collapse .nav .nav-header {
        color: #777777;
        text-shadow: none
    }

    .nav-collapse .nav > li > a, .nav-collapse .dropdown-menu a {
        padding: 9px 15px;
        font-weight: 700;
        color: #777777;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px
    }

    .nav-collapse .btn {
        padding: 4px 10px 4px;
        font-weight: 400;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px
    }

    .nav-collapse .dropdown-menu li + li a {
        margin-bottom: 2px
    }

    .nav-collapse .nav > li > a:hover, .nav-collapse .nav > li > a:focus, .nav-collapse .dropdown-menu a:hover, .nav-collapse .dropdown-menu a:focus {
        background-color: #f2f2f2
    }

    .navbar-inverse .nav-collapse .nav > li > a, .navbar-inverse .nav-collapse .dropdown-menu a {
        color: #999999
    }

    .navbar-inverse .nav-collapse .nav > li > a:hover, .navbar-inverse .nav-collapse .nav > li > a:focus, .navbar-inverse .nav-collapse .dropdown-menu a:hover, .navbar-inverse .nav-collapse .dropdown-menu a:focus {
        background-color: #111111
    }

    .nav-collapse.in .btn-group {
        margin-top: 5px;
        padding: 0
    }

    .nav-collapse .dropdown-menu {
        position: static;
        top: auto;
        left: auto;
        float: none;
        display: none;
        max-width: none;
        margin: 0 15px;
        padding: 0;
        background-color: transparent;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }

    .nav-collapse .open > .dropdown-menu {
        display: block
    }

    .nav-collapse .dropdown-menu:before, .nav-collapse .dropdown-menu:after {
        display: none
    }

    .nav-collapse .dropdown-menu .divider {
        display: none
    }

    .nav-collapse .nav > li > .dropdown-menu:before, .nav-collapse .nav > li > .dropdown-menu:after {
        display: none
    }

    .nav-collapse .navbar-form, .nav-collapse .navbar-search {
        float: none;
        padding: 10px 15px;
        margin: 10px 0;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
        -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1)
    }

    .navbar-inverse .nav-collapse .navbar-form, .navbar-inverse .nav-collapse .navbar-search {
        border-top-color: #111111;
        border-bottom-color: #111111
    }

    .navbar .nav-collapse .nav.pull-right {
        float: none;
        margin-left: 0
    }

    .nav-collapse, .nav-collapse.collapse {
        overflow: hidden;
        height: 0
    }

    .navbar .btn-navbar {
        display: block
    }

    .navbar-static .navbar-inner {
        padding-left: 10px;
        padding-right: 10px
    }
}

@media (min-width:980px) {
    .nav-collapse.collapse {
        height: auto !important;
        overflow: visible !important
    }
}

.uploader, .selector {
    display: -moz-inline-box;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    *display: inline
}

.uploader *, .selector * {
    margin: 0;
    padding: 0
}

.selector select:focus {
    outline: 0
}

.uploader {
    width: 190px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    cursor: default
}

.uploader span.action {
    width: 85px;
    text-align: center;
    text-shadow: #fff 0 1px 0;
    background-color: #fff;
    font-size: 11px;
    font-weight: 700;
    float: left;
    display: inline;
    padding: 2px 0;
    overflow: hidden;
    cursor: pointer
}

.uploader span.filename {
    color: #777;
    width: 82px;
    border-right: solid 1px #bbb;
    font-size: 11px;
    padding: 0 10px;
    float: left;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default
}

.uploader input {
    width: 190px;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    float: right;
    height: 25px;
    border: none;
    cursor: default
}

.uploader input:focus {
    outline: 0
}

.uploader.disabled span.action {
    color: #aaa
}

.uploader.disabled span.filename {
    border-color: #ddd;
    color: #aaa
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    outline: none
}

html {
    height: 100%;
    min-height: 100%
}

body {
    font: 12px Arial;
    min-height: 100%;
    overflow-x: hidden;
}

strong, b {
    font-weight: 600
}

a {
    color: #78808a
}

a:hover, a:active, a:focus {
    cursor: pointer;
    outline: 0 none;
    text-decoration: none;
    color: #5a6573
}

i {
    font-size: 14px
}

.container-fluid {
    padding: 0
}

.padded {
    padding: 15px
}

.nopadding {
    padding: 0
}

.hpadded {
    padding-left: 15px;
    padding-right: 15px
}

.vpadded {
    padding-top: 15px;
    padding-bottom: 15px
}

.separate-sections {
    margin: 0;
    list-style: none;
    padding-bottom: 5px
}

.separate-sections > li, .separate-sections > div {
    margin-bottom: 15px !important
}

.separate-sections > li:last-child, .separate-sections > div:last-child {
    margin-bottom: 0
}

h1, h2, h3, h4, h5, h6, th {
    color: #333333;
    font-weight: 600;
}

.the-icons {
    list-style: none
}

.avatar {
    display: inline-block
}

.avatar img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3)
}

.avatar-small {
    max-width: 32px;
    max-height: 32px
}

.twitter-color {
    color: #4099ff
}

.facebook-color {
    color: #35508F
}

.note {
    color: #909090;
    font-style: italic;
    font-size: 11px;
    margin-bottom: 10px
}

.close {
    line-height: 14px
}

.relative {
    position: relative
}

.indent {
    margin-left: 10px
}

.strikethrough {
    text-decoration: line-through
}

.box {
    -webkit-box-shadow: 0 2px 2px -2px #cccccc;
    -moz-box-shadow: 0 2px 2px -2px #cccccc;
    box-shadow: 0 2px 2px -2px #cccccc;
    background: #fbfbfb;
    border: 1px solid #cdcdcd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 20px
}

.box .box-header, .box .box-footer {
    *zoom: 1;
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea);
    text-shadow: 0 1px white;
    border-bottom: 1px solid #CDCDCD;
    color: #636363;
    font-weight: 600
}

.box .box-header:before, .box .box-header:after, .box .box-footer:before, .box .box-footer:after {
    display: table;
    content: "";
    line-height: 0
}

.box .box-header:after, .box .box-footer:after {
    clear: both
}

.box .box-header .title, .box .box-footer .title {
    float: left;
    display: block;
    padding: 10px;
    font-size: 12px;
    font-weight: 600
}

.box .box-header .box-toolbar, .box .box-footer .box-toolbar {
    margin: 0;
    padding: 0;
    float: right;
    list-style: none
}

.box .box-header .box-toolbar .ibutton-container, .box .box-footer .box-toolbar .ibutton-container {
    margin-top: 6px
}

.box .box-header .box-toolbar > li, .box .box-footer .box-toolbar > li {
    position: relative;
    float: left;
    margin-left: 15px;
    line-height: 37px;
    padding-right: 10px;
    color: #636363;
    font-size: 12px
}

.box .box-header .box-toolbar > li ul, .box .box-footer .box-toolbar > li ul {
    left: auto;
    right: 0
}

.box .box-header .box-toolbar > li.toolbar-link, .box .box-footer .box-toolbar > li.toolbar-link {
    padding-right: 0
}

.box .box-header .box-toolbar > li.toolbar-link > a, .box .box-footer .box-toolbar > li.toolbar-link > a {
    border-left: 1px solid #cecece;
    height: 37px;
    line-height: 37px;
    display: block;
    padding: 0 10px
}

.box .box-header .box-toolbar > li.toolbar-link > a i, .box .box-footer .box-toolbar > li.toolbar-link > a i {
    font-size: 16px
}

.box .box-header .box-toolbar > li.toolbar-link > a:hover, .box .box-footer .box-toolbar > li.toolbar-link > a:hover {
    background: #efefef;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VmZWZlZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #efefef));
    background-image: -webkit-linear-gradient(to bottom, #ffffff, #efefef);
    background-image: -moz-linear-gradient(to bottom, #ffffff, #efefef);
    background-image: -o-linear-gradient(to bottom, #ffffff, #efefef);
    background-image: linear-gradient(to bottom, #ffffff, #efefef)
}

.box .box-header .box-toolbar > li.toolbar-link > a:active, .box .box-footer .box-toolbar > li.toolbar-link > a:active {
    background: #e5e5e5
}

.box .box-header .box-toolbar > li > i, .box .box-footer .box-toolbar > li > i {
    font-size: 14px;
    height: 37px;
    line-height: 37px
}

.box .box-header .box-toolbar > li.open > a, .box .box-footer .box-toolbar > li.open > a {
    background: #eee
}

.box .box-header {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px
}

.box .box-footer {
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom: none;
    border-top: 1px solid var(--table-border-color)
}

.box .box-footer .pagination {
    margin: 10px 0
}

.box .box-footer.flat {
    border-top: none;
    background: #f1f1f1;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YxZjFmMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fbfbfb), color-stop(100%, #f1f1f1));
    background-image: -webkit-linear-gradient(to bottom, #fbfbfb, #f1f1f1);
    background-image: -moz-linear-gradient(to bottom, #fbfbfb, #f1f1f1);
    background-image: -o-linear-gradient(to bottom, #fbfbfb, #f1f1f1);
    background-image: linear-gradient(to bottom, #fbfbfb, #f1f1f1)
}

.box-list {
    list-style: none;
    margin: 0
}

.box-list > li {
    *zoom: 1;
    background: #f1f1f1;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YxZjFmMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fbfbfb), color-stop(100%, #f1f1f1));
    background-image: -webkit-linear-gradient(to bottom, #fbfbfb, #f1f1f1);
    background-image: -moz-linear-gradient(to bottom, #fbfbfb, #f1f1f1);
    background-image: -o-linear-gradient(to bottom, #fbfbfb, #f1f1f1);
    background-image: linear-gradient(to bottom, #fbfbfb, #f1f1f1);
    text-shadow: 0 1px white;
    padding: 10px;
    border-bottom: 1px solid #ddd
}

.box-list > li:before, .box-list > li:after {
    display: table;
    content: "";
    line-height: 0
}

.box-list > li:after {
    clear: both
}

.box-list > li:last-child {
    border-bottom: none
}

.box-list .fields li {
    line-height: 23px
}

.box-section {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #fff;
    padding: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.box-section:last-child {
    border-bottom: none
}

.faded-toolbar a {
    margin-right: 10px
}

.faded-toolbar.pull-right a {
    margin-left: 10px;
    margin-right: 0
}

.faded-toolbar i {
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    vertical-align: middle;
    color: #989898;
    opacity: .6
}

.faded-toolbar i:hover {
    opacity: 1
}

.box .box-header .nav {
    margin-bottom: 0
}

.box .box-header .nav-tabs + .title {
    float: right
}

.box .box-header .nav-tabs {
    border-bottom: none
}

.box .box-header .nav-tabs > .active > a, .box .box-header .nav-tabs > .active > a:hover {
    background: #fbfbfb;
    border-left-color: var(--table-border-color);
    border-right-color: var(--table-border-color);
    border-top-color: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.box .box-header .nav-tabs.nav-tabs-left {
    float: left
}

.box .box-header .nav-tabs.nav-tabs-left li:first-child a {
    border-left: 0;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px
}

.box .box-header .nav-tabs.nav-tabs-right {
    float: right
}

.box .box-header .nav-tabs.nav-tabs-right li:last-child a {
    border-right: 0;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    margin-right: 0
}

.box .box-header .nav > li > a:hover {
    border-color: transparent;
    background: none
}

.box-header .tooltip {
    line-height: 20px !important;
    text-shadow: none
}

.box-header .progress {
    margin-top: 15px
}

.box-header .input-with-submit input {
    margin-bottom: 0;
    margin-top: 5px
}

.chat-message-box {
    position: relative;
    background: white;
    border: 1px solid #d8d8d8;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.chat-message-box:after, .chat-message-box:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.chat-message-box:after {
    border-bottom-color: white;
    border-width: 7px;
    left: 10px
}

.chat-message-box:before {
    border-bottom-color: #d8d8d8;
    border-width: 8px;
    left: 9px
}

.chat-message-box {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    margin-bottom: 15px
}

.chat-message-box textarea {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 5px;
    width: 97% !important;
    background: #fff
}

.chat-message-box textarea:focus {
    border: none
}

.closable-chat-box .chat-message-box {
    margin-bottom: 0
}

.closable-chat-box .chat-message-box textarea {
    height: 20px
}

.closable-chat-box .actions {
    display: none
}

.closable-chat-box.open .chat-message-box {
    margin-bottom: 15px
}

.closable-chat-box.open .chat-message-box textarea {
    height: auto
}

.closable-chat-box.open .actions {
    display: block
}

.closable-chat-box .fields {
    *zoom: 1;
    position: relative
}

.closable-chat-box .fields:before, .closable-chat-box .fields:after {
    display: table;
    content: "";
    line-height: 0
}

.closable-chat-box .fields:after {
    clear: both
}

.closable-chat-box .fields ul {
    float: left;
    margin-left: 50px;
    margin-bottom: 2px;
    list-style: none;
    padding: 0
}

.closable-chat-box .fields ul li {
    line-height: 18px;
    color: #949494
}

.closable-chat-box .fields a {
    color: #393939
}

.closable-chat-box .fields .avatar {
    position: absolute;
    left: 0
}

.closable-chat-box .fields .avatar img {
    max-width: 36px;
    max-height: 36px
}

.dark-nav > ul {
    margin: 0;
    padding: 0;
    background: #11161A;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 0 4px 2px inset;
    -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 0 4px 2px inset;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 4px 2px inset
}

.dark-nav > ul:before {
    border-bottom: 7px solid #0a0d0e !important
}

.dark-nav > ul:after {
    border-bottom: 6px solid #0a0d0e !important
}

.dark-nav > ul li.active a {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    background: rgba(50, 50, 50, 0.1);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0icmdiYSg5MCwgOTAsIDkwLCAwLjIpIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJyZ2JhKDUwLCA1MCwgNTAsIDAuMSkiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, rgba(90, 90, 90, 0.2)), color-stop(100%, rgba(50, 50, 50, 0.1)));
    background-image: -webkit-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: -moz-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: -o-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1))
}

.dark-nav > ul li.active a:hover {
    background: rgba(50, 50, 50, 0.1);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0icmdiYSg5MCwgOTAsIDkwLCAwLjIpIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJyZ2JhKDUwLCA1MCwgNTAsIDAuMSkiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, rgba(90, 90, 90, 0.2)), color-stop(100%, rgba(50, 50, 50, 0.1)));
    background-image: -webkit-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: -moz-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: -o-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1))
}

.dark-nav > ul li a {
    text-shadow: none;
    text-align: left;
    font-size: 11px;
    padding: 6px 5px 6px 20px;
    border-top: 1px solid #20292f;
    border-bottom: 1px solid #0a0d0e;
    color: #bbb;
    display: block
}

.dark-nav > ul li a:hover {
    background: rgba(50, 50, 50, 0.1);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0icmdiYSg5MCwgOTAsIDkwLCAwLjIpIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJyZ2JhKDUwLCA1MCwgNTAsIDAuMSkiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, rgba(90, 90, 90, 0.2)), color-stop(100%, rgba(50, 50, 50, 0.1)));
    background-image: -webkit-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: -moz-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: -o-linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    background-image: linear-gradient(to bottom, rgba(90, 90, 90, 0.2) 0, rgba(50, 50, 50, 0.1));
    color: #fff
}

.dark-nav > ul li:last-child a {
    border-bottom: none
}

.dark-nav > ul li:first-child a {
    border-top: none
}

.dark-nav .dropdown-menu {
    float: none;
    position: relative;
    width: 100%;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1) inset;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.dark-nav .dropdown-menu:before {
    content: '';
    display: inline-block;
    border-top: 7px solid transparent !important;
    border-left: 7px solid transparent !important;
    border-right: 8px solid #11161A;
    border-bottom: 7px solid transparent !important;
    position: absolute;
    top: 12px;
    left: -15px
}

.navbar-inverse .nav.full {
    margin: 0;
    margin-left: 10px
}

.navbar-inverse .nav.full > li > a {
    height: 40px;
    line-height: 40px;
    padding: 3px 10px;
    margin-right: 10px;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.navbar-inverse .nav.full > li > a:hover {
    background: transparent
}

.navbar-inverse .nav.full > li > a img {
    width: 32px;
    height: 32px
}

.dropdown-menu {
    background: whitesmoke;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y1ZjVmNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #f5f5f5));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #f5f5f5);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #f5f5f5);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #f5f5f5);
    background-image: linear-gradient(to bottom, #fdfdfd, #f5f5f5);
    -webkit-box-shadow: 0 1px 2px #dadada;
    -moz-box-shadow: 0 1px 2px #dadada;
    box-shadow: 0 1px 2px #dadada
}

.dropdown-menu li > a:hover, .dropdown-menu .dropdown-menu li > a:focus, .dropdown-menu .dropdown-submenu:hover > a {
    background: #f2f2f2;
    color: #404040
}

.dropdown-menu li a {
    text-shadow: 0 1px white;
    color: #475967;
    font-weight: 600
}

.dropdown-menu li a i {
    color: #BCBECA
}

.dropdown-menu li.with-image {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px
}

.dropdown-menu li.with-image .avatar img {
    max-width: 100px;
    max-height: 100px;
    border: 4px solid #fff
}

.dropdown-menu li.with-image span {
    text-shadow: 0 1px white;
    display: block;
    margin-top: 15px;
    color: #9EA1AA;
    font-size: 16px;
    font-weight: 600
}

@font-face {
    font-family: 'FontAwesome';
    src: url("../_legacyDesign/fontawesome/fonts/fontawesome-webfont.eot?v=4.7.0");
    src: url("../_legacyDesign/fontawesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
        url("../_legacyDesign/fontawesome/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
        url("../_legacyDesign/fontawesome/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype");
    font-weight: 400;
    font-style: normal
}

[class^="icon-"], [class*=" icon-"] {
    font-family: FontAwesome;
    font-weight: 400;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    display: inline;
    width: auto;
    height: auto;
    line-height: normal;
    vertical-align: baseline;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    margin-top: 0
}

.icon-white, .nav-pills > .active > a > [class^="icon-"], .nav-pills > .active > a > [class*=" icon-"], .nav-list > .active > a > [class^="icon-"], .nav-list > .active > a > [class*=" icon-"], .navbar-inverse .nav > .active > a > [class^="icon-"], .navbar-inverse .nav > .active > a > [class*=" icon-"], .dropdown-menu > li > a:hover > [class^="icon-"], .dropdown-menu > li > a:hover > [class*=" icon-"], .dropdown-menu > .active > a > [class^="icon-"], .dropdown-menu > .active > a > [class*=" icon-"], .dropdown-submenu:hover > a > [class^="icon-"], .dropdown-submenu:hover > a > [class*=" icon-"] {
    background-image: none
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    text-decoration: inherit;
    display: inline-block;
    speak: none
}

a [class^="icon-"], a [class*=" icon-"] {
    display: inline-block
}

.icon-large:before {
    vertical-align: -10%;
    font-size: 1.3333333333333333em
}

.btn [class^="icon-"], .btn [class*=" icon-"], .nav [class^="icon-"], .nav [class*=" icon-"] {
    display: inline
}

.btn [class^="icon-"].icon-large, .btn [class*=" icon-"].icon-large, .nav [class^="icon-"].icon-large, .nav [class*=" icon-"].icon-large {
    line-height: .9em
}

.btn [class^="icon-"].icon-spin, .btn [class*=" icon-"].icon-spin, .nav [class^="icon-"].icon-spin, .nav [class*=" icon-"].icon-spin {
    display: inline-block
}

.nav-tabs [class^="icon-"], .nav-tabs [class*=" icon-"], .nav-pills [class^="icon-"], .nav-pills [class*=" icon-"] {
}

.nav-tabs [class^="icon-"], .nav-tabs [class^="icon-"].icon-large, .nav-tabs [class*=" icon-"], .nav-tabs [class*=" icon-"].icon-large, .nav-pills [class^="icon-"], .nav-pills [class^="icon-"].icon-large, .nav-pills [class*=" icon-"], .nav-pills [class*=" icon-"].icon-large {
    line-height: .9em
}

li [class^="icon-"], li [class*=" icon-"], .nav li [class^="icon-"], .nav li [class*=" icon-"] {
    display: inline-block;
    width: 1.25em;
    text-align: center
}

li [class^="icon-"].icon-large, li [class*=" icon-"].icon-large, .nav li [class^="icon-"].icon-large, .nav li [class*=" icon-"].icon-large {
    width: 1.5625em
}

ul.icons {
    list-style-type: none;
    text-indent: -.75em
}

ul.icons li [class^="icon-"], ul.icons li [class*=" icon-"] {
    width: .75em
}

.icon-muted {
    color: #eeeeee
}

.icon-border {
    border: solid 1px #eeeeee;
    padding: .2em .25em .15em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.icon-2x {
    font-size: 2em
}

.icon-2x.icon-border {
    border-width: 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.icon-3x {
    font-size: 3em
}

.icon-3x.icon-border {
    border-width: 3px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.icon-4x {
    font-size: 4em
}

.icon-4x.icon-border {
    border-width: 4px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

[class^="icon-"].pull-left, [class*=" icon-"].pull-left {
    margin-right: .3em
}

[class^="icon-"].pull-right, [class*=" icon-"].pull-right {
    margin-left: .3em
}

.btn [class^="icon-"].pull-left.icon-2x, .btn [class^="icon-"].pull-right.icon-2x, .btn [class*=" icon-"].pull-left.icon-2x, .btn [class*=" icon-"].pull-right.icon-2x {
    margin-top: .18em
}

.btn [class^="icon-"].icon-spin.icon-large, .btn [class*=" icon-"].icon-spin.icon-large {
    line-height: .8em
}

.btn.btn-small [class^="icon-"].pull-left.icon-2x, .btn.btn-small [class^="icon-"].pull-right.icon-2x, .btn.btn-small [class*=" icon-"].pull-left.icon-2x, .btn.btn-small [class*=" icon-"].pull-right.icon-2x {
    margin-top: .25em
}

.btn.btn-large [class^="icon-"], .btn.btn-large [class*=" icon-"] {
    margin-top: 0
}

.btn.btn-large [class^="icon-"].pull-left.icon-2x, .btn.btn-large [class^="icon-"].pull-right.icon-2x, .btn.btn-large [class*=" icon-"].pull-left.icon-2x, .btn.btn-large [class*=" icon-"].pull-right.icon-2x {
    margin-top: .05em
}

.btn.btn-large [class^="icon-"].pull-left.icon-2x, .btn.btn-large [class*=" icon-"].pull-left.icon-2x {
    margin-right: .2em
}

.btn.btn-large [class^="icon-"].pull-right.icon-2x, .btn.btn-large [class*=" icon-"].pull-right.icon-2x {
    margin-left: .2em
}

.icon-spin {
    display: inline-block;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg)
    }
    100% {
        -moz-transform: rotate(359deg)
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(359deg)
    }
}

@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg)
    }
    100% {
        -o-transform: rotate(359deg)
    }
}

@-ms-keyframes spin {
    0% {
        -ms-transform: rotate(0deg)
    }
    100% {
        -ms-transform: rotate(359deg)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(359deg)
    }
}

@-moz-document url-prefix(){.icon-spin{height:.9em}.btn .icon-spin{height:auto}.icon-spin.icon-large{height:1.25em}.btn .icon-spin.icon-large{height:.75em}} .icon-glass:before {
                                                                                                                                                                 content: "\f000"
                                                                                                                                                             }

.icon-music:before {
    content: "\f001"
}

.icon-search:before {
    content: "\f002"
}

.icon-envelope:before {
    content: "\f003"
}

.icon-heart:before {
    content: "\f004"
}

.icon-star:before {
    content: "\f005"
}

.icon-star-empty:before {
    content: "\f006"
}

.icon-user:before {
    content: "\f007"
}

.icon-film:before {
    content: "\f008"
}

.icon-th-large:before {
    content: "\f009"
}

.icon-th:before {
    content: "\f00a"
}

.icon-th-list:before {
    content: "\f00b"
}

.icon-ok:before {
    content: "\f00c"
}

.icon-remove:before {
    content: "\f00d"
}

.icon-zoom-in:before {
    content: "\f00e"
}

.icon-zoom-out:before {
    content: "\f010"
}

.icon-off:before {
    content: "\f011"
}

.icon-signal:before {
    content: "\f012"
}

.icon-cog:before {
    content: "\f013"
}

.icon-trash:before {
    content: "\f014"
}

.icon-home:before {
    content: "\f015"
}

.icon-file:before {
    content: "\f016"
}

.icon-time:before {
    content: "\f017"
}

.icon-road:before {
    content: "\f018"
}

.icon-download-alt:before {
    content: "\f019"
}

.icon-download:before {
    content: "\f01a"
}

.icon-upload:before {
    content: "\f01b"
}

.icon-inbox:before {
    content: "\f01c"
}

.icon-play-circle:before {
    content: "\f01d"
}

.icon-repeat:before {
    content: "\f01e"
}

.icon-refresh:before {
    content: "\f021"
}

.icon-list-alt:before {
    content: "\f022"
}

.icon-lock:before {
    content: "\f023"
}

.icon-flag:before {
    content: "\f024"
}

.icon-headphones:before {
    content: "\f025"
}

.icon-volume-off:before {
    content: "\f026"
}

.icon-volume-down:before {
    content: "\f027"
}

.icon-volume-up:before {
    content: "\f028"
}

.icon-qrcode:before {
    content: "\f029"
}

.icon-barcode:before {
    content: "\f02a"
}

.icon-tag:before {
    content: "\f02b"
}

.icon-tags:before {
    content: "\f02c"
}

.icon-book:before {
    content: "\f02d"
}

.icon-bookmark:before {
    content: "\f02e"
}

.icon-print:before {
    content: "\f02f"
}

.icon-camera:before {
    content: "\f030"
}

.icon-font:before {
    content: "\f031"
}

.icon-bold:before {
    content: "\f032"
}

.icon-italic:before {
    content: "\f033"
}

.icon-text-height:before {
    content: "\f034"
}

.icon-text-width:before {
    content: "\f035"
}

.icon-align-left:before {
    content: "\f036"
}

.icon-align-center:before {
    content: "\f037"
}

.icon-align-right:before {
    content: "\f038"
}

.icon-align-justify:before {
    content: "\f039"
}

.icon-list:before {
    content: "\f03a"
}

.icon-indent-left:before {
    content: "\f03b"
}

.icon-indent-right:before {
    content: "\f03c"
}

.icon-facetime-video:before {
    content: "\f03d"
}

.icon-picture:before {
    content: "\f03e"
}

.icon-pencil:before {
    content: "\f040"
}

.icon-map-marker:before {
    content: "\f041"
}

.icon-adjust:before {
    content: "\f042"
}

.icon-tint:before {
    content: "\f043"
}

.icon-edit:before {
    content: "\f044"
}

.icon-share:before {
    content: "\f045"
}

.icon-check:before {
    content: "\f046"
}

.icon-move:before {
    content: "\f047"
}

.icon-step-backward:before {
    content: "\f048"
}

.icon-fast-backward:before {
    content: "\f049"
}

.icon-backward:before {
    content: "\f04a"
}

.icon-play:before {
    content: "\f04b"
}

.icon-pause:before {
    content: "\f04c"
}

.icon-stop:before {
    content: "\f04d"
}

.icon-forward:before {
    content: "\f04e"
}

.icon-fast-forward:before {
    content: "\f050"
}

.icon-step-forward:before {
    content: "\f051"
}

.icon-eject:before {
    content: "\f052"
}

.icon-chevron-left:before {
    content: "\f053"
}

.icon-chevron-right:before {
    content: "\f054"
}

.icon-plus-sign:before {
    content: "\f055"
}

.icon-minus-sign:before {
    content: "\f056"
}

.icon-remove-sign:before {
    content: "\f057"
}

.icon-ok-sign:before {
    content: "\f058"
}

.icon-question-sign:before {
    content: "\f059"
}

.icon-info-sign:before {
    content: "\f05a"
}

.icon-screenshot:before {
    content: "\f05b"
}

.icon-remove-circle:before {
    content: "\f05c"
}

.icon-ok-circle:before {
    content: "\f05d"
}

.icon-ban-circle:before {
    content: "\f05e"
}

.icon-arrow-left:before {
    content: "\f060"
}

.icon-arrow-right:before {
    content: "\f061"
}

.icon-arrow-up:before {
    content: "\f062"
}

.icon-arrow-down:before {
    content: "\f063"
}

.icon-share-alt:before {
    content: "\f064"
}

.icon-resize-full:before {
    content: "\f065"
}

.icon-resize-small:before {
    content: "\f066"
}

.icon-plus:before {
    content: "\f067"
}

.icon-minus:before {
    content: "\f068"
}

.icon-asterisk:before {
    content: "\f069"
}

.icon-exclamation-sign:before {
    content: "\f06a"
}

.icon-gift:before {
    content: "\f06b"
}

.icon-leaf:before {
    content: "\f06c"
}

.icon-fire:before {
    content: "\f06d"
}

.icon-eye-open:before {
    content: "\f06e"
}

.icon-eye-close:before {
    content: "\f070"
}

.icon-warning-sign:before {
    content: "\f071"
}

.icon-plane:before {
    content: "\f072"
}

.icon-calendar:before {
    content: "\f073"
}

.icon-random:before {
    content: "\f074"
}

.icon-comment:before {
    content: "\f075"
}

.icon-magnet:before {
    content: "\f076"
}

.icon-chevron-up:before {
    content: "\f077"
}

.icon-chevron-down:before {
    content: "\f078"
}

.icon-retweet:before {
    content: "\f079"
}

.icon-shopping-cart:before {
    content: "\f07a"
}

.icon-folder-close:before {
    content: "\f07b"
}

.icon-folder-open:before {
    content: "\f07c"
}

.icon-resize-vertical:before {
    content: "\f07d"
}

.icon-resize-horizontal:before {
    content: "\f07e"
}

.icon-bar-chart:before {
    content: "\f080"
}

.icon-twitter-sign:before {
    content: "\f081"
}

.icon-facebook-sign:before {
    content: "\f082"
}

.icon-camera-retro:before {
    content: "\f083"
}

.icon-key:before {
    content: "\f084"
}

.icon-cogs:before {
    content: "\f085"
}

.icon-comments:before {
    content: "\f086"
}

.icon-thumbs-up:before {
    content: "\f087"
}

.icon-thumbs-down:before {
    content: "\f088"
}

.icon-star-half:before {
    content: "\f089"
}

.icon-heart-empty:before {
    content: "\f08a"
}

.icon-signout:before {
    content: "\f08b"
}

.icon-linkedin-sign:before {
    content: "\f08c"
}

.icon-pushpin:before {
    content: "\f08d"
}

.icon-external-link:before {
    content: "\f08e"
}

.icon-signin:before {
    content: "\f090"
}

.icon-trophy:before {
    content: "\f091"
}

.icon-github-sign:before {
    content: "\f092"
}

.icon-upload-alt:before {
    content: "\f093"
}

.icon-lemon:before {
    content: "\f094"
}

.icon-phone:before {
    content: "\f095"
}

.icon-check-empty:before {
    content: "\f096"
}

.icon-bookmark-empty:before {
    content: "\f097"
}

.icon-phone-sign:before {
    content: "\f098"
}

.icon-twitter:before {
    content: "\f099"
}

.icon-facebook:before {
    content: "\f09a"
}

.icon-github:before {
    content: "\f09b"
}

.icon-unlock:before {
    content: "\f09c"
}

.icon-credit-card:before {
    content: "\f09d"
}

.icon-rss:before {
    content: "\f09e"
}

.icon-hdd:before {
    content: "\f0a0"
}

.icon-bullhorn:before {
    content: "\f0a1"
}

.icon-bell:before {
    content: "\f0a2"
}

.icon-certificate:before {
    content: "\f0a3"
}

.icon-hand-right:before {
    content: "\f0a4"
}

.icon-hand-left:before {
    content: "\f0a5"
}

.icon-hand-up:before {
    content: "\f0a6"
}

.icon-hand-down:before {
    content: "\f0a7"
}

.icon-circle-arrow-left:before {
    content: "\f0a8"
}

.icon-circle-arrow-right:before {
    content: "\f0a9"
}

.icon-circle-arrow-up:before {
    content: "\f0aa"
}

.icon-circle-arrow-down:before {
    content: "\f0ab"
}

.icon-globe:before {
    content: "\f0ac"
}

.icon-wrench:before {
    content: "\f0ad"
}

.icon-tasks:before {
    content: "\f0ae"
}

.icon-filter:before {
    content: "\f0b0"
}

.icon-briefcase:before {
    content: "\f0b1"
}

.icon-fullscreen:before {
    content: "\f0b2"
}

.icon-group:before {
    content: "\f0c0"
}

.icon-link:before {
    content: "\f0c1"
}

.icon-cloud:before {
    content: "\f0c2"
}

.icon-beaker:before {
    content: "\f0c3"
}

.icon-cut:before {
    content: "\f0c4"
}

.icon-copy:before {
    content: "\f0c5"
}

.icon-paper-clip:before {
    content: "\f0c6"
}

.icon-save:before {
    content: "\f0c7"
}

.icon-sign-blank:before {
    content: "\f0c8"
}

.icon-reorder:before {
    content: "\f0c9"
}

.icon-list-ul:before {
    content: "\f0ca"
}

.icon-list-ol:before {
    content: "\f0cb"
}

.icon-strikethrough:before {
    content: "\f0cc"
}

.icon-underline:before {
    content: "\f0cd"
}

.icon-table:before {
    content: "\f0ce"
}

.icon-magic:before {
    content: "\f0d0"
}

.icon-truck:before {
    content: "\f0d1"
}

.icon-pinterest:before {
    content: "\f0d2"
}

.icon-pinterest-sign:before {
    content: "\f0d3"
}

.icon-google-plus-sign:before {
    content: "\f0d4"
}

.icon-google-plus:before {
    content: "\f0d5"
}

.icon-money:before {
    content: "\f0d6"
}

.icon-caret-down:before {
    content: "\f0d7"
}

.icon-caret-up:before {
    content: "\f0d8"
}

.icon-caret-left:before {
    content: "\f0d9"
}

.icon-caret-right:before {
    content: "\f0da"
}

.icon-columns:before {
    content: "\f0db"
}

.icon-sort:before {
    content: "\f0dc"
}

.icon-sort-down:before {
    content: "\f0dd"
}

.icon-sort-up:before {
    content: "\f0de"
}

.icon-envelope-alt:before {
    content: "\f0e0"
}

.icon-linkedin:before {
    content: "\f0e1"
}

.icon-undo:before {
    content: "\f0e2"
}

.icon-legal:before {
    content: "\f0e3"
}

.icon-dashboard:before {
    content: "\f0e4"
}

.icon-comment-alt:before {
    content: "\f0e5"
}

.icon-comments-alt:before {
    content: "\f0e6"
}

.icon-bolt:before {
    content: "\f0e7"
}

.icon-sitemap:before {
    content: "\f0e8"
}

.icon-umbrella:before {
    content: "\f0e9"
}

.icon-paste:before {
    content: "\f0ea"
}

.icon-lightbulb:before {
    content: "\f0eb"
}

.icon-exchange:before {
    content: "\f0ec"
}

.icon-cloud-download:before {
    content: "\f0ed"
}

.icon-cloud-upload:before {
    content: "\f0ee"
}

.icon-user-md:before {
    content: "\f0f0"
}

.icon-stethoscope:before {
    content: "\f0f1"
}

.icon-suitcase:before {
    content: "\f0f2"
}

.icon-bell-alt:before {
    content: "\f0f3"
}

.icon-coffee:before {
    content: "\f0f4"
}

.icon-food:before {
    content: "\f0f5"
}

.icon-file-alt:before {
    content: "\f0f6"
}

.icon-building:before {
    content: "\f0f7"
}

.icon-hospital:before {
    content: "\f0f8"
}

.icon-ambulance:before {
    content: "\f0f9"
}

.icon-medkit:before {
    content: "\f0fa"
}

.icon-fighter-jet:before {
    content: "\f0fb"
}

.icon-beer:before {
    content: "\f0fc"
}

.icon-h-sign:before {
    content: "\f0fd"
}

.icon-plus-sign-alt:before {
    content: "\f0fe"
}

.icon-double-angle-left:before {
    content: "\f100"
}

.icon-double-angle-right:before {
    content: "\f101"
}

.icon-double-angle-up:before {
    content: "\f102"
}

.icon-double-angle-down:before {
    content: "\f103"
}

.icon-angle-left:before {
    content: "\f104"
}

.icon-angle-right:before {
    content: "\f105"
}

.icon-angle-up:before {
    content: "\f106"
}

.icon-angle-down:before {
    content: "\f107"
}

.icon-desktop:before {
    content: "\f108"
}

.icon-laptop:before {
    content: "\f109"
}

.icon-tablet:before {
    content: "\f10a"
}

.icon-mobile-phone:before {
    content: "\f10b"
}

.icon-circle-blank:before {
    content: "\f10c"
}

.icon-quote-left:before {
    content: "\f10d"
}

.icon-quote-right:before {
    content: "\f10e"
}

.icon-spinner:before {
    content: "\f110"
}

.icon-circle:before {
    content: "\f111"
}

.icon-reply:before {
    content: "\f112"
}

.icon-github-alt:before {
    content: "\f113"
}

.icon-folder-close-alt:before {
    content: "\f114"
}

.icon-folder-open-alt:before {
    content: "\f115"
}

@media only screen and (max-width:767px) {
    table.responsive {
        margin-bottom: 0
    }

    .pinned {
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        width: 35%;
        overflow: hidden;
        overflow-x: scroll;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc
    }

    .pinned table {
        border-right: none;
        border-left: none;
        width: 100%
    }

    .pinned table th, .pinned table td {
        white-space: nowrap
    }

    .pinned td:last-child {
        border-bottom: 0
    }

    div.table-wrapper {
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        border-right: 1px solid #ccc
    }

    div.table-wrapper div.scrollable table {
        margin-left: 35%
    }

    div.table-wrapper div.scrollable {
        overflow: scroll;
        overflow-y: hidden
    }

    table.responsive td, table.responsive th {
        position: relative;
        white-space: nowrap;
        overflow: hidden
    }

    table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td {
        display: none
    }
}

.fc {
    direction: ltr;
    text-align: left
}

.fc table {
    border-collapse: collapse;
    border-spacing: 0
}

html .fc {
    font-size: 1em
}

.fc table {
    font-size: 1em
}

.fc td, .fc th {
    padding: 0;
    vertical-align: top
}

.fc-header {
    position: relative
}

.fc-header td {
    white-space: nowrap
}

.fc-header-left {
    text-align: left;
    width: 100%
}

.fc-header-center {
    text-align: center;
    position: absolute;
    top: 0;
    width: 80%;
    left: 10%;
    line-height: 37px
}

.fc-button-inner {
    border-left: 1px solid #d5d5d5
}

.fc-header-left .fc-button-inner {
    border: none
}

.fc-header-right {
    text-align: right;
    position: absolute;
    top: -37px;
    right: 0
}

.fc-header-right .fc-button-agendaDay .fc-button-inner {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px
}

.fc-header-title {
    display: inline-block;
    vertical-align: top
}

.fc-header-title h2 {
    font-size: 12px;
    margin: 0
}

.fc-header-title strong {
    margin-top: 0;
    white-space: nowrap;
    padding: 8px 12px !important;
    display: block
}

.fc .fc-header-space {
    padding-left: 10px
}

.fc-header .fc-corner-right {
    margin-right: 0;
    float: right
}

.fc-header .ui-corner-right {
    margin-right: 0
}

.fc-header .fc-state-hover, .fc-header .ui-state-hover {
    z-index: 2
}

.fc-header .fc-state-down {
    z-index: 3
}

.fc-header .fc-state-active, .fc-header .ui-state-active {
    z-index: 4
}

.fc-button-next {
    float: right;
    margin-right: -2px
}

.fc-view {
    width: 100%;
    overflow: hidden
}

.fc-border-separate thead {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea);
    height: 25px;
    line-height: 25px
}

.fc-widget-header, .fc-widget-content {
    border: 1px solid #ccc
}

.fc-state-highlight {
    background: #f4f3e6
}

.fc-cell-overlay {
    background: #99CCFF;
    opacity: .2
}

.fc-button {
    position: relative;
    cursor: pointer
}

.fc-button-inner {
    position: relative;
    float: left;
    overflow: hidden
}

.fc-button-content {
    position: relative;
    float: left;
    height: 37px;
    line-height: 37px;
    padding: 0 14px;
    white-space: nowrap
}

.fc-header-left .fc-button-content {
    padding: 0 8px
}

.fc-header-right .fc-button-content {
    height: 37px
}

.fc-state-default .fc-button-effect {
    position: absolute;
    top: 50%;
    left: 0
}

.fc-state-active {
    color: #797979;
    background: #f9f9f9;
    box-shadow: 0 -1px 0 #eaeaea inset
}

.fc-state-active .fc-button-inner {
    color: #797979;
    background: #f9f9f9;
    box-shadow: 0 -1px 0 #eaeaea inset
}

.fc-first th {
    padding-top: 1px
}

.fc-state-disabled {
    color: #999;
    border-color: #ddd;
    cursor: default
}

.fc-state-disabled .fc-button-inner {
    color: #999;
    border-color: #ddd
}

.fc-state-disabled .fc-button-effect {
    display: none
}

.fc-event {
    border-style: solid;
    border-width: 0;
    font-size: .85em;
    cursor: default
}

a.fc-event, .fc-event-draggable {
    cursor: pointer
}

a.fc-event {
    text-decoration: none
}

.fc-rtl .fc-event {
    text-align: right
}

.fc-event-skin {
    border-color: #32779A;
    background-color: #729BB9;
    color: #fff;
    display: block;
    padding: 1px 0;
    font-size: 11px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
    border-radius: 2px;
    margin-top: 1px
}

.fc-event-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0;
    overflow: hidden
}

.fc-event-time, .fc-event-title {
    padding: 0 2px 1px 5px;
    display: block;
    float: left;
    line-height: 16px
}

.fc .ui-resizable-handle {
    display: block;
    position: absolute;
    z-index: 99999;
    overflow: hidden;
    font-size: 300%;
    line-height: 50%
}

.fc-event-hori {
    margin-bottom: 1px
}

.fc-event-hori .ui-resizable-e {
    top: 0 !important;
    right: -3px !important;
    width: 7px !important;
    height: 100% !important;
    cursor: e-resize
}

.fc-event-hori .ui-resizable-w {
    top: 0 !important;
    left: -3px !important;
    width: 7px !important;
    height: 100% !important;
    cursor: w-resize
}

.fc-corner-right {
    margin-right: 1px
}

.fc-corner-right .fc-button-inner, .fc-corner-right .fc-event-inner {
    margin-right: 0
}

.fc-corner-top {
    margin-top: 1px
}

.fc-corner-top .fc-event-inner {
    margin-top: -1px
}

.fc-corner-bottom {
    margin-bottom: 1px
}

.fc-corner-bottom .fc-event-inner {
    margin-bottom: -1px
}

.fc-corner-top .fc-event-inner {
    border-top-width: 1px
}

.fc-corner-bottom .fc-event-inner {
    border-bottom-width: 1px
}

table.fc-border-separate {
    border-collapse: separate
}

.fc-border-separate th {
    border-width: 1px 0 0 1px
}

.fc-border-separate td {
    border-width: 1px 0 0 1px
}

.fc-border-separate td:first-child {
    border-left: none
}

.fc-border-separate th:first-child {
    border-left: none
}

.fc-border-separate tr.fc-last th, .fc-border-separate tr.fc-last td {
    border-top-width: 1px
}

.fc-border-separate tbody tr.fc-first td, .fc-border-separate tbody tr.fc-first th {
    border-top-width: 1px
}

.fc-widget-header {
    text-align: center
}

.fc-day-number {
    float: right;
    padding: 6px 5px 2px 5px
}

.fc-cols .fc-other-month .fc-day-number {
    opacity: .3;
    filter: alpha(opacity=30)
}

.fc-day-content {
    clear: both;
    padding: 5px 2px 3px
}

.fc-cols .fc-event-time {
    font-weight: 700
}

.fc-rtl .fc-cols .fc-day-number {
    float: left
}

.fc-rtl .fc-cols .fc-event-time {
    float: right
}

.fc-agenda table {
    border-collapse: separate
}

.fc-agenda-days th {
    text-align: center
}

.fc-agenda .fc-agenda-axis {
    width: 50px;
    padding: 0 4px;
    vertical-align: middle;
    text-align: right;
    white-space: nowrap;
    font-weight: 400
}

.fc-agenda .fc-day-content {
    padding: 2px 2px 1px
}

.fc-agenda-days .fc-agenda-axis {
    border-right-width: 1px
}

.fc-agenda-days .fc-col0 {
    border-left-width: 0
}

.fc-agenda-allday th {
    border-width: 0 1px
}

.fc-agenda-allday .fc-day-content {
    min-height: 34px;
    _height: 34px
}

.fc-agenda-divider-inner {
    height: 2px;
    overflow: hidden
}

.fc-widget-header .fc-agenda-divider-inner {
    background: #eee
}

.fc-agenda-slots th {
    border-width: 1px 1px 0
}

.fc-agenda-slots td {
    border-width: 1px 0 0;
    background: none
}

.fc-agenda-slots td div {
    height: 20px
}

.fc-agenda-slots tr.fc-slot0 th, .fc-agenda-slots tr.fc-slot0 td {
    border-top-width: 0
}

.fc-agenda-slots tr.fc-minor th, .fc-agenda-slots tr.fc-minor td {
    border-top-style: dotted
}

.fc-agenda-slots tr.fc-minor th.ui-widget-header {
    *border-top-style: solid
}

.fc-event-vert {
    border-width: 0 1px
}

.fc-event-vert .fc-event-head, .fc-event-vert .fc-event-content {
    position: relative;
    z-index: 2;
    width: 100%;
    overflow: hidden
}

.fc-event-vert .fc-event-time {
    white-space: nowrap;
    font-size: 10px
}

.fc-event-vert .fc-event-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: .3;
    filter: alpha(opacity=30)
}

.fc .ui-draggable-dragging .fc-event-bg, .fc-select-helper .fc-event-bg {
    display: none
}

.fc-event-vert .ui-resizable-s {
    bottom: 0 !important;
    width: 100% !important;
    height: 8px !important;
    overflow: hidden !important;
    line-height: 8px !important;
    font-size: 11px !important;
    font-family: monospace;
    text-align: center;
    cursor: s-resize
}

.fc-agenda .ui-resizable-resizing {
    overflow: hidden
}

#gritter-notice-wrapper {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 301px;
    z-index: 9999
}

#gritter-notice-wrapper.top-left {
    left: 20px;
    right: auto
}

#gritter-notice-wrapper.bottom-right {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px
}

#gritter-notice-wrapper.bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px
}

.gritter-item-wrapper {
    position: relative;
    margin: 0 0 10px 0;
    /*background: url("../_legacyDesign/images/ie-spacer.gif")*/
}

.gritter-item-wrapper.warn .gritter-title {
    color: orange
}

.gritter-item-wrapper.error .gritter-title {
    color: #ff4d4d
}

.gritter-item-wrapper.success .gritter-title {
    color: #00cc00
}

.gritter-item {
    display: block;
    background: rgba(0, 0, 0, 0.7);
    color: #eee;
    padding: 10px;
    font-size: 11px;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px
}

.hover .gritter-item {
    background-position: right -40px
}

.gritter-item p {
    padding: 0;
    margin: 0;
    word-wrap: break-word
}

.gritter-close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 100%;
    height: 100%
}

.gritter-title {
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 7px 0;
    display: block;
    text-shadow: 1px 1px 0 #000
}

.gritter-image {
    width: 48px;
    height: 48px;
    float: left
}

.gritter-with-image, .gritter-without-image {
    padding: 0
}

.gritter-with-image {
    width: 220px;
    float: right
}

.num-stats {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #717e88;
    *zoom: 1
}

.num-stats:before, .num-stats:after {
    display: table;
    content: "";
    line-height: 0
}

.num-stats:after {
    clear: both
}

.num-stats li {
    text-align: center;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    border-right: 1px dotted #bbbbbb;
    border-left: 1px dotted whitesmoke;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.num-stats li:first-child {
    border-left: none
}

.num-stats li:last-child {
    border-right: none
}

.num-stats li .number {
    text-shadow: 0 1px #fff;
    font-size: 18px;
    font-weight: 600;
    display: block;
    line-height: 18px
}

.num-stats li i {
    color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 14px;
    float: left;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6)
}

.num-stats li.stat-blue .number {
    color: #6e97aa
}

.num-stats li.stat-blue i {
    background: #6e97aa;
    text-shadow: 0 1px rgba(66, 98, 112, 0.5)
}

.num-stats li.stat-blue strong {
    color: #6e97aa
}

.num-stats li.stat-red .number {
    color: #ba6d6d
}

.num-stats li.stat-red i {
    background: #ba6d6d;
    text-shadow: 0 1px rgba(131, 62, 62, 0.5)
}

.num-stats li.stat-red strong {
    color: #ba6d6d
}

.num-stats li.stat-green .number {
    color: #8fae53
}

.num-stats li.stat-green i {
    background: #8fae53;
    text-shadow: 0 1px rgba(86, 105, 50, 0.5)
}

.num-stats li.stat-green strong {
    color: #8fae53
}

.well .num-stats li {
    border-right-color: #bbb;
    border-left-color: transparent
}

.area-top .num-stats {
    margin-top: 5px
}

.area-top .num-stats li {
    border: none
}

form.fill-up {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0
}

form.fill-up * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

form.fill-up input, form.fill-up textarea, form.fill-up select {
    width: 100%
}

form.fill-up .padded .control-group:last-child {
    margin-bottom: 0
}

form.fill-up.form-horizontal .form-actions {
    padding-left: 20px
}

form.fill-up .radio, form.fill-up .checkbox {
    padding-left: 0
}

.form-actions {
    margin-bottom: 0
}

.box form {
    padding: 0
}

form {
    margin-bottom: 0
}

.input-append, .input-prepend {
    height: 36px;
    line-height: 36px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    z-index: 1
}

.input-append .add-on i, .input-prepend .add-on i {
    line-height: 26px
}

.input-append *, .input-prepend * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.input-append a.add-on, .input-append span.add-on, .input-prepend a.add-on, .input-prepend span.add-on {
    min-width: 36px;
    color: #5a6573;
    position: absolute;
    height: 100%;
    z-index: 2;
    -webkit-box-shadow: inset 0 1px 2px white;
    -moz-box-shadow: inset 0 1px 2px white;
    box-shadow: inset 0 1px 2px white;
    border: 1px solid #ccc;
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea);
    padding-left: 10px;
    padding-right: 10px
}

.input-append a.add-on:active, .input-append span.add-on:active, .input-prepend a.add-on:active, .input-prepend span.add-on:active {
    background: #eee
}

.input-append ul.add-on, .input-prepend ul.add-on {
    list-style: none;
    position: absolute;
    z-index: 2;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    height: 100%;
    top: 1px;
    right: 2px
}

.input-append ul.add-on li, .input-prepend ul.add-on li {
    float: left;
    margin-left: 10px;
    line-height: 32px;
    height: 32px
}

.input-append ul.add-on li a, .input-append ul.add-on li button, .input-prepend ul.add-on li a, .input-prepend ul.add-on li button {
    vertical-align: middle
}

.input-append ul.add-on li a.btn, .input-append ul.add-on li button.btn, .input-prepend ul.add-on li a.btn, .input-prepend ul.add-on li button.btn {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px
}

.input-append ul.add-on li .btn, .input-prepend ul.add-on li .btn {
    padding: 0;
    line-height: 25px;
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px
}

.input-append input[type="text"], .input-append input[type="password"], .input-append input[type="email"], .input-append input[type="number"], .input-prepend input[type="text"], .input-prepend input[type="password"], .input-prepend input[type="email"], .input-prepend input[type="number"] {
    height: 100%;
    width: 100%;
    z-index: 1;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.box .input-append, .box .input-prepend {
    margin-bottom: 0
}

.input-append .add-on {
    right: 0
}

.input-append input[type="text"], .input-append input[type="password"], .input-append input[type="email"], .input-append input[type="number"] {
    padding-right: 35px
}

.input-prepend .add-on {
    left: 0
}

.input-prepend input[type="text"], .input-prepend input[type="password"], .input-prepend input[type="email"], .input-prepend input[type="number"] {
    padding-left: 45px
}

div.uploader {
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ccc;
    height: 28px;
    background: #fff
}

div.uploader span.filename {
    border-right: none;
    width: 120px;
    height: 24px;
    margin: 2px 0 2px 2px;
    line-height: 24px
}

div.uploader.hover span.action {
    background: #8dbfd8
}

div.uploader.active span.action {
    background: #4194bd
}

div.uploader span.action {
    height: 24px;
    line-height: 24px;
    float: right;
    width: 22px;
    text-align: center;
    height: 22px;
    margin-top: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background: #549fc4;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdhYjRkMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzU0OWZjNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #7ab4d1), color-stop(100%, #549fc4));
    background-image: -webkit-linear-gradient(to bottom, #7ab4d1, #549fc4);
    background-image: -moz-linear-gradient(to bottom, #7ab4d1, #549fc4);
    background-image: -o-linear-gradient(to bottom, #7ab4d1, #549fc4);
    background-image: linear-gradient(to bottom, #7ab4d1, #549fc4);
    color: white;
    text-shadow: none;
    font-size: 16px;
    line-height: 20px
}

div.selector {
    position: relative;
    padding-left: 5px;
    overflow: hidden;
    width: 100%;
    font-size: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background: whitesmoke;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y1ZjVmNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #f5f5f5));
    background-image: -webkit-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: -moz-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: -o-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: linear-gradient(to bottom, #ffffff, #f5f5f5);
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    border: 1px solid #ccc;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    color: #666
}

div.selector:after {
    content: "\f0dd";
    top: 2px;
    right: 2px;
    font-family: FontAwesome;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    position: absolute;
    width: 18px;
    height: 18px;
    pointer-events: none;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    color: #888
}

div.selector span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 25px 0 2px;
    cursor: pointer;
    color: #666;
    text-shadow: 0 1px 0 #fff;
    height: 26px;
    line-height: 26px;
    background: transparent
}

div.selector.disabled span {
    color: #bbb
}

div.selector select {
    position: absolute;
    opacity: 0;
    height: 25px;
    border: none;
    background: none;
    top: 0;
    left: 0;
    font-size: 12px;
    border: solid 1px #fff
}

textarea, input[type="text"]:not(.m-input__field), input[type="password"]:not(.m-input__field), input[type="datetime"]:not(.m-input__field), input[type="datetime-local"]:not(.m-input__field), input[type="date"]:not(.m-input__field), input[type="month"]:not(.m-input__field), input[type="time"]:not(.m-input__field), input[type="week"]:not(.m-input__field), input[type="number"]:not(.m-input__field), input[type="email"]:not(.m-input__field), input[type="url"]:not(.m-input__field), input[type="search"]:not(.m-input__field), input[type="tel"]:not(.m-input__field), input[type="color"]:not(.m-input__field), .uneditable-input {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    font-size: 12px;
    padding: 0 0 0 5px;
    border: 1px solid #d7d7d7;
    display: inline-block;
    background: #fdfdfd;
    height: 26px
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #68A6CE
}

textarea {
    height: auto
}

label {
    font-size: 12px;
    color: #5F5F5F
}

.search-dropdown {
    position: relative;
    background: #fbfbfb;
    border: 1px solid #cccccc;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.search-dropdown:after, .search-dropdown:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.search-dropdown:after {
    border-bottom-color: #fbfbfb;
    border-width: 7px;
    left: 50%;
    margin-left: -7px
}

.search-dropdown:before {
    border-bottom-color: #cccccc;
    border-width: 8px;
    left: 50%;
    margin-left: -8px
}

.search-dropdown {
    margin-top: 15px
}

.search-dropdown .box-section:first-child {
    border-top: none;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px
}

.input-with-submit {
    position: relative
}

.input-with-submit button {
    background: transparent;
    border: none;
    position: absolute;
    right: 2px;
    top: 7px
}

.input-with-submit i {
    color: #909090
}

.tagsinput {
    border: 1px solid #ddd;
    background: #fdfdfd;
    padding: 5px;
    width: 100%;
    overflow-y: auto
}

.tagsinput input {
    font-size: 11px;
    line-height: normal;
    width: auto !important
}

.tagsinput span.tag {
    display: block;
    float: left;
    padding: 0 8px;
    margin: 5px 5px 5px 5px;
    line-height: 23px;
    font-size: 11px;
    border: 1px solid #d2d2d2;
    background: #f2f2f2;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZjZmNmYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fcfcfc), color-stop(100%, #f2f2f2));
    background-image: -webkit-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: -moz-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: -o-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px
}

.tagsinput span.tag a {
    font-weight: 600;
    font-size: 11px;
    float: right
}

.tagsinput input {
    width: 60px;
    border: none;
    padding: 5px 8px 6px 8px;
    background: transparent;
    margin: 5px 5px 0 4px
}

.tagsinput div {
    display: block;
    float: left
}

.tags_clear {
    clear: both;
    width: 100%;
    height: 0
}

.not_valid {
    background: #FBD8DB !important;
    color: #90111A !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px
}

.secondary-sidebar .tagsinput {
    background: none;
    border: none;
    padding: 0 8px;
    margin: -3px 0
}

.secondary-sidebar .tagsinput span.tag {
    margin: 3px;
    color: #5f5f5f;
    background: #f2f2f2;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZjZmNmYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fcfcfc), color-stop(100%, #f2f2f2));
    background-image: -webkit-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: -moz-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: -o-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    border: 1px solid #D2D2D2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px
}

.secondary-sidebar .tagsinput span.tag a {
    color: #aaa
}

.navbar .brand {
    line-height: 27px;
    color: #bbb;
    text-shadow: 0 1px 0 black
}

.navbar-inverse .navbar-inner {
    border-bottom: 1px solid rgba(10, 13, 14, 0.8);
    background: #11161a;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiMjMyOSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzExMTYxYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #1b2329), color-stop(100%, #11161a));
    background-image: -webkit-linear-gradient(to bottom, #1b2329, #11161a);
    background-image: -moz-linear-gradient(to bottom, #1b2329, #11161a);
    background-image: -o-linear-gradient(to bottom, #1b2329, #11161a);
    background-image: linear-gradient(to bottom, #1b2329, #11161a);
    border-left: 0;
    border-right: 0
}

.navbar.navbar-top {
    margin-bottom: 0
}

.navbar.navbar-top .navbar-inner {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}


.navbar-search .search-query {
    height: 20px;
    padding: 2px 10px
}

.navbar-search .search-query.animated {
    width: 100px;
    -webkit-transition: width .3s;
    -moz-transition: width .3s;
    -o-transition: width .3s;
    transition: width .3s
}

.navbar-search .search-query.animated:focus, .navbar-search .search-query.animated .focused {
    width: 200px
}

.navbar-search i {
    position: absolute;
    right: 10px;
    top: 5px
}

.navbar-inverse .navbar-search .search-query, .navbar-inverse .navbar-search .search-query {
    background: rgba(35, 43, 48, 0.83)
}

.navbar-inverse .navbar-search .search-query:focus, .navbar-inverse .navbar-search .search-query.focused, .navbar-inverse .navbar-search .search-query:focus, .navbar-inverse .navbar-search .search-query.focused {
    padding: 2px 10px;
    border: 1px solid transparent
}

.navbar-inverse .nav {
    margin-top: 10px
}

.navbar-inverse .nav > li > a {
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    height: 20px;
    line-height: 20px;
    padding: 3px 10px;
    margin-right: 10px
}

.navbar-inverse .nav > li > a:hover {
    background: rgba(0, 0, 0, 0.8)
}

.navbar-inverse .nav > li.active > a {
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15)
}

.navbar .btn, .navbar .btn-group {
    margin-top: -4px
}

.menu-avatar {
    width: 32px;
    height: 32px;
    margin-right: 10px
}

.sidebar-background {
    position: fixed;
    z-index: -1;
    width: 200px;
    background: #eaeaea;
    top: 0;
    left: 0;
    height: 100%;
    border-right: 1px solid #ccc
}

.sidebar-background .primary-sidebar-background {
    width: 200px;
    background: #11161A;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute
}

.primary-sidebar {
    width: 200px;
    float: left;
    position: absolute
}

.primary-sidebar .nav {
    margin-bottom: 20px
}

.primary-sidebar .nav:after {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 1px;
    width: 100%;
    left: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iNTAlIiB4Mj0iMTAwJSIgeTI9IjUwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iInRyYW5zcGFyZW50IiIvPjxzdG9wIG9mZnNldD0iMzUlIiBzdG9wLWNvbG9yPSJyZ2JhKDMyLCA0MSwgNDcsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzIwMjkyZiIvPjxzdG9wIG9mZnNldD0iNjUlIiBzdG9wLWNvbG9yPSJyZ2JhKDMyLCA0MSwgNDcsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiJ0cmFuc3BhcmVudCIiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, transparent), color-stop(35%, rgba(32, 41, 47, 0.8)), color-stop(50%, #20292f), color-stop(65%, rgba(32, 41, 47, 0.8)), color-stop(100%, transparent));
    background: -webkit-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%);
    background: -moz-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%);
    background: -o-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%);
    background: linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%)
}

.primary-sidebar .nav > li {
    position: relative;
    border-bottom: 1px solid transparent
}

.primary-sidebar .nav > li a:after {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 1px;
    width: 100%;
    left: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iNTAlIiB4Mj0iMTAwJSIgeTI9IjUwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iInRyYW5zcGFyZW50IiIvPjxzdG9wIG9mZnNldD0iMzAlIiBzdG9wLWNvbG9yPSJyZ2JhKDEwLCAxMywgMTQsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzBhMGQwZSIvPjxzdG9wIG9mZnNldD0iNzAlIiBzdG9wLWNvbG9yPSJyZ2JhKDEwLCAxMywgMTQsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiJ0cmFuc3BhcmVudCIiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, transparent), color-stop(30%, rgba(10, 13, 14, 0.8)), color-stop(50%, #0a0d0e), color-stop(70%, rgba(10, 13, 14, 0.8)), color-stop(100%, transparent));
    background: -webkit-linear-gradient(to right, transparent 0, rgba(10, 13, 14, 0.8) 30%, #0a0d0e 50%, rgba(10, 13, 14, 0.8) 70%, transparent 100%);
    background: -moz-linear-gradient(to right, transparent 0, rgba(10, 13, 14, 0.8) 30%, #0a0d0e 50%, rgba(10, 13, 14, 0.8) 70%, transparent 100%);
    background: -o-linear-gradient(to right, transparent 0, rgba(10, 13, 14, 0.8) 30%, #0a0d0e 50%, rgba(10, 13, 14, 0.8) 70%, transparent 100%);
    background: linear-gradient(to right, transparent 0, rgba(10, 13, 14, 0.8) 30%, #0a0d0e 50%, rgba(10, 13, 14, 0.8) 70%, transparent 100%)
}

.primary-sidebar .nav > li a:before {
    content: "";
    position: absolute;
    top: 0;
    height: 1px;
    width: 100%;
    left: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iNTAlIiB4Mj0iMTAwJSIgeTI9IjUwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iInRyYW5zcGFyZW50IiIvPjxzdG9wIG9mZnNldD0iMzUlIiBzdG9wLWNvbG9yPSJyZ2JhKDMyLCA0MSwgNDcsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzIwMjkyZiIvPjxzdG9wIG9mZnNldD0iNjUlIiBzdG9wLWNvbG9yPSJyZ2JhKDMyLCA0MSwgNDcsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiJ0cmFuc3BhcmVudCIiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, transparent), color-stop(35%, rgba(32, 41, 47, 0.8)), color-stop(50%, #20292f), color-stop(65%, rgba(32, 41, 47, 0.8)), color-stop(100%, transparent));
    background: -webkit-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%);
    background: -moz-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%);
    background: -o-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%);
    background: linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 35%, #20292f 50%, rgba(32, 41, 47, 0.8) 65%, transparent 100%)
}

.primary-sidebar .nav > li .glow {
    position: absolute;
    height: 100%;
    width: 5px;
    -webkit-transition: box-shadow .3s;
    -moz-transition: box-shadow .3s;
    -o-transition: box-shadow .3s;
    transition: box-shadow .3s;
    background: #1F2B42;
    -webkit-box-shadow: 0 0 2px 1px #434d59 inset;
    -moz-box-shadow: 0 0 2px 1px #434d59 inset;
    box-shadow: 0 0 2px 1px #434d59 inset
}

.primary-sidebar .nav > li:hover .glow {
    background: #4291A6;
    -webkit-box-shadow: 0 0 2px 1px #7bc8d7 inset;
    -moz-box-shadow: 0 0 2px 1px #7bc8d7 inset;
    box-shadow: 0 0 2px 1px #7bc8d7 inset
}

.primary-sidebar .nav > li:hover > a {
    color: #939ea4
}

.primary-sidebar .nav > li.active .glow {
    background: #4291A6;
    -webkit-box-shadow: 0 0 2px 1px #7bc8d7 inset;
    -moz-box-shadow: 0 0 2px 1px #7bc8d7 inset;
    box-shadow: 0 0 2px 1px #7bc8d7 inset
}

.primary-sidebar .nav > li.active .glow:after {
    content: "";
    border-radius: 50%;
    position: absolute;
    top: 20px;
    width: 0;
    height: 0;
    left: 8px;
    -webkit-box-shadow: -10px 0 15px 23px rgba(122, 171, 216, 0.15);
    -moz-box-shadow: -10px 0 15px 23px rgba(122, 171, 216, 0.15);
    box-shadow: -10px 0 15px 23px rgba(122, 171, 216, 0.15);
    background: rgba(124, 190, 215, 0.2)
}

.primary-sidebar .nav > li.active > a {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    color: #939ea4
}

.primary-sidebar .nav > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    -webkit-transition: color .3s;
    -moz-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    color: #6B787F
}

.primary-sidebar .nav > li > a:hover, .primary-sidebar .nav > li > a:focus {
    background: #11161A
}

.primary-sidebar .nav > li > a span {
    font-size: 12px;
    vertical-align: middle
}

.primary-sidebar .nav > li > a i {
    vertical-align: middle
}

.primary-sidebar .nav > li > a i + span {
    padding-left: 10px
}

.user-avatar {
    width: 100%;
    position: relative
}

.user-avatar .dropdown-toggle span {
    font-weight: 600
}

.user-avatar a {
    display: inline-block
}

.user-avatar a > span {
    position: relative
}

.user-avatar a img {
    -webkit-box-shadow: 0 1px 3px #1e1e1e;
    -moz-box-shadow: 0 1px 3px #1e1e1e;
    box-shadow: 0 1px 3px #1e1e1e;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px
}

.user-avatar a .badge {
    z-index: 2;
    position: absolute;
    left: -6px;
    top: -10px
}

.user-avatar .dropdown-menu {
    margin-top: 4px
}

.status-error {
    color: #d13b3b
}

.status-success {
    color: #629b3e
}

.status-pending {
    color: #888
}

.status-warning {
    color: #E36D2D
}

.status-info {
    color: #4C9ECD
}

hr.divider, li.divider {
    border: 0;
    height: 1px;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iNTAlIiB4Mj0iMTAwJSIgeTI9IjUwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0icmdiYSgyMDUsIDIwNSwgMjA1LCAwKSIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjY2RjZGNkIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJyZ2JhKDIwNSwgMjA1LCAyMDUsIDApIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, rgba(205, 205, 205, 0)), color-stop(50%, #cdcdcd), color-stop(100%, rgba(205, 205, 205, 0)));
    background: -webkit-linear-gradient(to right, rgba(205, 205, 205, 0), #cdcdcd, rgba(205, 205, 205, 0));
    background: -moz-linear-gradient(to right, rgba(205, 205, 205, 0), #cdcdcd, rgba(205, 205, 205, 0));
    background: -o-linear-gradient(to right, rgba(205, 205, 205, 0), #cdcdcd, rgba(205, 205, 205, 0));
    background: linear-gradient(to right, rgba(205, 205, 205, 0), #cdcdcd, rgba(205, 205, 205, 0));
    position: relative
}

hr.divider:after, li.divider:after {
    content: '';
    height: 1px;
    top: 1px;
    left: 0;
    width: 100%;
    position: absolute;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iNTAlIiB4Mj0iMTAwJSIgeTI9IjUwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0icmdiYSgyNTAsIDI1MCwgMjUwLCAwKSIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjZmFmYWZhIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJyZ2JhKDI1MCwgMjUwLCAyNTAsIDApIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, rgba(250, 250, 250, 0)), color-stop(50%, #fafafa), color-stop(100%, rgba(250, 250, 250, 0)));
    background: -webkit-linear-gradient(to right, rgba(250, 250, 250, 0), #fafafa, rgba(250, 250, 250, 0));
    background: -moz-linear-gradient(to right, rgba(250, 250, 250, 0), #fafafa, rgba(250, 250, 250, 0));
    background: -o-linear-gradient(to right, rgba(250, 250, 250, 0), #fafafa, rgba(250, 250, 250, 0));
    background: linear-gradient(to right, rgba(250, 250, 250, 0), #fafafa, rgba(250, 250, 250, 0))
}

hr.divider + .padded, hr.divider + .vpadded, li.divider + .padded, li.divider + .vpadded {
    padding-top: 0
}

.primary-sidebar .divider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #0a0d0e;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iNTAlIiB4Mj0iMTAwJSIgeTI9IjUwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iInRyYW5zcGFyZW50IiIvPjxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSJyZ2JhKDMyLCA0MSwgNDcsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzIwMjkyZiIvPjxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSJyZ2JhKDMyLCA0MSwgNDcsIDAuOCkiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiJ0cmFuc3BhcmVudCIiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, transparent), color-stop(25%, rgba(32, 41, 47, 0.8)), color-stop(50%, #20292f), color-stop(75%, rgba(32, 41, 47, 0.8)), color-stop(100%, transparent));
    background: -webkit-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 25%, #20292f 50%, rgba(32, 41, 47, 0.8) 75%, transparent 100%);
    background: -moz-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 25%, #20292f 50%, rgba(32, 41, 47, 0.8) 75%, transparent 100%);
    background: -o-linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 25%, #20292f 50%, rgba(32, 41, 47, 0.8) 75%, transparent 100%);
    background: linear-gradient(to right, transparent 0, rgba(32, 41, 47, 0.8) 25%, #20292f 50%, rgba(32, 41, 47, 0.8) 75%, transparent 100%)
}

.primary-sidebar .divider:after {
    content: none
}

.area-top {
    background: #ececec;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZhZmFmYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fafafa), color-stop(100%, #ececec));
    background-image: -webkit-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -moz-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -o-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: linear-gradient(to bottom, #fafafa, #ececec);
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.07);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.07);
    border-bottom: 1px solid #ccc;
    position: relative;
    width: 100%;
    padding: 10px 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 15px
}

.area-top .header {
    font-size: 14px
}

.area-top .header i {
    float: left;
    font-size: 24px;
    margin-top: 4px;
    margin-right: 10px
}

.area-top .header h3, .area-top .header h5 {
    margin: 0;
    font-weight: 300
}

.area-top .header h3, .area-top .header i {
    color: #888
}

.area-top .header h3 {
    line-height: 35px
}

.area-top .header h5 {
    color: #aaa
}

.area-top .header > * {
    vertical-align: middle;
    text-shadow: 0 1px #fff
}

#breadcrumbs {
    display: inline-block;
    padding-right: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 0 1px #cccccc inset, 0 1px 0 rgba(255, 255, 255, 0.6), 0 1px 0 1px rgba(255, 255, 255, 0.6) inset;
    -moz-box-shadow: 0 0 0 1px #cccccc inset, 0 1px 0 rgba(255, 255, 255, 0.6), 0 1px 0 1px rgba(255, 255, 255, 0.6) inset;
    box-shadow: 0 0 0 1px #cccccc inset, 0 1px 0 rgba(255, 255, 255, 0.6), 0 1px 0 1px rgba(255, 255, 255, 0.6) inset;
    background: #ececec;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZhZmFmYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fafafa), color-stop(100%, #ececec));
    background-image: -webkit-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -moz-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -o-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: linear-gradient(to bottom, #fafafa, #ececec);
    width: 100%
}

#breadcrumbs .breadcrumb-button .breadcrumb-label {
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px
}

#breadcrumbs .breadcrumb-button {
    text-shadow: 0 1px 1px #fafafa, 0 -1px 1px #ececec;
    background: #ececec;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZhZmFmYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fafafa), color-stop(100%, #ececec));
    background-image: -webkit-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -moz-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -o-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: linear-gradient(to bottom, #fafafa, #ececec);
    display: inline-block;
    cursor: pointer;
    margin-right: -20px
}

#breadcrumbs .breadcrumb-button.yellow {
    text-shadow: 0 1px 1px #ffd972, 0 -1px 1px #ffba49;
    background: #ffba49;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZDk3MiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmYmE0OSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #ffd972), color-stop(100%, #ffba49));
    background-image: -webkit-linear-gradient(to bottom, #ffd972, #ffba49);
    background-image: -moz-linear-gradient(to bottom, #ffd972, #ffba49);
    background-image: -o-linear-gradient(to bottom, #ffd972, #ffba49);
    background-image: linear-gradient(to bottom, #ffd972, #ffba49)
}

#breadcrumbs .breadcrumb-button.yellow .breadcrumb-label {
    color: #5c481d
}

#breadcrumbs .breadcrumb-button.yellow:hover {
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjEwMCUiIHgyPSI1MCUiIHkyPSIwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmYmE0OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZDk3MiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -webkit-gradient(linear, 50% 100%, 50% 0, color-stop(0%, #ffba49), color-stop(100%, #ffd972));
    background: -webkit-linear-gradient(to top, #ffba49, #ffd972);
    background: -moz-linear-gradient(to top, #ffba49, #ffd972);
    background: -o-linear-gradient(to top, #ffba49, #ffd972);
    background: linear-gradient(to top, #ffba49, #ffd972)
}

#breadcrumbs .breadcrumb-button.yellow:hover .breadcrumb-arrow span {
    background-color: #ffba49;
    background: -moz-linear-gradient(135deg, #ffba49, #ffd972);
    background: -ms-linear-gradient(135deg, #ffd972, #ffba49);
    background: -o-linear-gradient(135deg, #ffd972, #ffba49);
    background: -webkit-gradient(linear, left top, right bottom, from(#ffd972), to(#ffba49))
}

#breadcrumbs .breadcrumb-button.yellow .breadcrumb-arrow span {
    background-color: #ffd972;
    background: -moz-linear-gradient(135deg, #ffba49, #ffd972);
    background: -ms-linear-gradient(135deg, #ffba49, #ffd972);
    background: -o-linear-gradient(135deg, #ffba49, #ffd972);
    background: -webkit-gradient(linear, right bottom, left top, from(#ffba49), to(#ffd972))
}

#breadcrumbs .breadcrumb-button.yellow .breadcrumb-label {
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom: 1px solid #C99337;
    border-left: 1px solid #D79D3B;
    border-top: 1px solid #F5B244
}

#breadcrumbs .breadcrumb-button.yellow .breadcrumb-arrow span {
    box-shadow: 1px -1px 0 #E29B1E, 1px -1px 1px #F09900
}

#breadcrumbs .breadcrumb-button.blue {
    text-shadow: 0 1px 1px #92cff5, 0 -1px 1px #68a6ce;
    background: #68a6ce;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzkyY2ZmNSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YTZjZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #92cff5), color-stop(100%, #68a6ce));
    background-image: -webkit-linear-gradient(to bottom, #92cff5, #68a6ce);
    background-image: -moz-linear-gradient(to bottom, #92cff5, #68a6ce);
    background-image: -o-linear-gradient(to bottom, #92cff5, #68a6ce);
    background-image: linear-gradient(to bottom, #92cff5, #68a6ce)
}

#breadcrumbs .breadcrumb-button.blue .breadcrumb-label {
    color: white
}

#breadcrumbs .breadcrumb-button.blue:hover {
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjEwMCUiIHgyPSI1MCUiIHkyPSIwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY4YTZjZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzkyY2ZmNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -webkit-gradient(linear, 50% 100%, 50% 0, color-stop(0%, #68a6ce), color-stop(100%, #92cff5));
    background: -webkit-linear-gradient(to top, #68a6ce, #92cff5);
    background: -moz-linear-gradient(to top, #68a6ce, #92cff5);
    background: -o-linear-gradient(to top, #68a6ce, #92cff5);
    background: linear-gradient(to top, #68a6ce, #92cff5)
}

#breadcrumbs .breadcrumb-button.blue:hover .breadcrumb-arrow span {
    background-color: #68a6ce;
    background: -moz-linear-gradient(135deg, #68a6ce, #92cff5);
    background: -ms-linear-gradient(135deg, #92cff5, #68a6ce);
    background: -o-linear-gradient(135deg, #92cff5, #68a6ce);
    background: -webkit-gradient(linear, left top, right bottom, from(#92cff5), to(#68a6ce))
}

#breadcrumbs .breadcrumb-button.blue .breadcrumb-arrow span {
    background-color: #92cff5;
    background: -moz-linear-gradient(135deg, #68a6ce, #92cff5);
    background: -ms-linear-gradient(135deg, #68a6ce, #92cff5);
    background: -o-linear-gradient(135deg, #68a6ce, #92cff5);
    background: -webkit-gradient(linear, right bottom, left top, from(#68a6ce), to(#92cff5))
}

#breadcrumbs .breadcrumb-button.blue .breadcrumb-label {
    font-weight: 600;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom: 1px solid #3e89b9;
    border-left: 1px solid #60a1ca;
    border-top: 1px solid #73acd0
}

#breadcrumbs .breadcrumb-button.blue .breadcrumb-label:hover {
    color: white
}

#breadcrumbs .breadcrumb-button.blue .breadcrumb-label:hover .breadcrumb-label {
    color: white
}

#breadcrumbs .breadcrumb-button.blue .breadcrumb-arrow span {
    box-shadow: 1px -1px 0 #7599AF, 1px -1px 1px #45A8E7
}

#breadcrumbs .breadcrumb-button .breadcrumb-label {
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc
}

#breadcrumbs .breadcrumb-button .breadcrumb-arrow span {
    box-shadow: 1px -1px 0 #ccc, 1px -1px 1px #ccc
}

#breadcrumbs .breadcrumb-button .breadcrumb-label {
    color: #888888
}

#breadcrumbs .breadcrumb-button:hover {
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjEwMCUiIHgyPSI1MCUiIHkyPSIwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZhZmFmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -webkit-gradient(linear, 50% 100%, 50% 0, color-stop(0%, #ececec), color-stop(100%, #fafafa));
    background: -webkit-linear-gradient(to top, #ececec, #fafafa);
    background: -moz-linear-gradient(to top, #ececec, #fafafa);
    background: -o-linear-gradient(to top, #ececec, #fafafa);
    background: linear-gradient(to top, #ececec, #fafafa)
}

#breadcrumbs .breadcrumb-button:hover .breadcrumb-arrow span {
    background-color: #ececec;
    background: -moz-linear-gradient(135deg, #ececec, #fafafa);
    background: -ms-linear-gradient(135deg, #fafafa, #ececec);
    background: -o-linear-gradient(135deg, #fafafa, #ececec);
    background: -webkit-gradient(linear, left top, right bottom, from(#fafafa), to(#ececec))
}

#breadcrumbs .breadcrumb-button .breadcrumb-arrow span {
    background-color: #fafafa;
    background: -moz-linear-gradient(135deg, #ececec, #fafafa);
    background: -ms-linear-gradient(135deg, #ececec, #fafafa);
    background: -o-linear-gradient(135deg, #ececec, #fafafa);
    background: -webkit-gradient(linear, right bottom, left top, from(#ececec), to(#fafafa))
}

#breadcrumbs .breadcrumb-button:first-child {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    -ms-border-radius: 3px 0 0 3px;
    -o-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px
}

#breadcrumbs .breadcrumb-button:first-child .breadcrumb-label {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

#breadcrumbs .breadcrumb-button:last-child {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    -o-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0
}

#breadcrumbs .breadcrumb-button:first-child .breadcrumb-label {
    padding-left: 15px
}

#breadcrumbs .breadcrumb-button:last-child .breadcrumb-label {
    padding-right: 15px
}

#breadcrumbs .breadcrumb-button:last-child .breadcrumb-arrow {
    display: none
}

#breadcrumbs .breadcrumb-button .breadcrumb-label {
    padding: 8px;
    -moz-user-select: none;
    -webkit-user-select: none;
    display: inline-block;
    padding-left: 25px
}

#breadcrumbs .breadcrumb-button .breadcrumb-arrow {
    width: 17px;
    height: 33px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    margin-left: -5px
}

#breadcrumbs .breadcrumb-button .breadcrumb-arrow span {
    border-radius: 4px;
    width: 26px;
    height: 30px;
    display: inline-block;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: -16px;
    margin-top: 3px
}

.breadcrumb-line {
    color: #666;
    height: 28px;
    -webkit-box-shadow: 0 1px 0 white, 0 1px 0 white inset;
    -moz-box-shadow: 0 1px 0 white, 0 1px 0 white inset;
    box-shadow: 0 1px 0 white, 0 1px 0 white inset;
    background: #e3e3e3;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2UzZTNlMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f4f4f4), color-stop(100%, #e3e3e3));
    background-image: -webkit-linear-gradient(to bottom, #f4f4f4, #e3e3e3);
    background-image: -moz-linear-gradient(to bottom, #f4f4f4, #e3e3e3);
    background-image: -o-linear-gradient(to bottom, #f4f4f4, #e3e3e3);
    background-image: linear-gradient(to bottom, #f4f4f4, #e3e3e3);
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    font-size: 11px
}

.breadcrumb-line .breadcrumbs {
    margin: 0;
    padding: 0;
    list-style: none
}

.breadcrumb-line .breadcrumbs li {
    float: left;
    line-height: 28px;
    margin-left: 10px
}

.breadcrumb-line .breadcrumbs li:after {
    content: ">";
    margin-left: 10px
}

.breadcrumb-line .breadcrumbs li:last-child:after {
    content: ""
}

.breadcrumb-line .breadcrumbs li:first-child {
    margin-left: 20px
}

.breadcrumb-line .breadcrumbs li.current {
    color: #888
}

.breadcrumb-line .nav.pull-right {
    margin-right: 20px;
    border-right: 1px solid #ccc
}

.breadcrumb-line .nav.pull-right .badge {
    margin-left: 4px
}

.breadcrumb-line .nav .dropdown-toggle .caret {
    margin-top: 12px
}

.breadcrumb-line .nav > li > a {
    text-shadow: none;
    line-height: 28px;
    height: 28px;
    margin: 0;
    padding: 0 15px;
    border-left: 1px solid #ccc;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    color: #666
}

.breadcrumb-line .nav > li > a strong {
    color: #B35D5D;
    margin-left: 4px
}

.triangle-button {
    position: absolute;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    top: 0;
    right: 0;
    color: #fff;
    overflow: hidden;
    height: 32px;
    width: 32px;
    text-shadow: none
}

.triangle-button i, .triangle-button .inner {
    z-index: 1;
    position: absolute;
    right: 3px;
    top: 2px
}

.triangle-button:before {
    content: '';
    display: inline-block;
    border-right: 32px solid;
    border-bottom: 32px solid transparent;
    position: absolute;
    top: 0;
    right: 0
}

.triangle-button:hover, .triangle-button:active {
    color: #fff
}

.triangle-button.red:before {
    border-right-color: #aa433a
}

.triangle-button.red:hover:before {
    border-right-color: #b5473e
}

.triangle-button.red:active:before {
    border-right-color: #9f3f36
}

.triangle-button.green:before {
    border-right-color: #8fae53
}

.triangle-button.green:hover:before {
    border-right-color: #96b35d
}

.triangle-button.green:active:before {
    border-right-color: #87a44d
}

.triangle-button.blue:before {
    border-right-color: #7fb3d4
}

.triangle-button.blue:hover:before {
    border-right-color: #8abad8
}

.triangle-button.blue:active:before {
    border-right-color: #74acd0
}

.triangle-button.orange:before {
    border-right-color: #cf6e20
}

.triangle-button.orange:hover:before {
    border-right-color: #dc7522
}

.triangle-button.orange:active:before {
    border-right-color: #c2671e
}

.jqstooltip {
    z-index: 10001;
    border: 1px solid #333333 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.7);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0icmdiYSgwLCAwLCAwLCAwLjUpIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJyZ2JhKDAsIDAsIDAsIDAuNykiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(100%, rgba(0, 0, 0, 0.7)));
    background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    display: inline-block
}

.btn {
    color: #fff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    font-weight: 600;
    text-shadow: 0 -1px #6f6f6f
}

.btn:hover, .btn:active, .btn:focus {
    color: #fff
}

.btn.btn-full {
    display: block
}

.btn.btn-default {
    color: #686868;
    text-shadow: none
}

.btn.btn-default:hover, .btn.btn-default:active {
    color: #505050
}

.btn.btn-default .caret {
    border-top: 4px solid black
}

.btn .caret {
    border-top: 4px solid white
}

.btn.btn-large {
    font-size: 12px
}

.btn.btn-default {
    border: 1px solid #d4d4d4;
    -webkit-box-shadow: inset 0 1px 2px white;
    -moz-box-shadow: inset 0 1px 2px white;
    box-shadow: inset 0 1px 2px white;
    background: #e9e9e9;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2U5ZTllOSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #e9e9e9));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #e9e9e9);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #e9e9e9);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #e9e9e9);
    background-image: linear-gradient(to bottom, #fdfdfd, #e9e9e9)
}

.btn.btn-default:hover {
    background: #e6e6e6;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2U2ZTZlNiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #e6e6e6));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #e6e6e6);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #e6e6e6);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #e6e6e6);
    background-image: linear-gradient(to bottom, #fdfdfd, #e6e6e6);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-default:active {
    background: #f3f3f3;
    border-color: #cfcfcf;
    -webkit-box-shadow: 0 0 5px #f3f3f3 inset;
    -moz-box-shadow: 0 0 5px #f3f3f3 inset;
    box-shadow: 0 0 5px #f3f3f3 inset
}

.btn.btn-red {
    border: 1px solid #a54747;
    -webkit-box-shadow: inset 0 1px 2px #cd8b8b;
    -moz-box-shadow: inset 0 1px 2px #cd8b8b;
    box-shadow: inset 0 1px 2px #cd8b8b;
    background: #b95d5d;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2M1Nzk3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2I5NWQ1ZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #c57979), color-stop(100%, #b95d5d));
    background-image: -webkit-linear-gradient(to bottom, #c57979, #b95d5d);
    background-image: -moz-linear-gradient(to bottom, #c57979, #b95d5d);
    background-image: -o-linear-gradient(to bottom, #c57979, #b95d5d);
    background-image: linear-gradient(to bottom, #c57979, #b95d5d)
}

.btn.btn-red:hover {
    background: #b75959;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2M1Nzk3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2I3NTk1OSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #c57979), color-stop(100%, #b75959));
    background-image: -webkit-linear-gradient(to bottom, #c57979, #b75959);
    background-image: -moz-linear-gradient(to bottom, #c57979, #b75959);
    background-image: -o-linear-gradient(to bottom, #c57979, #b75959);
    background-image: linear-gradient(to bottom, #c57979, #b75959);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-red:active {
    background: #bf6b6b;
    border-color: #9e4444;
    -webkit-box-shadow: 0 0 5px #bf6b6b inset;
    -moz-box-shadow: 0 0 5px #bf6b6b inset;
    box-shadow: 0 0 5px #bf6b6b inset
}

.btn.btn-green {
    border: 1px solid #6d963b;
    -webkit-box-shadow: inset 0 1px 2px #a2c873;
    -moz-box-shadow: inset 0 1px 2px #a2c873;
    box-shadow: inset 0 1px 2px #a2c873;
    background: #82b346;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzk2YzE2MSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzgyYjM0NiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #96c161), color-stop(100%, #82b346));
    background-image: -webkit-linear-gradient(to bottom, #96c161, #82b346);
    background-image: -moz-linear-gradient(to bottom, #96c161, #82b346);
    background-image: -o-linear-gradient(to bottom, #96c161, #82b346);
    background-image: linear-gradient(to bottom, #96c161, #82b346)
}

.btn.btn-green:hover {
    background: #80af45;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzk2YzE2MSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzgwYWY0NSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #96c161), color-stop(100%, #80af45));
    background-image: -webkit-linear-gradient(to bottom, #96c161, #80af45);
    background-image: -moz-linear-gradient(to bottom, #96c161, #80af45);
    background-image: -o-linear-gradient(to bottom, #96c161, #80af45);
    background-image: linear-gradient(to bottom, #96c161, #80af45);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-green:active {
    background: #8cbb52;
    border-color: #688e38;
    -webkit-box-shadow: 0 0 5px #8cbb52 inset;
    -moz-box-shadow: 0 0 5px #8cbb52 inset;
    box-shadow: 0 0 5px #8cbb52 inset
}

.btn.btn-blue {
    border: 1px solid #337ca1;
    -webkit-box-shadow: inset 0 1px 2px #6eb0d1;
    -moz-box-shadow: inset 0 1px 2px #6eb0d1;
    box-shadow: inset 0 1px 2px #6eb0d1;
    background: #3d94c0;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzViYTVjYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNkOTRjMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #5ba5cb), color-stop(100%, #3d94c0));
    background-image: -webkit-linear-gradient(to bottom, #5ba5cb, #3d94c0);
    background-image: -moz-linear-gradient(to bottom, #5ba5cb, #3d94c0);
    background-image: -o-linear-gradient(to bottom, #5ba5cb, #3d94c0);
    background-image: linear-gradient(to bottom, #5ba5cb, #3d94c0)
}

.btn.btn-blue:hover {
    background: #3c91bc;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzViYTVjYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNjOTFiYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #5ba5cb), color-stop(100%, #3c91bc));
    background-image: -webkit-linear-gradient(to bottom, #5ba5cb, #3c91bc);
    background-image: -moz-linear-gradient(to bottom, #5ba5cb, #3c91bc);
    background-image: -o-linear-gradient(to bottom, #5ba5cb, #3c91bc);
    background-image: linear-gradient(to bottom, #5ba5cb, #3c91bc);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-blue:active {
    background: #4c9dc6;
    border-color: #31769a;
    -webkit-box-shadow: 0 0 5px #4c9dc6 inset;
    -moz-box-shadow: 0 0 5px #4c9dc6 inset;
    box-shadow: 0 0 5px #4c9dc6 inset
}

.btn.btn-gray {
    border: 1px solid #626e7f;
    -webkit-box-shadow: inset 0 1px 2px #9aa4b1;
    -moz-box-shadow: inset 0 1px 2px #9aa4b1;
    box-shadow: inset 0 1px 2px #9aa4b1;
    background: #758294;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhjOTdhNiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzc1ODI5NCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #8c97a6), color-stop(100%, #758294));
    background-image: -webkit-linear-gradient(to bottom, #8c97a6, #758294);
    background-image: -moz-linear-gradient(to bottom, #8c97a6, #758294);
    background-image: -o-linear-gradient(to bottom, #8c97a6, #758294);
    background-image: linear-gradient(to bottom, #8c97a6, #758294)
}

.btn.btn-gray:hover {
    background: #728092;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhjOTdhNiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzcyODA5MiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #8c97a6), color-stop(100%, #728092));
    background-image: -webkit-linear-gradient(to bottom, #8c97a6, #728092);
    background-image: -moz-linear-gradient(to bottom, #8c97a6, #728092);
    background-image: -o-linear-gradient(to bottom, #8c97a6, #728092);
    background-image: linear-gradient(to bottom, #8c97a6, #728092);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-gray:active {
    background: #818d9d;
    border-color: #5d6979;
    -webkit-box-shadow: 0 0 5px #818d9d inset;
    -moz-box-shadow: 0 0 5px #818d9d inset;
    box-shadow: 0 0 5px #818d9d inset
}

.btn.btn-black {
    border: 1px solid #5a5a5a;
    -webkit-box-shadow: inset 0 1px 2px #909090;
    -moz-box-shadow: inset 0 1px 2px #909090;
    box-shadow: inset 0 1px 2px #909090;
    background: #6f6f6f;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgzODM4MyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzZmNmY2ZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #838383), color-stop(100%, #6f6f6f));
    background-image: -webkit-linear-gradient(to bottom, #838383, #6f6f6f);
    background-image: -moz-linear-gradient(to bottom, #838383, #6f6f6f);
    background-image: -o-linear-gradient(to bottom, #838383, #6f6f6f);
    background-image: linear-gradient(to bottom, #838383, #6f6f6f)
}

.btn.btn-black:hover {
    background: #6c6c6c;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgzODM4MyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzZjNmM2YyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #838383), color-stop(100%, #6c6c6c));
    background-image: -webkit-linear-gradient(to bottom, #838383, #6c6c6c);
    background-image: -moz-linear-gradient(to bottom, #838383, #6c6c6c);
    background-image: -o-linear-gradient(to bottom, #838383, #6c6c6c);
    background-image: linear-gradient(to bottom, #838383, #6c6c6c);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-black:active {
    background: #797979;
    border-color: #555555;
    -webkit-box-shadow: 0 0 5px #797979 inset;
    -moz-box-shadow: 0 0 5px #797979 inset;
    box-shadow: 0 0 5px #797979 inset
}

.btn.btn-lightblue {
    border: 1px solid #3692bb;
    -webkit-box-shadow: inset 0 1px 2px #81bfdb;
    -moz-box-shadow: inset 0 1px 2px #81bfdb;
    box-shadow: inset 0 1px 2px #81bfdb;
    background: #4da5cc;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZkYjVkNSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRkYTVjYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #6db5d5), color-stop(100%, #4da5cc));
    background-image: -webkit-linear-gradient(to bottom, #6db5d5, #4da5cc);
    background-image: -moz-linear-gradient(to bottom, #6db5d5, #4da5cc);
    background-image: -o-linear-gradient(to bottom, #6db5d5, #4da5cc);
    background-image: linear-gradient(to bottom, #6db5d5, #4da5cc)
}

.btn.btn-lightblue:hover {
    background: #49a3cb;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZkYjVkNSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQ5YTNjYiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #6db5d5), color-stop(100%, #49a3cb));
    background-image: -webkit-linear-gradient(to bottom, #6db5d5, #49a3cb);
    background-image: -moz-linear-gradient(to bottom, #6db5d5, #49a3cb);
    background-image: -o-linear-gradient(to bottom, #6db5d5, #49a3cb);
    background-image: linear-gradient(to bottom, #6db5d5, #49a3cb);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-lightblue:active {
    background: #5dadd0;
    border-color: #338cb3;
    -webkit-box-shadow: 0 0 5px #5dadd0 inset;
    -moz-box-shadow: 0 0 5px #5dadd0 inset;
    box-shadow: 0 0 5px #5dadd0 inset
}

.btn.btn-gold {
    border: 1px solid #a87a27;
    -webkit-box-shadow: inset 0 1px 2px #daaf61;
    -moz-box-shadow: inset 0 1px 2px #daaf61;
    box-shadow: inset 0 1px 2px #daaf61;
    background: #c9922f;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Q1YTQ0YyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2M5OTIyZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #d5a44c), color-stop(100%, #c9922f));
    background-image: -webkit-linear-gradient(to bottom, #d5a44c, #c9922f);
    background-image: -moz-linear-gradient(to bottom, #d5a44c, #c9922f);
    background-image: -o-linear-gradient(to bottom, #d5a44c, #c9922f);
    background-image: linear-gradient(to bottom, #d5a44c, #c9922f)
}

.btn.btn-gold:hover {
    background: #c58f2e;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Q1YTQ0YyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2M1OGYyZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #d5a44c), color-stop(100%, #c58f2e));
    background-image: -webkit-linear-gradient(to bottom, #d5a44c, #c58f2e);
    background-image: -moz-linear-gradient(to bottom, #d5a44c, #c58f2e);
    background-image: -o-linear-gradient(to bottom, #d5a44c, #c58f2e);
    background-image: linear-gradient(to bottom, #d5a44c, #c58f2e);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-gold:active {
    background: #d19c3b;
    border-color: #a07425;
    -webkit-box-shadow: 0 0 5px #d19c3b inset;
    -moz-box-shadow: 0 0 5px #d19c3b inset;
    box-shadow: 0 0 5px #d19c3b inset
}

.btn.btn-sea {
    border: 1px solid #39534f;
    -webkit-box-shadow: inset 0 1px 2px #64928d;
    -moz-box-shadow: inset 0 1px 2px #64928d;
    box-shadow: inset 0 1px 2px #64928d;
    background: #496b67;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVhODM3ZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQ5NmI2NyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #5a837e), color-stop(100%, #496b67));
    background-image: -webkit-linear-gradient(to bottom, #5a837e, #496b67);
    background-image: -moz-linear-gradient(to bottom, #5a837e, #496b67);
    background-image: -o-linear-gradient(to bottom, #5a837e, #496b67);
    background-image: linear-gradient(to bottom, #5a837e, #496b67)
}

.btn.btn-sea:hover {
    background: #476864;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVhODM3ZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQ3Njg2NCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #5a837e), color-stop(100%, #476864));
    background-image: -webkit-linear-gradient(to bottom, #5a837e, #476864);
    background-image: -moz-linear-gradient(to bottom, #5a837e, #476864);
    background-image: -o-linear-gradient(to bottom, #5a837e, #476864);
    background-image: linear-gradient(to bottom, #5a837e, #476864);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-sea:active {
    background: #527772;
    border-color: #354d4a;
    -webkit-box-shadow: 0 0 5px #527772 inset;
    -moz-box-shadow: 0 0 5px #527772 inset;
    box-shadow: 0 0 5px #527772 inset
}

.btn.btn-brown {
    border: 1px solid #856733;
    -webkit-box-shadow: inset 0 1px 2px #c29e61;
    -moz-box-shadow: inset 0 1px 2px #c29e61;
    box-shadow: inset 0 1px 2px #c29e61;
    background: #a27e3f;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JiOTM0ZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2EyN2UzZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #bb934f), color-stop(100%, #a27e3f));
    background-image: -webkit-linear-gradient(to bottom, #bb934f, #a27e3f);
    background-image: -moz-linear-gradient(to bottom, #bb934f, #a27e3f);
    background-image: -o-linear-gradient(to bottom, #bb934f, #a27e3f);
    background-image: linear-gradient(to bottom, #bb934f, #a27e3f)
}

.btn.btn-brown:hover {
    background: #9f7b3d;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JiOTM0ZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzlmN2IzZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #bb934f), color-stop(100%, #9f7b3d));
    background-image: -webkit-linear-gradient(to bottom, #bb934f, #9f7b3d);
    background-image: -moz-linear-gradient(to bottom, #bb934f, #9f7b3d);
    background-image: -o-linear-gradient(to bottom, #bb934f, #9f7b3d);
    background-image: linear-gradient(to bottom, #bb934f, #9f7b3d);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-brown:active {
    background: #b18944;
    border-color: #7e6131;
    -webkit-box-shadow: 0 0 5px #b18944 inset;
    -moz-box-shadow: 0 0 5px #b18944 inset;
    box-shadow: 0 0 5px #b18944 inset
}

.btn.btn-twitter {
    border: 1px solid #2189ff;
    -webkit-box-shadow: inset 0 1px 2px #8dc2ff;
    -moz-box-shadow: inset 0 1px 2px #8dc2ff;
    box-shadow: inset 0 1px 2px #8dc2ff;
    background: #4a9eff;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzczYjRmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRhOWVmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #73b4ff), color-stop(100%, #4a9eff));
    background-image: -webkit-linear-gradient(to bottom, #73b4ff, #4a9eff);
    background-image: -moz-linear-gradient(to bottom, #73b4ff, #4a9eff);
    background-image: -o-linear-gradient(to bottom, #73b4ff, #4a9eff);
    background-image: linear-gradient(to bottom, #73b4ff, #4a9eff)
}

.btn.btn-twitter:hover {
    background: #459cff;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzczYjRmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQ1OWNmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #73b4ff), color-stop(100%, #459cff));
    background-image: -webkit-linear-gradient(to bottom, #73b4ff, #459cff);
    background-image: -moz-linear-gradient(to bottom, #73b4ff, #459cff);
    background-image: -o-linear-gradient(to bottom, #73b4ff, #459cff);
    background-image: linear-gradient(to bottom, #73b4ff, #459cff);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-twitter:active {
    background: #5fa9ff;
    border-color: #1783ff;
    -webkit-box-shadow: 0 0 5px #5fa9ff inset;
    -moz-box-shadow: 0 0 5px #5fa9ff inset;
    box-shadow: 0 0 5px #5fa9ff inset
}

.btn.btn-facebook {
    border: 1px solid #2d4479;
    -webkit-box-shadow: inset 0 1px 2px #5272bf;
    -moz-box-shadow: inset 0 1px 2px #5272bf;
    box-shadow: inset 0 1px 2px #5272bf;
    background: #385496;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQzNjViNCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzM4NTQ5NiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #4365b4), color-stop(100%, #385496));
    background-image: -webkit-linear-gradient(to bottom, #4365b4, #385496);
    background-image: -moz-linear-gradient(to bottom, #4365b4, #385496);
    background-image: -o-linear-gradient(to bottom, #4365b4, #385496);
    background-image: linear-gradient(to bottom, #4365b4, #385496)
}

.btn.btn-facebook:hover {
    background: #365293;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQzNjViNCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzM2NTI5MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #4365b4), color-stop(100%, #365293));
    background-image: -webkit-linear-gradient(to bottom, #4365b4, #365293);
    background-image: -moz-linear-gradient(to bottom, #4365b4, #365293);
    background-image: -o-linear-gradient(to bottom, #4365b4, #365293);
    background-image: linear-gradient(to bottom, #4365b4, #365293);
    -webkit-transition: box-shadow .05s ease-in-out;
    -moz-transition: box-shadow .05s ease-in-out;
    -o-transition: box-shadow .05s ease-in-out;
    transition: box-shadow .05s ease-in-out
}

.btn.btn-facebook:active {
    background: #3d5ca5;
    border-color: #2a3f71;
    -webkit-box-shadow: 0 0 5px #3d5ca5 inset;
    -moz-box-shadow: 0 0 5px #3d5ca5 inset;
    box-shadow: 0 0 5px #3d5ca5 inset
}

.btn-group.open .btn.btn-default.dropdown-toggle {
    background-color: #fdfdfd
}

.btn-group.open .btn.btn-red.dropdown-toggle {
    background-color: #c57979
}

.btn-group.open .btn.btn-green.dropdown-toggle {
    background-color: #96c161
}

.btn-group.open .btn.btn-blue.dropdown-toggle {
    background-color: #5ba5cb
}

.btn-group.open .btn.btn-gray.dropdown-toggle {
    background-color: #8c97a6
}

.btn-group.open .btn.btn-black.dropdown-toggle {
    background-color: #838383
}

.btn-group.open .btn.btn-lightblue.dropdown-toggle {
    background-color: #6db5d5
}

.btn-group.open .btn.btn-gold.dropdown-toggle {
    background-color: #d5a44c
}

.btn-group.open .btn.btn-sea.dropdown-toggle {
    background-color: #5a837e
}

.btn-group.open .btn.btn-brown.dropdown-toggle {
    background-color: #bb934f
}

.btn-group.open .btn.btn-twitter.dropdown-toggle {
    background-color: #73b4ff
}

.btn-group.open .btn.btn-facebook.dropdown-toggle {
    background-color: #4365b4
}

.btn-group.btn.btn-large {
    padding: 8px 10px
}

.btn-group > .btn, .btn-group > .dropdown-menu {
    font-size: 12px
}

.caret {
    opacity: .6
}

.btn-mini .caret, .btn-small .caret, .btn-large .caret {
    margin-top: 8px;
    margin-left: 4px
}

.dropdown-menu .divider {
    border-top: none !important
}

.btn i {
    vertical-align: middle
}

.btn-group > .btn:first-child {
    margin-left: 0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px
}

.btn-group > .btn:last-child, .btn-group > .dropdown-toggle {
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px
}

.btn-group > .btn.large:first-child {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px
}

.btn-group > .btn.large:last-child, .btn-group > .large.dropdown-toggle {
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px
}

.action-nav-normal .action-nav-button.default a, .action-nav-normal .action-nav-button.red a, .action-nav-normal .action-nav-button.green a, .action-nav-normal .action-nav-button.blue a, .action-nav-normal .action-nav-button.gray a, .action-nav-normal .action-nav-button.black a, .action-nav-normal .action-nav-button.lightblue a, .action-nav-normal .action-nav-button.gold a, .action-nav-normal .action-nav-button.sea a, .action-nav-normal .action-nav-button.brown a, .action-nav-normal .action-nav-button.twitter a, .action-nav-normal .action-nav-button.facebook a {
    color: #fff;
    text-shadow: 0 1px rgba(51, 51, 51, 0.5)
}

.action-nav-normal .action-nav-button.default a i, .action-nav-normal .action-nav-button.red a i, .action-nav-normal .action-nav-button.green a i, .action-nav-normal .action-nav-button.blue a i, .action-nav-normal .action-nav-button.gray a i, .action-nav-normal .action-nav-button.black a i, .action-nav-normal .action-nav-button.lightblue a i, .action-nav-normal .action-nav-button.gold a i, .action-nav-normal .action-nav-button.sea a i, .action-nav-normal .action-nav-button.brown a i, .action-nav-normal .action-nav-button.twitter a i, .action-nav-normal .action-nav-button.facebook a i {
    color: #fff;
    text-shadow: 0 1px rgba(51, 51, 51, 0.5)
}

.action-nav-normal .action-nav-button.default a:active, .action-nav-normal .action-nav-button.red a:active, .action-nav-normal .action-nav-button.green a:active, .action-nav-normal .action-nav-button.blue a:active, .action-nav-normal .action-nav-button.gray a:active, .action-nav-normal .action-nav-button.black a:active, .action-nav-normal .action-nav-button.lightblue a:active, .action-nav-normal .action-nav-button.gold a:active, .action-nav-normal .action-nav-button.sea a:active, .action-nav-normal .action-nav-button.brown a:active, .action-nav-normal .action-nav-button.twitter a:active, .action-nav-normal .action-nav-button.facebook a:active {
    -webkit-box-shadow: 0 0 2px #707070 inset, 0 1px 0 white;
    -moz-box-shadow: 0 0 2px #707070 inset, 0 1px 0 white;
    box-shadow: 0 0 2px #707070 inset, 0 1px 0 white
}

.action-nav-normal {
    text-align: center
}

.action-nav-normal.rounded .action-nav-button {
    margin-left: 10px;
    margin-right: 10px
}

.action-nav-normal.rounded .action-nav-button a {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    padding: 16px;
    min-width: auto
}

.action-nav-normal.rounded .action-nav-button a i {
    height: 40px;
    line-height: 40px
}

.action-nav-normal .action-nav-button {
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
    text-align: center
}

.action-nav-normal .action-nav-button a {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    background: #ececec;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZhZmFmYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fafafa), color-stop(100%, #ececec));
    background-image: -webkit-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -moz-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: -o-linear-gradient(to bottom, #fafafa, #ececec);
    background-image: linear-gradient(to bottom, #fafafa, #ececec);
    border: 1px solid #d5d5d5;
    -webkit-box-shadow: 0 0 0 1px #fafafa inset, 0 1px 1px 0 var(--table-border-color);
    -moz-box-shadow: 0 0 0 1px #fafafa inset, 0 1px 1px 0 var(--table-border-color);
    box-shadow: 0 0 0 1px #fafafa inset, 0 1px 1px 0 var(--table-border-color);
    padding: 10px 0;
    display: block;
    font-weight: 700;
    white-space: nowrap;
    color: #626262;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.action-nav-normal .action-nav-button a:hover {
    background: #f2f2f2;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y3ZjdmNyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f7f7f7), color-stop(100%, #f2f2f2));
    background-image: -webkit-linear-gradient(to bottom, #f7f7f7, #f2f2f2);
    background-image: -moz-linear-gradient(to bottom, #f7f7f7, #f2f2f2);
    background-image: -o-linear-gradient(to bottom, #f7f7f7, #f2f2f2);
    background-image: linear-gradient(to bottom, #f7f7f7, #f2f2f2)
}

.action-nav-normal .action-nav-button a:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #f7f7f7;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y3ZjdmNyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f4f4f4), color-stop(100%, #f7f7f7));
    background-image: -webkit-linear-gradient(to bottom, #f4f4f4, #f7f7f7);
    background-image: -moz-linear-gradient(to bottom, #f4f4f4, #f7f7f7);
    background-image: -o-linear-gradient(to bottom, #f4f4f4, #f7f7f7);
    background-image: linear-gradient(to bottom, #f4f4f4, #f7f7f7)
}

.action-nav-normal .action-nav-button a > span {
    display: block;
    padding-top: 4px;
    font-weight: 600
}

.action-nav-normal .action-nav-button a > i {
    font-size: 32px;
    color: #808080
}

.action-nav-normal .action-nav-button.default a {
    border: 1px solid #d9d9d9;
    -webkit-box-shadow: 0 1px 2px 0 #f8f8f8 inset;
    -moz-box-shadow: 0 1px 2px 0 #f8f8f8 inset;
    box-shadow: 0 1px 2px 0 #f8f8f8 inset;
    background: #d4d4d4;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y4ZjhmOCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Q0ZDRkNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f8f8f8), color-stop(100%, #d4d4d4));
    background-image: -webkit-linear-gradient(to bottom, #f8f8f8, #d4d4d4);
    background-image: -moz-linear-gradient(to bottom, #f8f8f8, #d4d4d4);
    background-image: -o-linear-gradient(to bottom, #f8f8f8, #d4d4d4);
    background-image: linear-gradient(to bottom, #f8f8f8, #d4d4d4)
}

.action-nav-normal .action-nav-button.default a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.default a:active {
    background: #e4e4e4
}

.action-nav-normal .action-nav-button.red a {
    border: 1px solid #ac4a4a;
    -webkit-box-shadow: 0 1px 2px 0 #c27272 inset;
    -moz-box-shadow: 0 1px 2px 0 #c27272 inset;
    box-shadow: 0 1px 2px 0 #c27272 inset;
    background: #a54747;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2MyNzI3MiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2E1NDc0NyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #c27272), color-stop(100%, #a54747));
    background-image: -webkit-linear-gradient(to bottom, #c27272, #a54747);
    background-image: -moz-linear-gradient(to bottom, #c27272, #a54747);
    background-image: -o-linear-gradient(to bottom, #c27272, #a54747);
    background-image: linear-gradient(to bottom, #c27272, #a54747)
}

.action-nav-normal .action-nav-button.red a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.red a:active {
    background: #b65555
}

.action-nav-normal .action-nav-button.green a {
    border: 1px solid #729d3e;
    -webkit-box-shadow: 0 1px 2px 0 #91be5a inset;
    -moz-box-shadow: 0 1px 2px 0 #91be5a inset;
    box-shadow: 0 1px 2px 0 #91be5a inset;
    background: #6d963b;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzkxYmU1YSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzZkOTYzYiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #91be5a), color-stop(100%, #6d963b));
    background-image: -webkit-linear-gradient(to bottom, #91be5a, #6d963b);
    background-image: -moz-linear-gradient(to bottom, #91be5a, #6d963b);
    background-image: -o-linear-gradient(to bottom, #91be5a, #6d963b);
    background-image: linear-gradient(to bottom, #91be5a, #6d963b)
}

.action-nav-normal .action-nav-button.green a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.green a:active {
    background: #7dac43
}

.action-nav-normal .action-nav-button.blue a {
    border: 1px solid #3682a9;
    -webkit-box-shadow: 0 1px 2px 0 #53a1c9 inset;
    -moz-box-shadow: 0 1px 2px 0 #53a1c9 inset;
    box-shadow: 0 1px 2px 0 #53a1c9 inset;
    background: #337ca1;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzUzYTFjOSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzN2NhMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #53a1c9), color-stop(100%, #337ca1));
    background-image: -webkit-linear-gradient(to bottom, #53a1c9, #337ca1);
    background-image: -moz-linear-gradient(to bottom, #53a1c9, #337ca1);
    background-image: -o-linear-gradient(to bottom, #53a1c9, #337ca1);
    background-image: linear-gradient(to bottom, #53a1c9, #337ca1)
}

.action-nav-normal .action-nav-button.blue a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.blue a:active {
    background: #3b8eb8
}

.action-nav-normal .action-nav-button.gray a {
    border: 1px solid #667384;
    -webkit-box-shadow: 0 1px 2px 0 #8692a2 inset;
    -moz-box-shadow: 0 1px 2px 0 #8692a2 inset;
    box-shadow: 0 1px 2px 0 #8692a2 inset;
    background: #626e7f;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg2OTJhMiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzYyNmU3ZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #8692a2), color-stop(100%, #626e7f));
    background-image: -webkit-linear-gradient(to bottom, #8692a2, #626e7f);
    background-image: -moz-linear-gradient(to bottom, #8692a2, #626e7f);
    background-image: -o-linear-gradient(to bottom, #8692a2, #626e7f);
    background-image: linear-gradient(to bottom, #8692a2, #626e7f)
}

.action-nav-normal .action-nav-button.gray a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.gray a:active {
    background: #6f7d90
}

.action-nav-normal .action-nav-button.black a {
    border: 1px solid #5f5f5f;
    -webkit-box-shadow: 0 1px 2px 0 #7e7e7e inset;
    -moz-box-shadow: 0 1px 2px 0 #7e7e7e inset;
    box-shadow: 0 1px 2px 0 #7e7e7e inset;
    background: #5a5a5a;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdlN2U3ZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzVhNWE1YSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #7e7e7e), color-stop(100%, #5a5a5a));
    background-image: -webkit-linear-gradient(to bottom, #7e7e7e, #5a5a5a);
    background-image: -moz-linear-gradient(to bottom, #7e7e7e, #5a5a5a);
    background-image: -o-linear-gradient(to bottom, #7e7e7e, #5a5a5a);
    background-image: linear-gradient(to bottom, #7e7e7e, #5a5a5a)
}

.action-nav-normal .action-nav-button.black a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.black a:active {
    background: #6a6a6a
}

.action-nav-normal .action-nav-button.lightblue a {
    border: 1px solid #3898c3;
    -webkit-box-shadow: 0 1px 2px 0 #65b1d3 inset;
    -moz-box-shadow: 0 1px 2px 0 #65b1d3 inset;
    box-shadow: 0 1px 2px 0 #65b1d3 inset;
    background: #3692bb;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY1YjFkMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzM2OTJiYiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #65b1d3), color-stop(100%, #3692bb));
    background-image: -webkit-linear-gradient(to bottom, #65b1d3, #3692bb);
    background-image: -moz-linear-gradient(to bottom, #65b1d3, #3692bb);
    background-image: -o-linear-gradient(to bottom, #65b1d3, #3692bb);
    background-image: linear-gradient(to bottom, #65b1d3, #3692bb)
}

.action-nav-normal .action-nav-button.lightblue a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.lightblue a:active {
    background: #45a1ca
}

.action-nav-normal .action-nav-button.gold a {
    border: 1px solid #b08029;
    -webkit-box-shadow: 0 1px 2px 0 #d3a044 inset;
    -moz-box-shadow: 0 1px 2px 0 #d3a044 inset;
    box-shadow: 0 1px 2px 0 #d3a044 inset;
    background: #a87a27;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2QzYTA0NCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2E4N2EyNyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #d3a044), color-stop(100%, #a87a27));
    background-image: -webkit-linear-gradient(to bottom, #d3a044, #a87a27);
    background-image: -moz-linear-gradient(to bottom, #d3a044, #a87a27);
    background-image: -o-linear-gradient(to bottom, #d3a044, #a87a27);
    background-image: linear-gradient(to bottom, #d3a044, #a87a27)
}

.action-nav-normal .action-nav-button.gold a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.gold a:active {
    background: #c18c2d
}

.action-nav-normal .action-nav-button.sea a {
    border: 1px solid #3d5955;
    -webkit-box-shadow: 0 1px 2px 0 #567d78 inset;
    -moz-box-shadow: 0 1px 2px 0 #567d78 inset;
    box-shadow: 0 1px 2px 0 #567d78 inset;
    background: #39534f;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzU2N2Q3OCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzM5NTM0ZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #567d78), color-stop(100%, #39534f));
    background-image: -webkit-linear-gradient(to bottom, #567d78, #39534f);
    background-image: -moz-linear-gradient(to bottom, #567d78, #39534f);
    background-image: -o-linear-gradient(to bottom, #567d78, #39534f);
    background-image: linear-gradient(to bottom, #567d78, #39534f)
}

.action-nav-normal .action-nav-button.sea a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.sea a:active {
    background: #456561
}

.action-nav-normal .action-nav-button.brown a {
    border: 1px solid #8c6c36;
    -webkit-box-shadow: 0 1px 2px 0 #b88e48 inset;
    -moz-box-shadow: 0 1px 2px 0 #b88e48 inset;
    box-shadow: 0 1px 2px 0 #b88e48 inset;
    background: #856733;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2I4OGU0OCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzg1NjczMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #b88e48), color-stop(100%, #856733));
    background-image: -webkit-linear-gradient(to bottom, #b88e48, #856733);
    background-image: -moz-linear-gradient(to bottom, #b88e48, #856733);
    background-image: -o-linear-gradient(to bottom, #b88e48, #856733);
    background-image: linear-gradient(to bottom, #b88e48, #856733)
}

.action-nav-normal .action-nav-button.brown a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.brown a:active {
    background: #9b783c
}

.action-nav-normal .action-nav-button.twitter a {
    border: 1px solid #2c8eff;
    -webkit-box-shadow: 0 1px 2px 0 #69afff inset;
    -moz-box-shadow: 0 1px 2px 0 #69afff inset;
    box-shadow: 0 1px 2px 0 #69afff inset;
    background: #2189ff;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY5YWZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzIxODlmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #69afff), color-stop(100%, #2189ff));
    background-image: -webkit-linear-gradient(to bottom, #69afff, #2189ff);
    background-image: -moz-linear-gradient(to bottom, #69afff, #2189ff);
    background-image: -o-linear-gradient(to bottom, #69afff, #2189ff);
    background-image: linear-gradient(to bottom, #69afff, #2189ff)
}

.action-nav-normal .action-nav-button.twitter a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.twitter a:active {
    background: #4099ff
}

.action-nav-normal .action-nav-button.facebook a {
    border: 1px solid #2f4880;
    -webkit-box-shadow: 0 1px 2px 0 #4061ad inset;
    -moz-box-shadow: 0 1px 2px 0 #4061ad inset;
    box-shadow: 0 1px 2px 0 #4061ad inset;
    background: #2d4479;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQwNjFhZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzJkNDQ3OSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #4061ad), color-stop(100%, #2d4479));
    background-image: -webkit-linear-gradient(to bottom, #4061ad, #2d4479);
    background-image: -moz-linear-gradient(to bottom, #4061ad, #2d4479);
    background-image: -o-linear-gradient(to bottom, #4061ad, #2d4479);
    background-image: linear-gradient(to bottom, #4061ad, #2d4479)
}

.action-nav-normal .action-nav-button.facebook a:hover {
    opacity: .95;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.action-nav-normal .action-nav-button.facebook a:active {
    background: #35508f
}

.action-nav-normal .badge, .action-nav-normal .label {
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 3px 5px;
    display: block;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.action-nav-normal .triangle-button {
    border-top-right-radius: 3px;
    pointer-events: none
}

.action-nav-normal .triangle-button i {
    right: 4px;
    top: 3px
}

.dashboard-stats .glyph {
    padding: 0;
    background: #86B6DA;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #5F9FCE;
    height: 32px;
    width: 32px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.dashboard-stats .glyph i {
    color: white;
    line-height: 29px
}

.dashboard-stats .count {
    font-size: 32px;
    line-height: 32px;
    height: 32px
}

.dashboard-stats ul.inline li {
    vertical-align: middle
}

.dashboard-stats .stats-label {
    color: #999999;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 5px
}

.dashboard-stats.small .glyph {
    height: 24px;
    width: 24px
}

.dashboard-stats.small .glyph i {
    line-height: 23px
}

.dashboard-stats.small .count {
    font-size: 16px;
    line-height: 16px;
    height: 16px
}

.dashboard-stats .progress {
    margin-bottom: 0;
    height: 5px
}

.ibutton-container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    height: 24px;
    cursor: pointer;
    overflow: hidden;
    max-width: 400px;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -moz-user-focus: ignore;
    -moz-user-input: disabled;
    width: 80px
}

.ibutton-container * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.ibutton-container input {
    position: absolute;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -moz-opacity: .0;
    opacity: .0;
    -moz-user-input: enabled !important
}

.ibutton-container i {
    position: relative;
    top: -2px
}

.ibutton-handle {
    display: block;
    height: 24px;
    cursor: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    background: red;
    z-index: 3;
    width: 30px
}

.ibutton-handle-right {
    display: none;
    height: 100%;
    width: 100%;
    padding-right: 3px;
    background: blue;
    z-index: 3
}

.ibutton-handle-middle {
    height: 100%;
    width: 100%;
    background: green;
    z-index: 3
}

.ibutton-label-on, .ibutton-label-off {
    white-space: nowrap;
    font-size: 17px;
    line-height: 17px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: inherit;
    display: block;
    height: 22px;
    position: absolute;
    width: auto;
    top: 0;
    overflow: hidden;
    height: 24px
}

.ibutton-label-on span, .ibutton-label-off span {
    display: inline-block;
    text-align: center;
    position: absolute
}

.ibutton-label-on label, .ibutton-label-off label {
    padding: 0;
    margin: 0;
    height: 24px;
    line-height: 26px
}

.ibutton-label-on {
    background: blue;
    color: #fff;
    text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.4);
    left: 0;
    z-index: 1
}

.ibutton-label-on span {
    left: 0;
    right: 4px
}

.ibutton-label-off {
    background: yellow;
    color: #7c7c7c;
    background-position: 100% 0;
    text-shadow: 0 -1px 2px rgba(153, 153, 153, 0.4);
    text-align: right;
    width: 100%
}

.ibutton-label-off span {
    right: 0;
    padding-right: 6px
}

.ibutton-container label {
    cursor: inherit;
    padding: 0 3px;
    font-size: 1em !important
}

.ibutton-focus label {
    border: 1px dotted #666 !important;
    padding: 0 2px
}

.ibutton-focus div.ibutton-label-on span label {
    border-color: #fff !important
}

.ibutton-padding-left, .ibutton-padding-right {
    display: none;
    position: absolute;
    top: 4px;
    z-index: 2;
    width: 3px;
    height: 20px
}

.ibutton-padding-left {
    left: 0
}

.ibutton-padding-right {
    right: 0;
    background-position: 100% -4px
}

.ibutton-disabled {
    cursor: not-allowed !important
}

.ibutton-disabled .ibutton-handle {
    background-position: 0 -81px
}

.ibutton-disabled .ibutton-handle-right {
    background-position: 100% -81px
}

.ibutton-disabled .ibutton-handle-middle {
    background-position: 50% -81px
}

.ibutton-disabled div.ibutton-label-on {
    background-position: 0 -27px
}

.ibutton-disabled div.ibutton-label-off {
    background-position: 100% -27px
}

.ibutton-disabled .ibutton-padding-left {
    background-position: 0 -27px
}

.ibutton-disabled .ibutton-padding-right {
    background-position: 100% -27px
}

.ibutton-disabled div.ibutton-label-on {
    color: #fff
}

.ibutton-disabled div.ibutton-label-off {
    color: #cbcbcb
}

.ibutton-label-off, .ibutton-label-on {
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3) inset;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3) inset
}

.ibutton-label-off label, .ibutton-label-on label {
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.5)
}

.ibutton-label-on {
    background: #7fb3d4;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2E1Y2FlMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzdmYjNkNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #a5cae1), color-stop(100%, #7fb3d4));
    background-image: -webkit-linear-gradient(to bottom, #a5cae1, #7fb3d4);
    background-image: -moz-linear-gradient(to bottom, #a5cae1, #7fb3d4);
    background-image: -o-linear-gradient(to bottom, #a5cae1, #7fb3d4);
    background-image: linear-gradient(to bottom, #a5cae1, #7fb3d4);
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.ibutton-label-off {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #e49181;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RjN2U2ZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2U0OTE4MSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #dc7e6f), color-stop(100%, #e49181));
    background-image: -webkit-linear-gradient(to bottom, #dc7e6f, #e49181);
    background-image: -moz-linear-gradient(to bottom, #dc7e6f, #e49181);
    background-image: -o-linear-gradient(to bottom, #dc7e6f, #e49181);
    background-image: linear-gradient(to bottom, #dc7e6f, #e49181)
}

.ibutton-handle {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #8F8F8F;
    background: #ebebeb;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ViZWJlYiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #ebebeb));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #ebebeb);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #ebebeb);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #ebebeb);
    background-image: linear-gradient(to bottom, #fdfdfd, #ebebeb)
}

.ibutton-container {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5)
}

.progress {
    height: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background: #eee;
    -webkit-box-shadow: 0 1px 0 white, 0 0 0 1px rgba(0, 0, 0, 0.1) inset, 0 1px 4px rgba(0, 0, 0, 0.2) inset;
    -moz-box-shadow: 0 1px 0 white, 0 0 0 1px rgba(0, 0, 0, 0.1) inset, 0 1px 4px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0 1px 0 white, 0 0 0 1px rgba(0, 0, 0, 0.1) inset, 0 1px 4px rgba(0, 0, 0, 0.2) inset
}

.progress .bar {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.progress.progress-green .bar {
    background-color: #a7bf3f;
    background-image: -moz-linear-gradient(to bottom, #b2cb49, #97af32);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b2cb49), to(#97af32));
    background-image: -webkit-linear-gradient(to bottom, #b2cb49, #97af32);
    background-image: -o-linear-gradient(to bottom, #b2cb49, #97af32);
    background-image: linear-gradient(to bottom, #b2cb49, #97af32);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFB2CB49', endColorstr='#FF97AF32', GradientType=0);
    border: 1px solid #869b2c
}

.progress.progress-green.progress-striped .bar {
    background-color: #b2cb49;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress.progress-blue .bar {
    background-color: #88c6f3;
    background-image: -moz-linear-gradient(to bottom, #9bcff5, #6db9f0);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9bcff5), to(#6db9f0));
    background-image: -webkit-linear-gradient(to bottom, #9bcff5, #6db9f0);
    background-image: -o-linear-gradient(to bottom, #9bcff5, #6db9f0);
    background-image: linear-gradient(to bottom, #9bcff5, #6db9f0);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9BCFF5', endColorstr='#FF6DB9F0', GradientType=0);
    border: 1px solid #55aeee
}

.progress.progress-blue.progress-striped .bar {
    background-color: #9bcff5;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress.progress-orange .bar {
    background-color: #d8935e;
    background-image: -moz-linear-gradient(to bottom, #dc9e6f, #d28346);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#dc9e6f), to(#d28346));
    background-image: -webkit-linear-gradient(to bottom, #dc9e6f, #d28346);
    background-image: -o-linear-gradient(to bottom, #dc9e6f, #d28346);
    background-image: linear-gradient(to bottom, #dc9e6f, #d28346);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFDC9E6F', endColorstr='#FFD28346', GradientType=0);
    border: 1px solid #cd7532
}

.progress.progress-orange.progress-striped .bar {
    background-color: #dc9e6f;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress.progress-black .bar {
    background-color: #5b5b5b;
    background-image: -moz-linear-gradient(to bottom, #666666, #4c4c4c);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#666666), to(#4c4c4c));
    background-image: -webkit-linear-gradient(to bottom, #666666, #4c4c4c);
    background-image: -o-linear-gradient(to bottom, #666666, #4c4c4c);
    background-image: linear-gradient(to bottom, #666666, #4c4c4c);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF666666', endColorstr='#FF4C4C4C', GradientType=0);
    border: 1px solid #3f3f3f
}

.progress.progress-black.progress-striped .bar {
    background-color: #666666;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress.progress-red .bar {
    background-color: #cf6765;
    background-image: -moz-linear-gradient(to bottom, #d47775, #c8514e);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#d47775), to(#c8514e));
    background-image: -webkit-linear-gradient(to bottom, #d47775, #c8514e);
    background-image: -o-linear-gradient(to bottom, #d47775, #c8514e);
    background-image: linear-gradient(to bottom, #d47775, #c8514e);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFD47775', endColorstr='#FFC8514E', GradientType=0);
    border: 1px solid #c13f3c
}

.progress.progress-red.progress-striped .bar {
    background-color: #d47775;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.sine-chart {
    height: 186px
}

/*Select2 base css BEGIN*/
/*
Version: 3.4.3 Timestamp: Tue Sep 17 06:47:14 PDT 2013
*/
.select2-container {
    margin: 0;
    position: relative;
    display: inline-block;
    /* inline-block for ie7 */
    zoom: 1;
    *display: inline;
    vertical-align: middle;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.

    More Info : http://www.quirksmode.org/css/box.html
  */
  -webkit-box-sizing: border-box; /* webkit */
     -moz-box-sizing: border-box; /* firefox */
          box-sizing: border-box; /* css3 */
}

.select2-container .select2-choice {
    display: block;
    height: 26px;
    padding: 0 0 0 8px;
    overflow: hidden;
    position: relative;

    border: 1px solid #aaa;
    white-space: nowrap;
    line-height: 26px;
    color: #444;
    text-decoration: none;

    border-radius: 4px;

    background-clip: padding-box;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    background-color: #fff;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff));
    background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%);
    background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#eeeeee', GradientType = 0);
    background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: #aaa;

    border-radius: 0 0 4px 4px;

    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.9, #fff));
    background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 90%);
    background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 90%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
    background-image: linear-gradient(to bottom, #eee 0%, #fff 90%);
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
    margin-right: 26px;
    display: block;
    overflow: hidden;

    white-space: nowrap;

    text-overflow: ellipsis;
}

.select2-container .select2-choice abbr {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 24px;
    top: 8px;

    font-size: 1px;
    text-decoration: none;

    border: 0;
    background: url('images/select2/select2.png') right top no-repeat;
    cursor: pointer;
    outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
    display: inline-block;
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer;
}

.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 9998;
    /* styles required for IE to work */
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-drop {
    width: 100%;
    margin-top: -1px;
    position: absolute;
    z-index: 9999;
    top: 100%;

    background: #fff;
    color: #000;
    border: 1px solid #aaa;
    border-top: 0;

    border-radius: 0 0 4px 4px;

    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
            box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
}

.select2-drop-auto-width {
    border-top: 1px solid #aaa;
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: 4px;
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
    border-top: 1px solid #aaa;
    /*border-bottom: 0; Enable bottom border for select2. */

    border-radius: 4px 4px 0 0;

    -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
            box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
}

.select2-drop-active {
    border: 1px solid #5897fb;
    border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
    border-top: 1px solid #5897fb;
}

.select2-container .select2-choice .select2-arrow {
    display: inline-block;
    width: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    border-left: 1px solid #aaa;
    border-radius: 0 4px 4px 0;

    background-clip: padding-box;

    background: #ccc;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ccc), color-stop(0.6, #eee));
    background-image: -webkit-linear-gradient(center bottom, #ccc 0%, #eee 60%);
    background-image: -moz-linear-gradient(center bottom, #ccc 0%, #eee 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#eeeeee', endColorstr = '#cccccc', GradientType = 0);
    background-image: linear-gradient(to bottom, #ccc 0%, #eee 60%);
}

.select2-container .select2-choice .select2-arrow b {
    display: block;
    width: 100%;
    height: 100%;
    background: url('images/select2/select2.png') no-repeat 0 1px;
}

.select2-search {
    display: inline-block;
    width: 100%;
    min-height: 26px;
    margin: 0;
    padding-left: 4px;
    padding-right: 4px;

    position: relative;
    z-index: 10000;

    white-space: nowrap;
}

.select2-search input {
    width: 100%;
    height: auto !important;
    min-height: 26px;
    padding: 4px 20px 4px 5px;
    margin: 0;

    outline: 0;
    font-family: sans-serif;
    font-size: 1em;

    border: 1px solid #aaa;
    border-radius: 0;

    -webkit-box-shadow: none;
            box-shadow: none;

    background: #fff url('images/select2/select2.png') no-repeat 100% -22px;
    background: url('images/select2/select2.png') no-repeat 100% -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
    background: url('images/select2/select2.png') no-repeat 100% -22px, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('images/select2/select2.png') no-repeat 100% -22px, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('images/select2/select2.png') no-repeat 100% -22px, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 4px;
}

.select2-search input.select2-active {
    background: #fff url('images/select2/select2-spinner.gif') no-repeat 100%;
    background: url('images/select2/select2-spinner.gif') no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
    background: url('images/select2/select2-spinner.gif') no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('images/select2/select2-spinner.gif') no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('images/select2/select2-spinner.gif') no-repeat 100%, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border: 1px solid #5897fb;
    outline: none;

    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
            box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.select2-dropdown-open .select2-choice {
    border-bottom-color: transparent;
    -webkit-box-shadow: 0 1px 0 #fff inset;
            box-shadow: 0 1px 0 #fff inset;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    background-color: #eee;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), color-stop(0.5, #eee));
    background-image: -webkit-linear-gradient(center bottom, #fff 0%, #eee 50%);
    background-image: -moz-linear-gradient(center bottom, #fff 0%, #eee 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
    background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border: 1px solid #5897fb;
    border-top-color: transparent;

    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(0.5, #eee));
    background-image: -webkit-linear-gradient(center top, #fff 0%, #eee 50%);
    background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
    background-image: linear-gradient(to top, #fff 0%, #eee 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
    background: transparent;
    border-left: none;
    filter: none;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
    background-position: -18px 1px;
}

/* results */
.select2-results {
    max-height: 200px;
    padding: 0 0 0 4px;
    margin: 4px 4px 4px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-results ul.select2-result-sub {
    margin: 0;
    padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label { padding-left: 20px }
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 40px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 60px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 80px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 100px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 110px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 120px }

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
    font-weight: bold;
}

.select2-results .select2-result-label {
    padding: 3px 7px 4px;
    margin: 0;
    cursor: pointer;

    min-height: 1em;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

.select2-results .select2-highlighted {
    background: #3875d7;
    color: #fff;
}

.select2-results li em {
    background: #feffde;
    font-style: normal;
}

.select2-results .select2-highlighted em {
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: #fff;
    color: #000;
}


.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background: #f4f4f4;
    display: list-item;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
    color: #666;
    background: #f4f4f4;
    display: list-item;
    cursor: default;
}
.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
    display: none;
}

.select2-more-results.select2-active {
    background: #f4f4f4 url('images/select2/select2-spinner.gif') no-repeat 100%;
}

.select2-more-results {
    background: #f4f4f4;
    display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
    background-color: #f4f4f4;
    background-image: none;
    border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
    display: none;
}


/* Multiselect */

.select2-container-multi .select2-choices {
    height: auto !important;
    height: 1%;
    margin: 0;
    padding: 0;
    position: relative;

    border: 1px solid #aaa;
    cursor: text;
    overflow: hidden;

    background-color: #fff;
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eee), color-stop(15%, #fff));
    background-image: -webkit-linear-gradient(to bottom, #eee 1%, #fff 15%);
    background-image: -moz-linear-gradient(to bottom, #eee 1%, #fff 15%);
    background-image: linear-gradient(to bottom, #eee 1%, #fff 15%);
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
    min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #5897fb;
    outline: none;

    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
            box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}
.select2-container-multi .select2-choices li {
    float: left;
    list-style: none;
}
.select2-container-multi .select2-choices .select2-search-field {
    width: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding: 5px;
    margin: 1px 0;

    font-family: sans-serif;
    font-size: 100%;
    color: #666;
    outline: 0;
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
    background: #fff url('images/select2/select2-spinner.gif') no-repeat 100% !important;
}

.select2-default {
    color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 18px;
    margin: 3px 0 3px 5px;
    position: relative;

    line-height: 13px;
    color: #333;
    cursor: default;
    border: 1px solid #aaaaaa;

    border-radius: 3px;

    -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);

    background-clip: padding-box;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    background-color: #e4e4e4;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#f4f4f4', GradientType=0);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eee));
    background-image: -webkit-linear-gradient(to bottom, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-image: -moz-linear-gradient(to bottom, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-image: linear-gradient(to bottom, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
    cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #d4d4d4;
}

.select2-search-choice-close {
    display: block;
    width: 12px;
    height: 13px;
    position: absolute;
    right: 3px;
    top: 4px;

    font-size: 1px;
    outline: none;
    background: url('images/select2/select2.png') right top no-repeat;
}

.select2-container-multi .select2-search-choice-close {
    left: 3px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px;
}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
    background-position: right -11px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 5px;
    border: 1px solid #ddd;
    background-image: none;
    background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {    display: none;
    background: none;
}
/* end Multiselect */


.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}
/* Retina-ize icons */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)  {
  .select2-search input, .select2-search-choice-close, .select2-container .select2-choice abbr, .select2-container .select2-choice .select2-arrow b {
      background-image: url('images/select2/select2x2.png');
      background-repeat: no-repeat;
      background-size: 60px 40px;
  }
  .select2-search input {
      background-position: 100% -21px;
  }
}
/*Select2 base css END*/

.select2-container {
    width: 100%
}

.select2-container .select2-choice {
    border: 1px solid #ccc;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background: whitesmoke;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y1ZjVmNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #f5f5f5));
    background-image: -webkit-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: -moz-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: -o-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: linear-gradient(to bottom, #ffffff, #f5f5f5)
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: #d2d2d2;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background: whitesmoke;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y1ZjVmNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #f5f5f5));
    background-image: -webkit-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: -moz-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: -o-linear-gradient(to bottom, #ffffff, #f5f5f5);
    background-image: linear-gradient(to bottom, #ffffff, #f5f5f5)
}

.select2-default {
    color: #666 !important
}

.select2-results .select2-highlighted {
    background: #519FC7
}

.select2-drop {
    border: 1px solid #d2d2d2;
    /*border-top: 0; Enable top border for select2. */
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.select2-drop.select2-drop-above {
    border-top: 1px solid #d2d2d2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.select2-container .select2-choice {
    color: #666666;
    height: 28px
}

.select2-container .select2-choice span.select2-arrow {
    border-left: 1px solid transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background: transparent;
    width: 20px
}

.select2-container .select2-choice span.select2-arrow b {
    background: none
}

.select2-container .select2-choice span.select2-arrow b:after {
    content: "\f0dd";
    top: 2px;
    right: 2px;
    font-family: FontAwesome;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    position: absolute;
    width: 18px;
    height: 18px;
    pointer-events: none;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    color: #888
}

.select2-search:after {
    content: "\f002";
    font-family: FontAwesome;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    position: absolute;
    width: 18px;
    height: 18px;
    pointer-events: none;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    color: #888;
    top: 5px;
    right: 10px
}

.select2-search input {
    border: 1px solid #ccc;
    background: #fff
}

.select2-spin,
.select2-search input.select2-active {
    background: white url("images/select2/select2-spinner.gif") no-repeat 100%
}

.select2-container-active .select2-choice, .select2-container-active .select2-choices {
    border: 1px solid #bbb;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.select2-dropdown-open .select2-choice {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #fff
}

.select2-container-multi .select2-choices {
    background: #fff;
    border: 1px solid #d2d2d2
}

.select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #d2d2d2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.select2-container-multi .select2-choices .select2-search-choice {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #f2f2f2;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZjZmNmYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fcfcfc), color-stop(100%, #f2f2f2));
    background-image: -webkit-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: -moz-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: -o-linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    background-image: linear-gradient(to bottom, #fcfcfc, #f2f2f2);
    border: 1px solid #d2d2d2
}

.select2-container-multi .select2-search-choice-close {
    background: none
}

.select2-container-multi .select2-search-choice-close:after {
    content: "\f00d";
    top: -4px;
    right: -2px;
    font-family: FontAwesome;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    position: absolute;
    width: 18px;
    height: 18px;
    pointer-events: none;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    color: #888
}

.ui-slider {
    position: relative;
    text-align: left
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 16px;
    height: 16px;
    cursor: default;
    border: none;
    cursor: pointer
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    height: 6px;
    font-size: .7em;
    display: block;
    border: 1px solid #5ba8e1;
    box-shadow: 0 1px 0 #aad6f6 inset;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -khtml-border-radius: 6px;
    border-radius: 6px;
    background: #81b8f3;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2EwZDRmNSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzgxYjhmMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #a0d4f5), color-stop(100%, #81b8f3));
    background-image: -webkit-linear-gradient(to bottom, #a0d4f5, #81b8f3);
    background-image: -moz-linear-gradient(to bottom, #a0d4f5, #81b8f3);
    background-image: -o-linear-gradient(to bottom, #a0d4f5, #81b8f3);
    background-image: linear-gradient(to bottom, #a0d4f5, #81b8f3)
}

.ui-slider-horizontal {
    height: 4px;
    background: #d7d7d7;
    border: 1px solid #bababa;
    box-shadow: 0 1px 0 #fff, 0 1px 0 #cfcfcf inset;
    clear: both;
    margin: 8px 0;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px
}

.ui-slider-horizontal .ui-slider-handle {
    top: -6px;
    margin-left: -0.6em
}

.ui-slider-horizontal .ui-slider-range {
    top: -1px;
    height: 100%
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0
}

.ui-slider-vertical .ui-slider-handle {
    left: -6px;
    margin-left: 0;
    margin-bottom: -0.6em
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 4px;
    background: #81b8f3;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2EwZDRmNSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzgxYjhmMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #a0d4f5), color-stop(100%, #81b8f3));
    background-image: -webkit-linear-gradient(to bottom, #a0d4f5, #81b8f3);
    background-image: -moz-linear-gradient(to bottom, #a0d4f5, #81b8f3);
    background-image: -o-linear-gradient(to bottom, #a0d4f5, #81b8f3);
    background-image: linear-gradient(to bottom, #a0d4f5, #81b8f3)
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0
}

.ui-slider-vertical {
    height: 100px;
    width: 6px;
    background: #d7d7d7;
    box-shadow: 0 1px 0 white, 0 1px 0 #cfcfcf inset;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px
}

.ui-slider .ui-slider-handle {
    border-radius: 50%;
    background: #f9fbfa;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2M3Y2VkNiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y5ZmJmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #c7ced6), color-stop(100%, #f9fbfa));
    background-image: -webkit-linear-gradient(to bottom, #c7ced6, #f9fbfa);
    background-image: -moz-linear-gradient(to bottom, #c7ced6, #f9fbfa);
    background-image: -o-linear-gradient(to bottom, #c7ced6, #f9fbfa);
    background-image: linear-gradient(to bottom, #c7ced6, #f9fbfa);
    width: 22px;
    height: 22px;
    -webkit-box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.6), 0 -1px 0 1px rgba(0, 0, 0, 0.15) inset, 0 1px 0 1px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.6), 0 -1px 0 1px rgba(0, 0, 0, 0.15) inset, 0 1px 0 1px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.6), 0 -1px 0 1px rgba(0, 0, 0, 0.15) inset, 0 1px 0 1px rgba(255, 255, 255, 0.9) inset;
    -webkit-transition: box-shadow .3s;
    -moz-transition: box-shadow .3s;
    -o-transition: box-shadow .3s;
    transition: box-shadow .3s
}

.ui-slider .ui-slider-handle:active {
    -webkit-box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.6), 0 -1px 0 1px rgba(0, 0, 0, 0.15) inset, 0 1px 0 1px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.6), 0 -1px 0 1px rgba(0, 0, 0, 0.15) inset, 0 1px 0 1px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.6), 0 -1px 0 1px rgba(0, 0, 0, 0.15) inset, 0 1px 0 1px rgba(255, 255, 255, 0.9) inset
}

.ui-slider .ui-slider-handle:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 50%;
    margin-top: -4px;
    left: 50%;
    margin-left: -4px;
    background: #30a2d2;
    -webkit-box-shadow: 0 1px 1px 1px rgba(22, 73, 163, 0.7) inset, 0 1px 0 0 white;
    -moz-box-shadow: 0 1px 1px 1px rgba(22, 73, 163, 0.7) inset, 0 1px 0 0 white;
    box-shadow: 0 1px 1px 1px rgba(22, 73, 163, 0.7) inset, 0 1px 0 0 white
}

.ui-slider-horizontal .ui-slider-handle {
    top: -10px
}

.ui-slider-vertical .ui-slider-handle {
    left: -8px
}

.ranged-slider-ui label {
    color: #858585
}

.ranged-slider-ui input {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 0
}

.ranged-slider-ui input:focus {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.ranged-slider-ui.vertical-bars span {
    height: 120px;
    float: left;
    margin-right: 30px;
    display: block
}

.icheckbox_flat-aero, .iradio_flat-aero {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    /*background: url("../images/blue.png") no-repeat;*/
    border: none;
    cursor: pointer
}

.icheckbox_flat-aero + label, .iradio_flat-aero + label {
    display: inline-block;
    position: relative;
    top: -5px;
    left: 5px
}

.icheckbox_flat-aero {
    background-position: 0 0
}

.icheckbox_flat-aero.checked {
    background-position: -22px 0
}

.icheckbox_flat-aero.disabled {
    background-position: -44px 0;
    cursor: default
}

.icheckbox_flat-aero.checked.disabled {
    background-position: -66px 0
}

.iradio_flat-aero {
    background-position: -88px 0
}

.iradio_flat-aero.checked {
    background-position: -110px 0
}

.iradio_flat-aero.disabled {
    background-position: -132px 0;
    cursor: default
}

.iradio_flat-aero.checked.disabled {
    background-position: -154px 0
}

@media only screen and (-webkit-min-device-pixel-ratio:1.5), only screen and (-moz-min-device-pixel-ratio:1.5), only screen and (-o-min-device-pixel-ratio:3 / 2), only screen and (min-device-pixel-ratio:1.5) {
    .icheckbox_flat-aero, .iradio_flat-aero {
        /*background-image: url("../images/blue@2x.png");*/
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px
    }
}

.xchart .line {
    stroke-width: 3px;
    fill: none
}

.xchart .fill {
    stroke-width: 0
}

.xchart circle {
    stroke: #FFF;
    stroke-width: 3px
}

.xchart .axis .domain {
    fill: none
}

.xchart .axis .tick {
    stroke: #EEE;
    stroke-width: 1px
}

.xchart .axis text {
    fill: #666;
    font-size: 12px
}

.xchart .color0 .line {
    stroke: #3880aa
}

.xchart .color0 rect, .xchart .color0 circle {
    fill: #3880aa
}

.xchart .color0 .fill {
    fill: rgba(56, 128, 170, 0.1)
}

.xchart .color0.comp .line {
    stroke: #89bbd8
}

.xchart .color0.comp rect {
    fill: #89bbd8
}

.xchart .color0.comp .fill {
    display: none
}

.xchart .color0.comp circle, .xchart .color0.comp .pointer {
    fill: #89bbd8
}

.xchart .color1 .line {
    stroke: #4da944
}

.xchart .color1 rect, .xchart .color1 circle {
    fill: #4da944
}

.xchart .color1 .fill {
    fill: rgba(77, 169, 68, 0.1)
}

.xchart .color1.comp .line {
    stroke: #9dd597
}

.xchart .color1.comp rect {
    fill: #9dd597
}

.xchart .color1.comp .fill {
    display: none
}

.xchart .color1.comp circle, .xchart .color1.comp .pointer {
    fill: #9dd597
}

.xchart .color2 .line {
    stroke: #f26522
}

.xchart .color2 rect, .xchart .color2 circle {
    fill: #f26522
}

.xchart .color2 .fill {
    fill: rgba(242, 101, 34, 0.1)
}

.xchart .color2.comp .line {
    stroke: #f9b99a
}

.xchart .color2.comp rect {
    fill: #f9b99a
}

.xchart .color2.comp .fill {
    display: none
}

.xchart .color2.comp circle, .xchart .color2.comp .pointer {
    fill: #f9b99a
}

.xchart .color3 .line {
    stroke: #c6080d
}

.xchart .color3 rect, .xchart .color3 circle {
    fill: #c6080d
}

.xchart .color3 .fill {
    fill: rgba(198, 8, 13, 0.1)
}

.xchart .color3.comp .line {
    stroke: #f8555a
}

.xchart .color3.comp rect {
    fill: #f8555a
}

.xchart .color3.comp .fill {
    display: none
}

.xchart .color3.comp circle, .xchart .color3.comp .pointer {
    fill: #f8555a
}

.xchart .color4 .line {
    stroke: #672d8b
}

.xchart .color4 rect, .xchart .color4 circle {
    fill: #672d8b
}

.xchart .color4 .fill {
    fill: rgba(103, 45, 139, 0.1)
}

.xchart .color4.comp .line {
    stroke: #a869ce
}

.xchart .color4.comp rect {
    fill: #a869ce
}

.xchart .color4.comp .fill {
    display: none
}

.xchart .color4.comp circle, .xchart .color4.comp .pointer {
    fill: #a869ce
}

.xchart .color5 .line {
    stroke: #ce1797
}

.xchart .color5 rect, .xchart .color5 circle {
    fill: #ce1797
}

.xchart .color5 .fill {
    fill: rgba(206, 23, 151, 0.1)
}

.xchart .color5.comp .line {
    stroke: #f075cb
}

.xchart .color5.comp rect {
    fill: #f075cb
}

.xchart .color5.comp .fill {
    display: none
}

.xchart .color5.comp circle, .xchart .color5.comp .pointer {
    fill: #f075cb
}

.xchart .color6 .line {
    stroke: #d9ce00
}

.xchart .color6 rect, .xchart .color6 circle {
    fill: #d9ce00
}

.xchart .color6 .fill {
    fill: rgba(217, 206, 0, 0.1)
}

.xchart .color6.comp .line {
    stroke: #fff75a
}

.xchart .color6.comp rect {
    fill: #fff75a
}

.xchart .color6.comp .fill {
    display: none
}

.xchart .color6.comp circle, .xchart .color6.comp .pointer {
    fill: #fff75a
}

.xchart .color7 .line {
    stroke: #754c24
}

.xchart .color7 rect, .xchart .color7 circle {
    fill: #754c24
}

.xchart .color7 .fill {
    fill: rgba(117, 76, 36, 0.1)
}

.xchart .color7.comp .line {
    stroke: #c98c50
}

.xchart .color7.comp rect {
    fill: #c98c50
}

.xchart .color7.comp .fill {
    display: none
}

.xchart .color7.comp circle, .xchart .color7.comp .pointer {
    fill: #c98c50
}

.xchart .color8 .line {
    stroke: #2eb9b4
}

.xchart .color8 rect, .xchart .color8 circle {
    fill: #2eb9b4
}

.xchart .color8 .fill {
    fill: rgba(46, 185, 180, 0.1)
}

.xchart .color8.comp .line {
    stroke: #86e1de
}

.xchart .color8.comp rect {
    fill: #86e1de
}

.xchart .color8.comp .fill {
    display: none
}

.xchart .color8.comp circle, .xchart .color8.comp .pointer {
    fill: #86e1de
}

.xchart .color9 .line {
    stroke: #0e2e42
}

.xchart .color9 rect, .xchart .color9 circle {
    fill: #0e2e42
}

.xchart .color9 .fill {
    fill: rgba(14, 46, 66, 0.1)
}

.xchart .color9.comp .line {
    stroke: #2477ab
}

.xchart .color9.comp rect {
    fill: #2477ab
}

.xchart .color9.comp .fill {
    display: none
}

.xchart .color9.comp circle, .xchart .color9.comp .pointer {
    fill: #2477ab
}

.ex-tooltip {
    position: absolute;
    background: #EEE;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    padding: 5px;
    -webkit-box-shadow: 0 1px 3px #000;
    -moz-box-shadow: 0 1px 3px #000;
    -ms-box-shadow: 0 1px 3px #000;
    -o-box-shadow: 0 1px 3px #000;
    box-shadow: 0 1px 3px #000;
    border-collapse: separate;
    display: none
}

.justgage {
    width: 200px;
    height: 160px;
    display: inline-block
}

.justgage.small {
    width: 90px;
    height: 70px
}

.easyPieChart {
    position: relative;
    text-align: center;
    font-size: 16px;
    font-weight: 600
}

.easyPieChart.easy-pie-chart-small {
    font-size: 10px
}

.easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0
}

.sparkline-box {
    margin: 0;
    padding: 0;
    margin-top: 10px
}

.sparkline-box.side .sparkline {
    margin-top: 5px
}

.sparkline-box.side .sparkline-row {
    padding: 0;
    float: none;
    border: none
}

.sparkline-box.side h4 {
    margin-right: 20px;
    min-width: 70px;
    text-shadow: none
}

.sparkline-box.side h4 span {
    color: inherit
}

.sparkline-box .sparkline {
    margin-top: 8px;
    display: inline-block;
    vertical-align: top;
    height: 30px
}

.sparkline-box .big {
    width: 83px;
    height: 30px
}

.sparkline-box .sparkline-row {
    *zoom: 1;
    text-align: center;
    padding: 0;
    padding-right: 15px;
    float: left;
    border-right: 1px dotted #aaa;
    border-left: 1px dotted #fff
}

.sparkline-box .sparkline-row:before, .sparkline-box .sparkline-row:after {
    display: table;
    content: "";
    line-height: 0
}

.sparkline-box .sparkline-row:after {
    clear: both
}

.sparkline-box .sparkline-row:last-child {
    border-right: none
}

.sparkline-box .sparkline-row:first-child {
    border-left: none
}

.sparkline-box h4 {
    display: inline-block;
    text-align: center;
    margin: 0;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    min-width: 70px
}

.sparkline-box h4.red {
    color: #c75d5d
}

.sparkline-box h4.orange {
    color: #ff9f01
}

.sparkline-box h4.blue {
    color: #6e97aa
}

.sparkline-box h4.gray {
    color: #6b787f
}

.sparkline-box h4.green {
    color: #8fae53
}

.sparkline-box h4.dark-green {
    color: #779148
}

.sparkline-box h4 span {
    color: #717e88;
    font-size: 12px;
    display: block;
    text-align: center;
    font-weight: 400
}

.arrow-box-left {
    position: relative;
    background: #fafeff;
    border: 1px solid #b5cdd7;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.arrow-box-left:after, .arrow-box-left:before {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.arrow-box-left:after {
    border-right-color: #fafeff;
    border-width: 6px;
    top: 7px
}

.arrow-box-left:before {
    border-right-color: #b5cdd7;
    border-width: 7px;
    top: 6px
}

.arrow-box-left.gray {
    background: nil;
    border-color: #d8d8d8
}

.arrow-box-left.gray:before {
    border-right-color: #d8d8d8
}

.arrow-box-left {
    -webkit-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1)
}

.arrow-box-right {
    position: relative;
    background: #fafeff;
    border: 1px solid #b5cdd7;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.arrow-box-right:after, .arrow-box-right:before {
    left: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.arrow-box-right:after {
    border-left-color: #fafeff;
    border-width: 6px;
    top: 7px
}

.arrow-box-right:before {
    border-left-color: #b5cdd7;
    border-width: 7px;
    top: 6px
}

.arrow-box-right.gray {
    background: nil;
    border-color: #d8d8d8
}

.arrow-box-right.gray:before {
    border-left-color: #d8d8d8
}

.arrow-box-right {
    -webkit-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.1)
}

.chat-box.timeline .arrow-box-left {
    padding: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    min-height: auto;
    margin-right: 0
}

.chat-box.timeline .info, .chat-box.timeline .content {
    background: #fafafa;
    padding: 5px 10px
}

.chat-box.timeline .content {
    *zoom: 1;
    border-top: 1px solid #ccc;
    background: #f8f8f8;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px
}

.chat-box.timeline .content:before, .chat-box.timeline .content:after {
    display: table;
    content: "";
    line-height: 0
}

.chat-box.timeline .content:after {
    clear: both
}

.chat-box.timeline blockquote {
    margin: 10px 0
}

.chat-box.timeline .chat-message-box {
    display: inline-block;
    min-width: 100px;
    margin-left: 64px;
    padding: 3px 10px;
    border-radius: 10px
}

.chat-box.timeline + .closable-chat-box {
    margin-left: 65px
}

.chat-box {
    list-style: none;
    margin: 0;
    font-size: 11px;
    line-height: 20px;
    color: #5f5f5f
}

.chat-box .avatar {
    position: absolute;
    top: 0
}

.chat-box .arrow-box-left, .chat-box .arrow-box-right {
    min-height: 40px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px 10px
}

.chat-box .arrow-box-left {
    margin-left: 65px;
    margin-right: 15px
}

.chat-box .arrow-box-left .avatar {
    left: -50px
}

.chat-box .arrow-box-left.gray .info .name strong {
    color: #393939
}

.chat-box .arrow-box-right {
    margin-right: 65px;
    margin-left: 15px
}

.chat-box .arrow-box-right .avatar {
    right: -50px
}

.chat-box .arrow-box-right.gray .info .name strong {
    color: #393939
}

.chat-box .info {
    *zoom: 1;
    color: #949494;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px
}

.chat-box .info:before, .chat-box .info:after {
    display: table;
    content: "";
    line-height: 0
}

.chat-box .info:after {
    clear: both
}

.chat-box .info .name {
    float: left
}

.chat-box .info .name strong {
    color: #2c596d;
    margin-right: 10px
}

.chat-box .info .time {
    float: right
}

table.dataTable {
    margin: 0 auto;
    clear: both;
    width: 100%
}

table.dataTable thead th {
    padding: 0;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
    *cursor: hand;
    font-size: 12px;
    font-weight: 600;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 1px 0 white inset;
    -moz-box-shadow: 0 1px 0 white inset;
    box-shadow: 0 1px 0 white inset
}

table.dataTable tfoot th {
    padding: 3px 18px 3px 10px;
    font-weight: 600
}

table.dataTable td {
    padding: 7px 11px
}

table.dataTable td.center, table.dataTable td.dataTables_empty {
    text-align: center
}

table.dataTable tbody tr td {
    border-left: 1px solid #eaebef
}

table.dataTable thead tr th {
    border-left: 1px solid #b5b7c3;
    border-bottom: 1px solid #b5b7c3;
    border-top: 1px solid #c3c5ce
}

table.dataTable tbody tr td:first-child {
    border-left: none
}

table.dataTable thead tr {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea)
}

table.dataTable thead tr th:first-child {
    border-left: none
}

table.dataTable thead tr:first-child {
    margin: 0;
    padding: 0;
    border-top: none
}

table.dataTable tr {
    border-top: 1px solid #eaebef
}

table.dataTable tr.even {
    background-color: #F3F4F8
}

.dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1
}

.dataTables_length {
    float: right;
    margin: 0
}

.dataTables_length label {
    line-height: 61px;
    height: 61px;
    margin: 0
}

.dataTables_length select {
    width: auto
}

.dataTables_length div.selector {
    width: auto
}

.entries-per-page {
    float: left;
    margin: 0 15px 0 0
}

.dataTables_filter {
    float: left;
    font-size: 11px;
    text-align: left;
    margin: 0;
    position: relative
}

.dataTables_filter label {
    line-height: 61px;
    height: 61px;
    margin-bottom: 0
}

.dataTables_filter label > span {
    display: block;
    float: left;
    padding-top: 1px
}

.dataTables_filter input[type=text] {
    height: auto;
    padding: 2px 20px 2px 7px;
    margin-left: 15px;
    margin-bottom: 0;
    width: 180px;
    background: #f9f9f9;
    border: 1px solid #d7d7d7
}

.dataTables_info {
    clear: both;
    float: left;
    padding: 10px 0
}

.table-footer {
    clear: both;
    padding: 8px 12px;
    height: 36px;
    font-size: 11px;
    color: #666;
    border-top: 1px solid #cdcdcd;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background: #fdfdfd;
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea)
}

.table-header {
    border-bottom: 1px solid #eaebef;
    height: 61px;
    -webkit-box-shadow: 0 1px 0 white inset;
    -moz-box-shadow: 0 1px 0 white inset;
    box-shadow: 0 1px 0 white inset;
    clear: both;
    padding: 0 16px;
    font-size: 11px;
    color: #666;
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea)
}

.dataTables_paginate {
    float: right;
    margin-top: 7px
}

.paging_full_numbers {
    height: 22px;
    line-height: 22px
}

.paging_full_numbers a.paginate_button, .paging_full_numbers a.paginate_active {
    border-top: 1px solid #E2E2E2;
    border-left: 1px solid #C9C9C9;
    border-right: 1px solid #C9C9C9;
    border-bottom: 1px solid #AEAEAE;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    border-radius: 3px;
    padding: 4px 7px;
    margin: 0 3px;
    cursor: pointer;
    color: #666;
    font-weight: 600;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    font-size: 11px;
    -webkit-box-shadow: 0 1px 0 white inset;
    -moz-box-shadow: 0 1px 0 white inset;
    box-shadow: 0 1px 0 white inset
}

.paging_full_numbers a.paginate_button {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea)
}

.paging_full_numbers a.paginate_button:hover {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YxZjFmMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f1f1f1), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #f1f1f1, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #f1f1f1, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #f1f1f1, #eaeaea);
    background-image: linear-gradient(to bottom, #f1f1f1, #eaeaea)
}

.paging_full_numbers a.paginate_active, .paging_full_numbers a.paginate_button:active {
    border: 1px solid #AEAEAE;
    -webkit-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 white;
    -moz-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 white;
    box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 white
}

.paging_full_numbers a.paginate_button_disabled {
    background: #eee;
    color: #c5c5c5;
    box-shadow: none;
    border: 1px solid #E2E2E2
}

.paging_full_numbers a.paginate_button_disabled:active, .paging_full_numbers a.paginate_button_disabled:hover {
    background: #eee;
    border: 1px solid #E2E2E2;
    box-shadow: none
}

.sorting, .sorting_asc, .sorting_desc {
    position: relative
}

.sorting > div, .sorting_asc > div, .sorting_desc > div {
    position: relative;
    height: 20px;
    line-height: 20px
}

.sorting > div:after, .sorting_asc > div:after, .sorting_desc > div:after {
    font-family: FontAwesome;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    position: absolute;
    width: 18px;
    height: 18px;
    pointer-events: none;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    color: #888;
    color: #666666;
    line-height: 14px;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 3px;
    top: 3px
}

.sorting_asc, .sorting_desc {
    background: #f2f3f7;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y3ZjhmYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YyZjNmNyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f7f8fc), color-stop(100%, #f2f3f7));
    background-image: -webkit-linear-gradient(to bottom, #f7f8fc, #f2f3f7);
    background-image: -moz-linear-gradient(to bottom, #f7f8fc, #f2f3f7);
    background-image: -o-linear-gradient(to bottom, #f7f8fc, #f2f3f7);
    background-image: linear-gradient(to bottom, #f7f8fc, #f2f3f7)
}

.sorting > div:after {
    content: "\f0dc"
}

.sorting_asc > div:after {
    content: "\f0de";
    top: 7px
}

.sorting_desc > div:after {
    content: "\f0dd";
    top: 1px
}

table.dataTable th:active {
    outline: none
}

.dataTables_scroll {
    clear: both
}

.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch
}

.accordion {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.accordion-group {
    margin-bottom: 0;
    border: 1px solid #cdcdcd;
    border-bottom: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    margin-top: -1px;
    border-top: none
}

.accordion-group .accordion-heading {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea);
    text-shadow: 0 1px white;
    border-bottom: 1px solid #CDCDCD;
    color: #636363;
    font-weight: 600;
    border-top: 1px solid #cdcdcd
}

.accordion-group:first-child {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 0;
    border-top: none
}

.accordion-group:first-child .accordion-heading {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px
}

.accordion-group:last-child {
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid #cdcdcd
}

.accordion-group .accordion-inner {
    background: #fbfbfb;
    margin-bottom: 1px
}

.accordion-heading .accordion-toggle {
    padding: 10px;
    color: #636363
}

.accordion-inner .nav {
    margin-bottom: 0
}

.accordion-inner .nav li {
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #ddd
}

.accordion-inner .nav li a {
    padding-left: 10px
}

.accordion-inner .nav li:last-child {
    border-bottom: none
}

.sidebar-tabs .accordion {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.sidebar-tabs .accordion .active {
    border-left: 6px solid #505050;
    margin-left: -3px
}

.sidebar-tabs .accordion .active a {
    padding-left: 7px;
    color: #505050
}

.sidebar-tabs .accordion-group {
    border-left: none;
    border-right: none
}

.sidebar-tabs .accordion-group:first-child, .sidebar-tabs .accordion-group:first-child .accordion-heading, .sidebar-tabs .accordion-group:last-child {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.sidebar-tabs .accordion-group:last-child {
    border-bottom: none
}

.sidebar-tabs .accordion-heading .accordion-toggle {
    padding: 15px
}

.alert {
    border: 1px solid #f1d276;
    color: #a37f11;
    background: #f6e1a4;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y4ZTliYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y2ZTFhNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f8e9bb), color-stop(100%, #f6e1a4));
    background-image: -webkit-linear-gradient(to bottom, #f8e9bb, #f6e1a4);
    background-image: -moz-linear-gradient(to bottom, #f8e9bb, #f6e1a4);
    background-image: -o-linear-gradient(to bottom, #f8e9bb, #f6e1a4);
    background-image: linear-gradient(to bottom, #f8e9bb, #f6e1a4);
    -webkit-box-shadow: 0 1px 2px #f9edc9 inset, 0 1px 1px #dfdfdf;
    -moz-box-shadow: 0 1px 2px #f9edc9 inset, 0 1px 1px #dfdfdf;
    box-shadow: 0 1px 2px #f9edc9 inset, 0 1px 1px #dfdfdf
}

.alert-info {
    border: 1px solid #a8c3dc;
    color: #3c6a95;
    background: #ccdcea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RlZThmMiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2NjZGNlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #dee8f2), color-stop(100%, #ccdcea));
    background-image: -webkit-linear-gradient(to bottom, #dee8f2, #ccdcea);
    background-image: -moz-linear-gradient(to bottom, #dee8f2, #ccdcea);
    background-image: -o-linear-gradient(to bottom, #dee8f2, #ccdcea);
    background-image: linear-gradient(to bottom, #dee8f2, #ccdcea);
    -webkit-box-shadow: 0 1px 2px #e9f0f6 inset, 0 1px 1px #dfdfdf;
    -moz-box-shadow: 0 1px 2px #e9f0f6 inset, 0 1px 1px #dfdfdf;
    box-shadow: 0 1px 2px #e9f0f6 inset, 0 1px 1px #dfdfdf
}

.alert-danger, .alert-error {
    border: 1px solid #de7e6d;
    color: #7d2a1c;
    background: #e8a397;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VjYjVhYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2U4YTM5NyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #ecb5ac), color-stop(100%, #e8a397));
    background-image: -webkit-linear-gradient(to bottom, #ecb5ac, #e8a397);
    background-image: -moz-linear-gradient(to bottom, #ecb5ac, #e8a397);
    background-image: -o-linear-gradient(to bottom, #ecb5ac, #e8a397);
    background-image: linear-gradient(to bottom, #ecb5ac, #e8a397);
    -webkit-box-shadow: 0 1px 2px #efc0b8 inset, 0 1px 1px #dfdfdf;
    -moz-box-shadow: 0 1px 2px #efc0b8 inset, 0 1px 1px #dfdfdf;
    box-shadow: 0 1px 2px #efc0b8 inset, 0 1px 1px #dfdfdf
}

.alert-success {
    border: 1px solid #97cc2e;
    color: #2b3a0d;
    background: #acd954;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2I2ZGQ2OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2FjZDk1NCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #b6dd69), color-stop(100%, #acd954));
    background-image: -webkit-linear-gradient(to bottom, #b6dd69, #acd954);
    background-image: -moz-linear-gradient(to bottom, #b6dd69, #acd954);
    background-image: -o-linear-gradient(to bottom, #b6dd69, #acd954);
    background-image: linear-gradient(to bottom, #b6dd69, #acd954);
    -webkit-box-shadow: 0 1px 2px #bce075 inset, 0 1px 1px #dfdfdf;
    -moz-box-shadow: 0 1px 2px #bce075 inset, 0 1px 1px #dfdfdf;
    box-shadow: 0 1px 2px #bce075 inset, 0 1px 1px #dfdfdf
}

.pagination > ul {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.pagination-large ul > li > a, .pagination-large ul > li > span {
    padding: 9px 16px
}

.pagination > ul > li > a {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea);
    border-top: 1px solid #E2E2E2;
    border-left: 1px solid #C9C9C9;
    border-right: 1px solid #C9C9C9;
    border-bottom: 1px solid #AEAEAE;
    font-weight: 600;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    margin: 0 1px;
    cursor: pointer;
    color: #666;
    -webkit-box-shadow: 0 1px 0 white inset;
    -moz-box-shadow: 0 1px 0 white inset;
    box-shadow: 0 1px 0 white inset
}

.pagination > ul > li > a:hover {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YxZjFmMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #f1f1f1), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #f1f1f1, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #f1f1f1, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #f1f1f1, #eaeaea);
    background-image: linear-gradient(to bottom, #f1f1f1, #eaeaea)
}

.pagination > ul > li.active a, .pagination > ul > li > a:active, .pagination > ul > li.active:hover a {
    color: #666;
    border: 1px solid #AEAEAE;
    -webkit-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 white;
    -moz-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 white;
    box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 white;
    text-shadow: none
}

.modal-body {
    background: #f5f5f5
}

.modal {
    border: 3px solid #808080
}

.modal-body .box-flexbox {
    margin: 10px;
}

.modal-header, .modal-footer {
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea)
}

.modal-header h1, .modal-header h2, .modal-header h3, .modal-header h4, .modal-header h5, .modal-header h6, .modal-footer h1, .modal-footer h2, .modal-footer h3, .modal-footer h4, .modal-footer h5, .modal-footer h6 {
    margin: 0
}

.modal-header {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px
}

.modal-header {
    border-bottom: 1px solid #CDCDCD
}

#galleryOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 100000;
    background-color: #222;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    display: none;
    -moz-transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease
}

#galleryOverlay.visible {
    opacity: 1
}

#gallerySlider {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    white-space: nowrap;
    position: absolute;
    -moz-transition: left .4s ease;
    -webkit-transition: left .4s ease;
    transition: left .4s ease
}

#gallerySlider .placeholder {
    /*background: url("loading.gif") no-repeat center center;*/
    height: 100%;
    line-height: 1px;
    text-align: center;
    width: 100%;
    display: inline-block
}

#gallerySlider .placeholder:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px
}

#gallerySlider .placeholder img {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle
}

#gallerySlider.rightSpring {
    -moz-animation: rightSpring .3s;
    -webkit-animation: rightSpring .3s
}

#gallerySlider.leftSpring {
    -moz-animation: leftSpring .3s;
    -webkit-animation: leftSpring .3s
}

@-moz-keyframes rightSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: -30px
    }
    100% {
        margin-left: 0
    }
}

@-moz-keyframes leftSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: 30px
    }
    100% {
        margin-left: 0
    }
}

@-webkit-keyframes rightSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: -30px
    }
    100% {
        margin-left: 0
    }
}

@-webkit-keyframes leftSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: 30px
    }
    100% {
        margin-left: 0
    }
}

#prevArrow, #nextArrow {
    border: none;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.7);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    opacity: .5;
    cursor: pointer;
    position: absolute;
    width: 43px;
    height: 58px;
    top: 50%;
    margin-top: -29px;
    -moz-transition: opacity .2s ease;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease
}

#prevArrow i, #nextArrow i {
    height: 55px;
    line-height: 55px;
    width: 100%;
    text-align: center
}

#prevArrow:hover, #nextArrow:hover {
    opacity: 1
}

#prevArrow {
    left: 40px
}

#nextArrow {
    right: 40px
}

#thumbs {
    margin: 60px auto 35px;
    text-align: center
}

#thumbs a {
    width: 120px;
    height: 120px;
    display: inline-block;
    border: 7px solid #303030;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin: 6px 6px 40px;
    position: relative;
    text-decoration: none;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover
}

#thumbs a:after {
    background-color: #303030;
    border-radius: 7px;
    bottom: -136px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    color: #FFFFFF;
    content: attr(title);
    display: inline-block;
    font-size: 10px;
    max-width: 90px;
    overflow: hidden;
    padding: 2px 10px;
    position: relative;
    text-align: center;
    white-space: nowrap
}

#credit {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    font-size: 11px;
    margin: 0 auto 70px;
    opacity: .5;
    padding: 12px 16px;
    text-align: center;
    width: 400px
}

.table-normal {
    margin-bottom: 0;
    color: #5a6573
}

.table-normal tbody td, .table-normal thead td {
    border-left: 1px solid #eaebef
}

.table-normal tbody td:first-child, .table-normal thead td:first-child {
    border-left: none
}

.table-normal tbody td {
    border-top: 1px solid #eaebef
}

.table-normal thead td {
    text-align: center;
    font-size: 11px;
    padding: 3px 5px 2px 5px;
    color: #666;
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    font-size: 12px;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    background: #eaeaea;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #eaeaea));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #eaeaea);
    background-image: linear-gradient(to bottom, #fdfdfd, #eaeaea);
    border-left: 1px solid #d1d2da;
    border-right: 1px solid #d1d2da;
    border-bottom: 1px solid #d1d2da
}

.table-normal thead td:first-child {
    border-left: none
}

.table-normal thead td:last-child {
    border-right: none
}

.table-normal tbody td {
    padding: 7px 11px;
    vertical-align: middle
}

.table-normal tbody tr {
    border-top: 1px solid #eaebef
}

.table-normal tbody tr:nth-child(even) {
    background: #F3F4F8
}

.table-normal .icon {
    width: 30px;
    text-align: center
}

.scrollable::-webkit-scrollbar {
    height: 16px;
    width: 10px
}

.scrollable::-webkit-scrollbar-button:start:decrement, .scrollable::-webkit-scrollbar-button:end:increment {
    background-color: transparent;
    display: block;
    height: 0
}

.scrollable::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0 0 0 4px
}

.scrollable::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.scrollable::-webkit-scrollbar:hover {
    background-color: #f3f3f3;
    border: 1px solid #dbdbdb
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: solid transparent;
    border-width: 0;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    background-clip: padding-box
}

.scrollable::-webkit-scrollbar-thumb:vertical, .scrollable::-webkit-scrollbar-thumb:horizontal {
    background-color: #c6c6c6;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #949494
}

.scrollable::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.35);
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.35)
}

.news {
    min-height: 85px
}

.news .avatar {
    float: left;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%
}

.news .avatar.blue {
    background: #7fb3d4;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2E1Y2FlMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzdmYjNkNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #a5cae1), color-stop(100%, #7fb3d4));
    background-image: -webkit-linear-gradient(to bottom, #a5cae1, #7fb3d4);
    background-image: -moz-linear-gradient(to bottom, #a5cae1, #7fb3d4);
    background-image: -o-linear-gradient(to bottom, #a5cae1, #7fb3d4);
    background-image: linear-gradient(to bottom, #a5cae1, #7fb3d4);
    border: 1px solid #60a1ca
}

.news .avatar.green {
    background: #8cc079;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2FhZDA5YyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzhjYzA3OSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #aad09c), color-stop(100%, #8cc079));
    background-image: -webkit-linear-gradient(to bottom, #aad09c, #8cc079);
    background-image: -moz-linear-gradient(to bottom, #aad09c, #8cc079);
    background-image: -o-linear-gradient(to bottom, #aad09c, #8cc079);
    background-image: linear-gradient(to bottom, #aad09c, #8cc079);
    border: 1px solid #74b35d
}

.news .avatar.cyan {
    background: #81babd;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2EyY2NjZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzgxYmFiZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #a2cccf), color-stop(100%, #81babd));
    background-image: -webkit-linear-gradient(to bottom, #a2cccf, #81babd);
    background-image: -moz-linear-gradient(to bottom, #a2cccf, #81babd);
    background-image: -o-linear-gradient(to bottom, #a2cccf, #81babd);
    background-image: linear-gradient(to bottom, #a2cccf, #81babd);
    border: 1px solid #66abaf
}

.news .avatar.purple {
    background: #aea7cf;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NjYzhlMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2FlYTdjZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #ccc8e1), color-stop(100%, #aea7cf));
    background-image: -webkit-linear-gradient(to bottom, #ccc8e1, #aea7cf);
    background-image: -moz-linear-gradient(to bottom, #ccc8e1, #aea7cf);
    background-image: -o-linear-gradient(to bottom, #ccc8e1, #aea7cf);
    background-image: linear-gradient(to bottom, #ccc8e1, #aea7cf);
    border: 1px solid #968dc1
}

.news .avatar i {
    color: white;
    line-height: 36px
}

.news.with-icons .news-content {
    margin-left: 55px
}

.news-content {
    margin-left: 50px;
    margin-right: 50px
}

.news-title {
    color: #636364;
    font-weight: 600;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    font-size: 16px
}

.news-time {
    float: right;
    color: #bbb;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    font-size: 14px;
    text-align: center
}

.news-time span {
    display: block;
    font-size: 24px;
    font-weight: 600
}

.badge-blue, .badge-gray, .badge-green, .badge-red, .badge-dark-red, .badge-black, .badge-cyan, .badge-purple {
    display: inline-block;
    padding: 0 4px;
    border-radius: 10px;
    height: 14px;
    min-width: 14px;
    line-height: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    color: #fff
}

.label, .badge {
    font-weight: 600
}

.label-blue {
    background: #599cc7;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdmYjNkNCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzU5OWNjNyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #7fb3d4), color-stop(100%, #599cc7));
    background-image: -webkit-linear-gradient(to bottom, #7fb3d4, #599cc7);
    background-image: -moz-linear-gradient(to bottom, #7fb3d4, #599cc7);
    background-image: -o-linear-gradient(to bottom, #7fb3d4, #599cc7);
    background-image: linear-gradient(to bottom, #7fb3d4, #599cc7);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #70aacf;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #70aacf;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #70aacf
}

.label-gray {
    background: #5d5d5d;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzc2NzY3NiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzVkNWQ1ZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #767676), color-stop(100%, #5d5d5d));
    background-image: -webkit-linear-gradient(to bottom, #767676, #5d5d5d);
    background-image: -moz-linear-gradient(to bottom, #767676, #5d5d5d);
    background-image: -o-linear-gradient(to bottom, #767676, #5d5d5d);
    background-image: linear-gradient(to bottom, #767676, #5d5d5d);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #6c6c6c;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #6c6c6c;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #6c6c6c
}

.label-green {
    background: #6eb056;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhjYzA3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzZlYjA1NiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #8cc079), color-stop(100%, #6eb056));
    background-image: -webkit-linear-gradient(to bottom, #8cc079, #6eb056);
    background-image: -moz-linear-gradient(to bottom, #8cc079, #6eb056);
    background-image: -o-linear-gradient(to bottom, #8cc079, #6eb056);
    background-image: linear-gradient(to bottom, #8cc079, #6eb056);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #80b96b;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #80b96b;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #80b96b
}

.label-red {
    background: #964747;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2IzNWQ1ZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzk2NDc0NyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #b35d5d), color-stop(100%, #964747));
    background-image: -webkit-linear-gradient(to bottom, #b35d5d, #964747);
    background-image: -moz-linear-gradient(to bottom, #b35d5d, #964747);
    background-image: -o-linear-gradient(to bottom, #b35d5d, #964747);
    background-image: linear-gradient(to bottom, #b35d5d, #964747);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #ab5050;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #ab5050;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #ab5050
}

.label-dark-red {
    background: #8f3831;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2I1NDczZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzhmMzgzMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #b5473e), color-stop(100%, #8f3831));
    background-image: -webkit-linear-gradient(to bottom, #b5473e, #8f3831);
    background-image: -moz-linear-gradient(to bottom, #b5473e, #8f3831);
    background-image: -o-linear-gradient(to bottom, #b5473e, #8f3831);
    background-image: linear-gradient(to bottom, #b5473e, #8f3831);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #a64139;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #a64139;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #a64139
}

.label-black {
    background: #4d4d4d;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY2NjY2NiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRkNGQ0ZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #666666), color-stop(100%, #4d4d4d));
    background-image: -webkit-linear-gradient(to bottom, #666666, #4d4d4d);
    background-image: -moz-linear-gradient(to bottom, #666666, #4d4d4d);
    background-image: -o-linear-gradient(to bottom, #666666, #4d4d4d);
    background-image: linear-gradient(to bottom, #666666, #4d4d4d);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #5c5c5c;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #5c5c5c;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #5c5c5c
}

.label-cyan {
    background: #60a8ab;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgxYmFiZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzYwYThhYiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #81babd), color-stop(100%, #60a8ab));
    background-image: -webkit-linear-gradient(to bottom, #81babd, #60a8ab);
    background-image: -moz-linear-gradient(to bottom, #81babd, #60a8ab);
    background-image: -o-linear-gradient(to bottom, #81babd, #60a8ab);
    background-image: linear-gradient(to bottom, #81babd, #60a8ab);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #74b3b6;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #74b3b6;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #74b3b6
}

.label-purple {
    background: #9086bd;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2FlYTdjZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzkwODZiZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #aea7cf), color-stop(100%, #9086bd));
    background-image: -webkit-linear-gradient(to bottom, #aea7cf, #9086bd);
    background-image: -moz-linear-gradient(to bottom, #aea7cf, #9086bd);
    background-image: -o-linear-gradient(to bottom, #aea7cf, #9086bd);
    background-image: linear-gradient(to bottom, #aea7cf, #9086bd);
    -webkit-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #a29ac8;
    -moz-box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #a29ac8;
    box-shadow: 0 1px 0 #f7f7f7, inset 0 1px 1px #a29ac8
}

.badge-blue {
    background: #6ca8ce;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdmYjNkNCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzZjYThjZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #7fb3d4), color-stop(100%, #6ca8ce));
    background-image: -webkit-linear-gradient(to bottom, #7fb3d4, #6ca8ce);
    background-image: -moz-linear-gradient(to bottom, #7fb3d4, #6ca8ce);
    background-image: -o-linear-gradient(to bottom, #7fb3d4, #6ca8ce);
    background-image: linear-gradient(to bottom, #7fb3d4, #6ca8ce);
    -webkit-box-shadow: 0 0 0 1px #3c84b1;
    -moz-box-shadow: 0 0 0 1px #3c84b1;
    box-shadow: 0 0 0 1px #3c84b1
}

.badge-gray {
    background: dimgrey;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzc2NzY3NiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY5Njk2OSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #767676), color-stop(100%, #696969));
    background-image: -webkit-linear-gradient(to bottom, #767676, #696969);
    background-image: -moz-linear-gradient(to bottom, #767676, #696969);
    background-image: -o-linear-gradient(to bottom, #767676, #696969);
    background-image: linear-gradient(to bottom, #767676, #696969);
    -webkit-box-shadow: 0 0 0 1px #434343;
    -moz-box-shadow: 0 0 0 1px #434343;
    box-shadow: 0 0 0 1px #434343
}

.badge-green {
    background: #7db868;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhjYzA3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzdkYjg2OCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #8cc079), color-stop(100%, #7db868));
    background-image: -webkit-linear-gradient(to bottom, #8cc079, #7db868);
    background-image: -moz-linear-gradient(to bottom, #8cc079, #7db868);
    background-image: -o-linear-gradient(to bottom, #8cc079, #7db868);
    background-image: linear-gradient(to bottom, #8cc079, #7db868);
    -webkit-box-shadow: 0 0 0 1px #589043;
    -moz-box-shadow: 0 0 0 1px #589043;
    box-shadow: 0 0 0 1px #589043
}

.badge-red {
    background: #a84f4f;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2IzNWQ1ZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2E4NGY0ZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #b35d5d), color-stop(100%, #a84f4f));
    background-image: -webkit-linear-gradient(to bottom, #b35d5d, #a84f4f);
    background-image: -moz-linear-gradient(to bottom, #b35d5d, #a84f4f);
    background-image: -o-linear-gradient(to bottom, #b35d5d, #a84f4f);
    background-image: linear-gradient(to bottom, #b35d5d, #a84f4f);
    -webkit-box-shadow: 0 0 0 1px #743636;
    -moz-box-shadow: 0 0 0 1px #743636;
    box-shadow: 0 0 0 1px #743636
}

.badge-dark-red {
    background: #a24037;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2I1NDczZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2EyNDAzNyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #b5473e), color-stop(100%, #a24037));
    background-image: -webkit-linear-gradient(to bottom, #b5473e, #a24037);
    background-image: -moz-linear-gradient(to bottom, #b5473e, #a24037);
    background-image: -o-linear-gradient(to bottom, #b5473e, #a24037);
    background-image: linear-gradient(to bottom, #b5473e, #a24037);
    -webkit-box-shadow: 0 0 0 1px #692924;
    -moz-box-shadow: 0 0 0 1px #692924;
    box-shadow: 0 0 0 1px #692924
}

.badge-black {
    background: #595959;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY2NjY2NiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzU5NTk1OSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #666666), color-stop(100%, #595959));
    background-image: -webkit-linear-gradient(to bottom, #666666, #595959);
    background-image: -moz-linear-gradient(to bottom, #666666, #595959);
    background-image: -o-linear-gradient(to bottom, #666666, #595959);
    background-image: linear-gradient(to bottom, #666666, #595959);
    -webkit-box-shadow: 0 0 0 1px #333333;
    -moz-box-shadow: 0 0 0 1px #333333;
    box-shadow: 0 0 0 1px #333333
}

.badge-cyan {
    background: #70b1b4;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgxYmFiZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzcwYjFiNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #81babd), color-stop(100%, #70b1b4));
    background-image: -webkit-linear-gradient(to bottom, #81babd, #70b1b4);
    background-image: -moz-linear-gradient(to bottom, #81babd, #70b1b4);
    background-image: -o-linear-gradient(to bottom, #81babd, #70b1b4);
    background-image: linear-gradient(to bottom, #81babd, #70b1b4);
    -webkit-box-shadow: 0 0 0 1px #4a8a8e;
    -moz-box-shadow: 0 0 0 1px #4a8a8e;
    box-shadow: 0 0 0 1px #4a8a8e
}

.badge-purple {
    background: #9f97c6;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2FlYTdjZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzlmOTdjNiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #aea7cf), color-stop(100%, #9f97c6));
    background-image: -webkit-linear-gradient(to bottom, #aea7cf, #9f97c6);
    background-image: -moz-linear-gradient(to bottom, #aea7cf, #9f97c6);
    background-image: -o-linear-gradient(to bottom, #aea7cf, #9f97c6);
    background-image: linear-gradient(to bottom, #aea7cf, #9f97c6);
    -webkit-box-shadow: 0 0 0 1px #7165ab;
    -moz-box-shadow: 0 0 0 1px #7165ab;
    box-shadow: 0 0 0 1px #7165ab
}

.badge-flat {
    text-shadow: none
}

.wizard {
    display: none
}

.wizard-modal form {
    margin: 0;
    padding: 0
}

.wizard-modal.modal {
    width: 750px;
    margin-left: -375px;
    top: 50%
}

.wizard-modal-footer {
    padding: 0;
    text-align: right
}

.wizard-modal-header.modal-header h3 {
    line-height: 35px;
    display: inline
}

.wizard-modal-header.modal-header {
    border-bottom: 0
}

.wizard-subtitle {
    font-weight: 700;
    color: #AFAFAF;
    padding-left: 20px
}

.wizard-error, .wizard-failure, .wizard-success, .wizard-loading, .wizard-card {
    position: relative;
    padding: 35px;
    padding-top: 20px;
    overflow-y: auto;
    height: 300px;
    display: none;
    border-top: 1px solid #EEE;
    margin-right: 5px
}

.wizard-nav-link .icon-chevron-right {
    float: right;
    margin-top: 12px;
    margin-right: -6px;
    opacity: .25
}

li.wizard-nav-item.active .icon-chevron-right {
    opacity: 1
}

li.wizard-nav-item {
    line-height: 40px
}

.wizard-modal.modal .nav-list > li > a {
    background-color: #f5f5f5;
    padding: 3px 15px 3px 20px;
    cursor: default;
    color: #B4B4B4
}

.wizard-modal.modal .nav-list li.active > a {
    background-color: #08C
}

.wizard-modal.modal .nav-list > li.already-visited > a.wizard-nav-link {
    color: #08C;
    cursor: pointer
}

.wizard-modal.modal .nav-list > li.active > a.wizard-nav-link {
    color: white
}

.already-visited > a.wizard-nav-link:hover {
    background-color: #E4E4E4
}

.wizard-card > h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 40px;
    font-weight: 400
}

.wizard-progress {
    padding: 15px;
    bottom: 0
}

.wizard-progress-container {
    padding: 20px
}

.wizard-steps {
    width: 28%;
    height: 425px;
    background-color: #f5f5f5
}

.wizard-nav-container {
    height: 360px
}

.nav > li > a.wizard-step-error {
    background-color: #F2DEDE;
    color: #B94A48;
    font-weight: 700
}

.wizard-step-error .icon-chevron-right {
    opacity: 0
}

.wizard-input-section {
    margin-bottom: 20px
}

.wizard-buttons-container {
    padding: 20px
}

.wizard-modal .popover.error-popover {
    background-color: #F2DEDE;
    color: #B94A48;
    border-color: #953B39
}

.wizard-modal .popover.error-popover .arrow::after {
    border-right-color: #F2DEDE
}

.wizard-modal .popover.error-popover .popover-title {
    display: none
}

.wizard-modal .popover.error-popover .arrow {
    border-right-color: #953B39
}

.error-box {
    margin-top: 100px;
    color: rgba(0, 0, 0, 0.2);
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6), 0 0 1px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-weight: 600
}

.error-box .message-small {
    font-size: 25px
}

.error-box .message-big {
    font-size: 160px
}

.textarea-html5 {
    margin: 0;
    padding: 10px;
    padding-top: 0;
    width: 100%;
    height: 190px;
    border-radius: 4px
}

.wysihtml5-sandbox {
    width: 100% !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    border: none !important;
    box-shadow: none !important;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    background: #f7f7f7
}

.closable-chat-box .wysihtml5-sandbox {
    width: 97% !important
}

ul.wysihtml5-toolbar {
    margin: 0;
    padding: 10px;
    display: block
}

ul.wysihtml5-toolbar::after {
    clear: both;
    display: table;
    content: ""
}

ul.wysihtml5-toolbar > li {
    float: left;
    display: list-item;
    list-style: none;
    margin: 0 5px 10px 0
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=bold] {
    font-weight: 600
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=italic] {
    font-style: italic
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=underline] {
    text-decoration: underline
}

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
    background-image: none;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: #eaeaea;
    background-color: #D9D9D9;
    outline: 0
}

ul.wysihtml5-commands-disabled .dropdown-menu {
    display: none !important
}

ul.wysihtml5-toolbar div.wysihtml5-colors {
    display: block;
    width: 50px;
    height: 20px;
    margin-top: 2px;
    margin-left: 5px;
    position: absolute;
    pointer-events: none
}

ul.wysihtml5-toolbar a.wysihtml5-colors-title {
    padding-left: 70px
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="black"] {
    background: black !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="silver"] {
    background: silver !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="gray"] {
    background: gray !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="maroon"] {
    background: maroon !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="red"] {
    background: red !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="purple"] {
    background: purple !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="green"] {
    background: green !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="olive"] {
    background: olive !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="navy"] {
    background: navy !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="blue"] {
    background: blue !important
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="orange"] {
    background: orange !important
}

.wysiwyg-color-black {
    color: black
}

.wysiwyg-color-silver {
    color: silver
}

.wysiwyg-color-gray {
    color: gray
}

.wysiwyg-color-white {
    color: white
}

.wysiwyg-color-maroon {
    color: maroon
}

.wysiwyg-color-red {
    color: red
}

.wysiwyg-color-purple {
    color: purple
}

.wysiwyg-color-fuchsia {
    color: fuchsia
}

.wysiwyg-color-green {
    color: green
}

.wysiwyg-color-lime {
    color: lime
}

.wysiwyg-color-olive {
    color: olive
}

.wysiwyg-color-yellow {
    color: yellow
}

.wysiwyg-color-navy {
    color: navy
}

.wysiwyg-color-blue {
    color: blue
}

.wysiwyg-color-teal {
    color: teal
}

.wysiwyg-color-aqua {
    color: aqua
}

.wysiwyg-color-orange {
    color: orange
}

.formError {
    position: relative;
    background: #202020;
    border: 1px solid #cccccc;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.formError:after, .formError:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.formError:after {
    border-top-color: #202020;
    border-width: 7px;
    left: 7px
}

.formError:before {
    border-top-color: #cccccc;
    border-width: 8px;
    left: 6px
}

.formError {
    z-index: 990;
    background: transparent;
    border: none
}

.formError .formErrorContent {
    z-index: 991
}

.formError .formErrorArrow {
    z-index: 996;
    display: none
}

.ui-dialog .formError {
    z-index: 5000
}

.ui-dialog .formError .formErrorContent {
    z-index: 5001
}

.ui-dialog .formError .formErrorArrow {
    z-index: 5006
}

.inputContainer {
    position: relative;
    float: left
}

.formError {
    position: absolute;
    top: 300px;
    left: 300px;
    display: block;
    cursor: pointer;
    text-align: left
}

.formError.inline {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block
}

.ajaxSubmit {
    padding: 20px;
    background: #55ea55;
    border: 1px solid #999;
    display: none
}

.formError .formErrorContent {
    width: 100%;
    background: #202020;
    position: relative;
    color: #fff;
    min-width: 120px;
    font-size: 11px;
    padding: 4px 10px 4px 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px
}

.formError.inline .formErrorContent {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.greenPopup .formErrorContent {
    background: #33be40
}

.blackPopup .formErrorContent {
    background: #393939;
    color: #FFF
}

.formError .formErrorArrow {
    width: 15px;
    margin: -2px 0 0 13px;
    position: relative
}

body[dir='rtl'] .formError .formErrorArrow, body.rtl .formError .formErrorArrow {
    margin: -2px 13px 0 0
}

.formError .formErrorArrowBottom {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -o-box-shadow: none;
    margin: 0 0 0 12px;
    top: 2px
}

.formError .formErrorArrow div {
    border-left: 2px solid #ddd;
    border-right: 2px solid #ddd;
    box-shadow: 0 2px 3px #444;
    -moz-box-shadow: 0 2px 3px #444;
    -webkit-box-shadow: 0 2px 3px #444;
    -o-box-shadow: 0 2px 3px #444;
    font-size: 0;
    height: 1px;
    background: #ee0101;
    margin: 0 auto;
    line-height: 0;
    font-size: 0;
    display: block
}

.formError .formErrorArrowBottom div {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -o-box-shadow: none
}

.greenPopup .formErrorArrow div {
    background: #33be40
}

.blackPopup .formErrorArrow div {
    background: #393939;
    color: #FFF
}

.formError .formErrorArrow .line10 {
    width: 15px;
    border: none
}

.formError .formErrorArrow .line9 {
    width: 13px;
    border: none
}

.formError .formErrorArrow .line8 {
    width: 11px
}

.formError .formErrorArrow .line7 {
    width: 9px
}

.formError .formErrorArrow .line6 {
    width: 7px
}

.formError .formErrorArrow .line5 {
    width: 5px
}

.formError .formErrorArrow .line4 {
    width: 3px
}

.formError .formErrorArrow .line3 {
    width: 1px;
    border-left: 2px solid #ddd;
    border-right: 2px solid #ddd;
    border-bottom: 0 solid #ddd
}

.formError .formErrorArrow .line2 {
    width: 3px;
    border: none;
    background: #ddd
}

.formError .formErrorArrow .line1 {
    width: 1px;
    border: none;
    background: #ddd
}

.editableform {
    margin-bottom: 0
}

.editableform .control-group {
    margin-bottom: 0;
    white-space: nowrap
}

.editable-buttons {
    display: inline-block;
    vertical-align: top;
    margin-left: 7px;
    zoom: 1;
    *display: inline
}

.editable-input {
    vertical-align: top;
    display: inline-block;
    width: auto;
    white-space: normal;
    zoom: 1;
    *display: inline
}

.editable-buttons .editable-cancel {
    margin-left: 7px
}

.editable-buttons button.ui-button-icon-only {
    height: 24px;
    width: 30px
}

.editableform-loading {
    /*background: url("loading.gif") center center no-repeat;*/
    height: 25px;
    width: auto;
    min-width: 25px
}

.editable-inline .editableform-loading {
    background-position: left 5px
}

.editable-error-block {
    max-width: 300px;
    margin: 5px 0 0 0;
    width: auto;
    white-space: normal
}

.editable-error-block.ui-state-error {
    padding: 3px
}

.editable-error {
    color: red
}

.editableform .editable-date {
    padding: 0;
    margin: 0;
    float: left
}

.editable-checklist label {
    white-space: nowrap
}

.editable-checklist label input[type="checkbox"], .editable-checklist label span {
    vertical-align: middle;
    margin: 0
}

.editable-wysihtml5 {
    width: 566px;
    height: 250px
}

.editable-clear {
    clear: both;
    font-size: .9em;
    text-decoration: none;
    text-align: right
}

.editable-clear-x {
    display: block;
    width: 13px;
    height: 13px;
    position: absolute;
    opacity: .6;
    z-index: 100
}

.editable-clear-x:hover {
    opacity: 1
}

.editable-container {
    max-width: none !important
}

.editable-container.popover {
    width: auto
}

.editable-container.editable-inline {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    zoom: 1;
    *display: inline
}

.editable-container.ui-widget {
    font-size: inherit
}

.editable-click {
    text-decoration: none;
    border-bottom: dashed 1px #0088cc
}

a.editable-click {
    text-decoration: none;
    border-bottom: dashed 1px #0088cc
}

a.editable-click:hover {
    text-decoration: none;
    border-bottom: dashed 1px #0088cc
}

.editable-click.editable-disabled {
    color: #585858;
    cursor: default;
    border-bottom: none
}

a.editable-click.editable-disabled {
    color: #585858;
    cursor: default;
    border-bottom: none
}

a.editable-click.editable-disabled:hover {
    color: #585858;
    cursor: default;
    border-bottom: none
}

.editable-empty {
    font-style: italic;
    color: #DD1144;
    border-bottom: none;
    text-decoration: none
}

.editable-empty:hover {
    font-style: italic;
    color: #DD1144;
    border-bottom: none;
    text-decoration: none
}

.editable-unsaved {
    font-weight: 700
}

.editable-unsaved:after {
}

.datepicker {
    padding: 4px;
    margin-top: 1px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    direction: ltr
}

.datepicker-inline {
    width: 220px
}

.datepicker.datepicker-rtl {
    direction: rtl
}

.datepicker.datepicker-rtl table tr td span {
    float: right
}

.datepicker-dropdown {
    top: 0;
    left: 0
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 6px
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    position: absolute;
    top: -6px;
    left: 7px
}

.datepicker > div {
    display: none
}

.datepicker.days div.datepicker-days, .datepicker.months div.datepicker-months, .datepicker.years div.datepicker-years {
    display: block
}

.datepicker table {
    margin: 0
}

.datepicker td, .datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
    background-color: transparent
}

.datepicker table tr td.day:hover {
    background: #eeeeee;
    cursor: pointer
}

.datepicker table tr td.old, .datepicker table tr td.new {
    color: #999999
}

.datepicker table tr td.disabled {
    background: none;
    color: #999999;
    cursor: default
}

.datepicker table tr td.disabled:hover {
    background: none;
    color: #999999;
    cursor: default
}

.datepicker table tr td.today {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.datepicker table tr td.today:hover {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.datepicker table tr td.today.disabled {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.datepicker table tr td.today:hover, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:active, .datepicker table tr td.today:hover:active {
    background-color: #fdf59a
}

.datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover:active {
    background-color: #fdf59a
}

.datepicker table tr td.today.active, .datepicker table tr td.today:hover.active {
    background-color: #fdf59a
}

.datepicker table tr td.today.disabled {
    background-color: #fdf59a
}

.datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:hover.active {
    background-color: #fdf59a
}

.datepicker table tr td.today:hover.disabled {
    background-color: #fdf59a
}

.datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:hover.disabled {
    background-color: #fdf59a
}

.datepicker table tr td.today[disabled], .datepicker table tr td.today:hover[disabled] {
    background-color: #fdf59a
}

.datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a
}

.datepicker table tr td.today:active, .datepicker table tr td.today:hover:active {
    background-color: #fbf069  \9
}

.datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover:active {
    background-color: #fbf069  \9
}

.datepicker table tr td.today.active, .datepicker table tr td.today:hover.active {
    background-color: #fbf069  \9
}

.datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069  \9
}

.datepicker table tr td.active {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td.active:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td.active.disabled {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td.active:hover, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:active, .datepicker table tr td.active:hover:active {
    background-color: #0044cc
}

.datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover:active {
    background-color: #0044cc
}

.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active {
    background-color: #0044cc
}

.datepicker table tr td.active.disabled {
    background-color: #0044cc
}

.datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:hover.active {
    background-color: #0044cc
}

.datepicker table tr td.active:hover.disabled {
    background-color: #0044cc
}

.datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:hover.disabled {
    background-color: #0044cc
}

.datepicker table tr td.active[disabled], .datepicker table tr td.active:hover[disabled] {
    background-color: #0044cc
}

.datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #0044cc
}

.datepicker table tr td.active:active, .datepicker table tr td.active:hover:active {
    background-color: #003399  \9
}

.datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover:active {
    background-color: #003399  \9
}

.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active {
    background-color: #003399  \9
}

.datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:hover.active {
    background-color: #003399  \9
}

.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.datepicker table tr td span:hover {
    background: #eeeeee
}

.datepicker table tr td span.disabled {
    background: none;
    color: #999999;
    cursor: default
}

.datepicker table tr td span.disabled:hover {
    background: none;
    color: #999999;
    cursor: default
}

.datepicker table tr td span.active {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td span.active:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td span.active.disabled {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td span.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active {
    background-color: #0044cc
}

.datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active {
    background-color: #0044cc
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active {
    background-color: #0044cc
}

.datepicker table tr td span.active.disabled {
    background-color: #0044cc
}

.datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active {
    background-color: #0044cc
}

.datepicker table tr td span.active:hover.disabled {
    background-color: #0044cc
}

.datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:hover.disabled {
    background-color: #0044cc
}

.datepicker table tr td span.active[disabled], .datepicker table tr td span.active:hover[disabled] {
    background-color: #0044cc
}

.datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #0044cc
}

.datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active {
    background-color: #003399  \9
}

.datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active {
    background-color: #003399  \9
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active {
    background-color: #003399  \9
}

.datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active {
    background-color: #003399  \9
}

.datepicker table tr td span.old {
    color: #999999
}

.datepicker th.switch {
    width: 145px
}

.datepicker thead tr:first-child th, .datepicker tfoot tr:first-child th {
    cursor: pointer
}

.datepicker thead tr:first-child th:hover, .datepicker tfoot tr:first-child th:hover {
    background: #eeeeee
}

.input-append.date .add-on i, .input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px
}

.datepicker table {
    width: 100%;
    border: 1px solid #CDCDCD;
    background: #fafafa;
    -webkit-box-shadow: 0 2px 2px -2px #cccccc;
    -moz-box-shadow: 0 2px 2px -2px #cccccc;
    box-shadow: 0 2px 2px -2px #cccccc
}

.datepicker table thead tr:first-child {
    background: #ececec;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #ececec));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #ececec);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #ececec);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #ececec);
    background-image: linear-gradient(to bottom, #fdfdfd, #ececec);
    border-bottom: 1px solid #CDCDCD
}

.datepicker table thead tr:first-child th {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0
}

.datepicker table thead tr:first-child th:hover {
    background: #ececec;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #ececec));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #ececec);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #ececec);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #ececec);
    background-image: linear-gradient(to bottom, #fdfdfd, #ececec);
    color: #404040
}

.datepicker table tr td.day:hover, .datepicker table tr td span:hover {
    background: #32779a;
    color: #fff
}

.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.active {
    background: #519FC7;
    color: #fff
}

.datepicker-inline {
    width: auto
}

@media (max-width:979px) {
    .sidebar-background {
        display: none
    }

    .primary-sidebar {
        *zoom: 1;
        position: static;
        width: auto;
        float: none;
        background: #11161A
    }

    .primary-sidebar:before, .primary-sidebar:after {
        display: table;
        content: "";
        line-height: 0
    }

    .primary-sidebar:after {
        clear: both
    }

    .main-content {
        margin: 0
    }

    .user-avatar > .dropdown-toggle {
        display: none
    }

    .dropdown-menu li.with-image span, .dropdown-menu li a {
        text-shadow: none
    }

    .label, .badge, .navbar-inverse .nav > li > a, .navbar-inverse .nav > li.active > a {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }

    .nav-collapse .nav > li > a, .nav-collapse .dropdown-menu a {
        font-weight: 600
    }

    .primary-sidebar .nav {
        margin: 0
    }

    .breadcrumb-line {
        height: auto
    }

    .breadcrumb-line .breadcrumbs {
        *zoom: 1;
        float: none
    }

    .breadcrumb-line .breadcrumbs:before, .breadcrumb-line .breadcrumbs:after {
        display: table;
        content: "";
        line-height: 0
    }

    .breadcrumb-line .breadcrumbs:after {
        clear: both
    }

    .breadcrumb-line .nav.pull-right {
        float: none;
        margin-right: 0;
        border-right: none;
        border-top: 1px solid #ccc
    }

    .breadcrumb-line .nav.pull-right > li:first-child > a {
        border-left: none
    }

    .nav-collapse .dropdown-menu {
        display: block;
        background: transparent
    }

    .nav-collapse .dropdown-menu li a {
        background: rgba(0, 0, 0, 0.2);
        margin-bottom: 3px !important
    }

    .navbar-search .search-query.animated, .navbar-search .search-query.animated {
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 12px
    }

    .navbar-search .search-query.animated:focus, .navbar-search .search-query.animated .focused, .navbar-search .search-query.animated:focus, .navbar-search .search-query.animated .focused {
        width: 100%;
        padding: 12px
    }

    .navbar-search i {
        right: 25px;
        top: 15px
    }

    .nav-tabs-right li > a > span, .nav-tabs-right .title, .nav-tabs-left li > a > span, .nav-tabs-left .title {
        display: none
    }
}

@media (max-width:767px) {
    body {
        padding: 0
    }

    .btn-block {
        margin-bottom: 10px
    }

    .user-avatar {
        padding-bottom: 0
    }

    .dataTables_info {
        display: none
    }

    .dataTables_filter input[type="text"] {
        width: 130px
    }

    .dataTables_length {
        display: none
    }

    .next.paginate_button, .previous.paginate_button {
        display: none
    }

    .area-top {
        text-align: center
    }

    .area-top .header {
        display: block;
        float: none
    }

    .area-top .header i {
        float: none
    }

    .area-top .sparkline-box {
        float: none;
        display: inline-block
    }
}

@media (max-width:550px) {
    .area-top .sparkline-box .sparkline {
        display: none
    }
}

@media (max-width:480px) {
    .area-top .num-stats li {
        width: auto
    }

    .navbar .brand {
        text-align: center;
        width: 100%;
        float: none
    }

    .navbar .nav.pull-right {
        float: none;
        *zoom: 1
    }

    .navbar .nav.pull-right:before, .navbar .nav.pull-right:after {
        display: table;
        content: "";
        line-height: 0
    }

    .navbar .nav.pull-right:after {
        clear: both
    }

    .navbar-inner {
        text-align: center
    }

    .navbar-inner a {
        margin-right: 0
    }

    .brand + .nav {
        display: inline-block;
        margin-bottom: 10px
    }

    .navbar-inverse .nav > li > a {
        margin-right: 0
    }

    .navbar-inverse .brand + .nav > li > a {
        margin-right: 10px
    }

    .navbar-inverse .brand + .nav > li:last-child a {
        margin-right: 0
    }

    .area-top {
        height: auto;
        text-align: center
    }

    .area-top .header {
        margin-top: 0;
        margin-bottom: 7px;
        text-align: center;
        float: none
    }

    .area-top .num-stats {
        float: none;
        display: inline-block
    }

    .area-top .num-stats li {
        padding: 5px
    }

    .chat-box.timeline .arrow-box-left {
        margin-left: 0
    }

    .chat-box.timeline .arrow-box-left:after, .chat-box.timeline .arrow-box-left:before {
        content: none
    }

    .chat-box.timeline .arrow-box-left .avatar {
        left: 10px;
        top: 5px
    }

    .chat-box.timeline .arrow-box-left .name {
        float: left;
        margin-left: 45px
    }

    .chat-box.timeline + .closable-chat-box {
        margin-left: 0
    }
}

.select2-select-all {
    background: #F4F4F4
}

/* grip icon for vertical drag & drop */
/* more: https://github.com/FortAwesome/Font-Awesome/issues/816#issuecomment-267202037 */
.fa-drag-handle:before {
    content: "\f142 \f142";
    letter-spacing: 0.1em;
}
