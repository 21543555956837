<template>
  <div class="login-layout">
    <img
      :src="logo"
      class="login-layout__logo-image"
    >
    <div class="login-layout__login-window">
      <slot />
    </div>
  </div>
</template>

<script>
  import twitterIcon from 'assets/channels/twitter.svg';
  import {logo} from 'utils/getAppLogo';

  export default {
    name: 'LoginViewLayout',
    data: () => ({
      twitterIcon,
      logo
    })
  };
</script>

<style>
  .login-layout {
    display: flex;

    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    height: 100%;

    background-color: #F8F9FC;
  }

  .login-layout__login-window {
    font-family: 'Roboto', sans-serif;

    box-sizing: border-box;
    width: 418px;

    padding: 30px 52px 32px;

    background-color: var(--background-color);
    box-shadow: 0 2px 10px rgba(54, 56, 65, 0.25);

    border-radius: 4px;
  }

  .login-layout__logo-image {
    max-width: 210px;

    margin-top: 100px;
    margin-bottom: 40px;
  }
</style>
