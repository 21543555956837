@import 'headers.css';
@import 'media.css';
@import 'z-indexes.css';
@import 'tooltip.css';
@import "generic.css";

.spacer5 { clear: both; height: 5px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer10 { clear: both; height: 10px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer15 { clear: both; height: 15px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer20 { clear: both; height: 20px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer25 { clear: both; height: 25px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer30 { clear: both; height: 30px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer35 { clear: both; height: 35px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer40 { clear: both; height: 40px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer45 { clear: both; height: 45px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer50 { clear: both; height: 50px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer100 { clear: both; height: 100px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }
.spacer200 { clear: both; height: 200px; width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block; }

body {
    font-family: var(--main-font-family);
    color: #333333;
    /*
    *  Fix font `some font faced fonts look bold on Mac in Chrome/Webkit based browsers.`
    *  https://www.uv.mx/personal/gvera/stop-fonts-looking-bold-on-mac-browsers/
    */
    -webkit-font-smoothing: antialiased;
    /* And same for firefox */
    -moz-osx-font-smoothing: grayscale;
}

button, input, textarea {
    font-family: var(--main-font-family);
}

.body_modal-active {
    overflow: hidden;
}

.project-common_info dt,.project-common_info dd {
    padding: 0.7em;
}

.dl-form dt, .dl-form dd {
    height: 2.7em;
}

div.dl-controls {
    margin-top: 4em;
}

.dl-pills dt {
    padding: 7px 0;
}

.booking {
    text-align: center;
}

.booking-enabled {
    background-color: #dee8f2;
}

.account-info {
    font-style: italic;
}

.account-info .divider {
    padding: 0 5px;
    color: #CCC;
}

.align-left { text-align: left; }
.align-right { text-align: right; }
.align-center { text-align: center; }

/* Forward compatibility with newer Bootstrap versions */
.text-left, th.text-left, td.text-left { text-align: left; }
.text-right, th.text-right, td.text-right { text-align: right; }
.text-center, th.text-center, td.text-center { text-align: center; }

.input-append .btn-group {
    margin-left: -1px;
}

.input-append .btn-group > .dropdown-toggle {
    vertical-align: top;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.input-append .btn-group:last-child > .dropdown-toggle {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.summary {
    font-style: italic;
}

li.disabled a {
    color: black;
    text-decoration: none;
}
li.disabled a:hover {
    color: black;
    text-decoration: none;
    cursor: default;
}

li label {
    display: inline;
}

li input[type="radio"] {
    margin-right: 5px;
}

.list {
    list-style: none;
}

.sortable {
    cursor: pointer;
    background: url('../_legacyAssets/sortable.gif') no-repeat right center;
}

.table th.sortable {
    text-align: center;
    padding-right: 20px;
}

.table thead th {
    vertical-align: middle;
}

.sortable.sorted {
    background-color: var(--table-sorted-th-color);
}

.sortable.sort-asc {
    background-image: url('../_legacyAssets/sortasc.gif');
}

.sortable.sort-desc {
    background-image: url('../_legacyAssets/sortdesc.gif');
}

.sortable.sort-progress {
    background-image: url('../_legacyAssets/spin.gif');
}

div.stats-control-hint {
    margin-bottom: 2px;
    margin-top: 2px;
    padding-bottom: 8px;
    padding-top: 4px;
}

/* Bootstrap select in form-inline */

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.btn-group,
.form-inline .combobox,
.form-inline .inline-input,
.form-inline button {
    display: inline-block;
    *display: inline;
    margin-bottom: 0;
    vertical-align: middle;
    *zoom: 1;
}

.form-horizontal .bootstrap-select {
    margin-bottom: 0;
}

.bootstrap-select.input-block-level {
    width: 100%;
}

/* Filter */

.filter-trigger label {
    font-weight: bold;
}

.apply-filters {
    width: 145px;
}

.clear-filter {
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.remove-filter {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.2;
    filter: alpha(opacity=20);
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

.remove-filter:hover {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.status-caret {
    display: none;
}

.btn-group:hover .status-caret { display: inline-block; }

#booking-table-holder .bootstrap-select { width: 180px; margin-bottom: 0px; }
#booking-table-holder .bootstrap-select .btn { width: 180px; }

.input-line {
    line-height: 30px;
    margin-bottom: 9px;
}

.clickable {
    cursor: pointer;
}

.form-horizontal-filter .controls {
    margin-left: 165px;
    margin-right: 50px;
}

.form-horizontal-filter-permanent .controls {
    margin-left: 165px;
    margin-right: 30px;
}

.form-horizontal-filter .control-label, .form-horizontal-filter-permanent .control-label {
    width: 145px;
}

.form-horizontal-filter .control-append, .form-horizontal-filter-permanent .control-append {
    float: right;
    display: block;
    margin-left: 9px;
}

/* Bootstrap accordion like well */
.accordion-group-well {
    margin-bottom: 9px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
       -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.accordion-group-well .accordion-heading a {
    font-weight: bold;
    color: #333;
}

.restore-link {
    font-size: 80%;
    padding-top: 0.2em;
}

.changed {
    font-weight: bold;
}

.form-horizontal input + .help-block-error,
.form-horizontal select + .help-block-error,
.form-horizontal textarea + .help-block-error {
  margin-top: 0;
}

.form-horizontal-annotated .controls {
    margin-right: 60px;
}

.form-horizontal-annotated .control-annotation {
    float: right;
    width: 50px;
    display: block;
}

.input-counter {
    font-size: 12px;
    color: #777;
    padding-top: 5px;
    padding-bottom: 5px;
}

.input-counter.error {
    color: #B94A48;
}

a.link-dotted, a.link-dotted:hover {
    text-decoration: none;
    border-bottom: 1px dotted;
}

input.input-cell {
    margin-top: -7px;
    margin-bottom: -5px;
}

input.input-cell-padded {
    margin: 0;
}

.checkbox.input-cell-padded {
    text-align: center;
    margin: 0;
    padding: 0;
    line-height: 26px;
}

.checkbox.input-cell-padded input {
    float: none;
    margin: 0;
}

.group-operations-sep {
    margin: 5px 0;
}

.nav-pills-compact {
    margin-bottom: 0;
}

/* Bootstrap-style Select2 */

.select2-container.bootstrap-select2 .select2-choices {
    border: 1px solid #CCC;
    border-radius: 3px;
    background-image: none;
}

.select2-container.bootstrap-select2 .select2-search-field .select2-input {
    margin: 0;
    padding: 4px 6px;
}

.select2-container.input-cell-padded .select2-search-field .select2-input {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.label-th {
    font-weight: bold;
    margin-bottom: 0;
}

.name-filter {
    width: 190px;
}

.closed-budget {
    width: 100px;
}

.icon-progress {
    background: url('../_legacyAssets/spin.gif') center center no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: -6px;
}

.icon-progress.small {
    width: 14px;
    height: 14px;
    margin-bottom: -3px;
}

/* c-imagepicker */

.imagepicker-control .file-input{
    display: none;
}

/* c-borderpicker */

.border-picker{
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #494949;
    border-radius: 1px;
    cursor: pointer;
    box-sizing: border-box;
}

.border-picker-dropdown {
    display: none;
    position: absolute;
    right: -60px;
    width: 140px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 1px 1px 2px 1px #9e9e9e;
    padding: 5px 0;
}

.border-picker-dropdown .header{
    text-align: center;
    font-weight: bold;
    margin: 5px 0;
}

.border-picker-dropdown .border-list .item {
    display: flex;
    padding: 0 10px;
}

.border-picker-dropdown .border-list .item:hover {
    background-color: #ececec;
    display: flex;
}

.border-picker-dropdown .border-list label{
    width: 100%;
}

.border-picker-dropdown .border {
    position: relative;
    top: 4px;
    width: 14px;
    height: 14px;
    display: inline-block;
    box-sizing: border-box;
}

/* c-colorpicker */

.sp-clear-enabled.sp-palette-only .sp-clear-palette-only {
    width: 21px;
    height: 14px;
    border: none;
    margin: 1px;
    display: inline-block;
    position: relative;
    left: 0; /* override default style of spectrum js */
    background: none;
}

.color-picker{
    display: inline-block;
    width: 45px;
    height: 13px;
    background-color: #ff0000;
    border: solid 1px rgba(0,0,0, 0.25);
    border-radius: 3px;
    cursor: pointer;
}

.sp-container{
    background-color: #fff;
    box-shadow: 1px 1px 2px 1px #9e9e9e;
    border: none;
}

.sp-container .sp-thumb-el{
    width: 24px;
    height: 14px;
    border: none;
    margin: 1px;
}
.sp-container .sp-thumb-inner {
    border: 1px solid rgba(0,0,0,0.25);
}

.sp-container .sp-thumb-light.sp-thumb-active .sp-thumb-inner {
    background-image: none;
    border: 2px solid rgba(0,0,0,0.5);
}

.sp-container .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
    background-image: none;
    border: 2px solid rgba(255,255,255,0.8);
}

/* Pagination tweaks (remove padding and line-height overrides after updating Twitter Bootstrap) */

.pagination ul > li > a, .pagination ul > li > span {
    padding: 0 10px;
    line-height: 30px;
}

.pagination ul > li > a.ellipsis, .pagination ul > li > span.ellipsis {
    border: 0;
}

input.item-checkbox {
    margin-top: -1px;
}

.table-striped tbody tr.paused:not(.success) td, .table-striped tbody tr.paused th {
    background-color: #f0f0f0;
}

/* Colors of percent cells */

.table tbody > tr > td.cell-ok {
    color: #468847;
    background-image: url('../_legacyAssets/icon-success.png');
    background-position: 8px 10px;
    background-repeat: no-repeat;
    padding-left: 24px;
}

.table tbody > tr > td.cell-warning {
    color: #c09853;
    background-image: url('../_legacyAssets/icon-warning.png');
    background-position: 8px 10px;
    background-repeat: no-repeat;
    padding-left: 24px;
}

.table tbody > tr > td.cell-alert {
    color: #b94a48;
    background-image: url('../_legacyAssets/icon-error.png');
    background-position: 8px 10px;
    background-repeat: no-repeat;
    padding-left: 24px;
}

.table thead > tr > th.cell-ok {
    color: #468847;
    background-image: url('../_legacyAssets/icon-success.png');
    background-position: 8px 10px;
    background-repeat: no-repeat;
    padding-left: 24px;
}

.table thead > tr > th.cell-warning {
    color: #c09853;
    background-image: url('../_legacyAssets/icon-warning.png');
    background-position: 8px 10px;
    background-repeat: no-repeat;
    padding-left: 24px;
}

.table thead > tr > th.cell-alert {
    color: #b94a48;
    background-image: url('../_legacyAssets/icon-error.png');
    background-position: 8px 10px;
    background-repeat: no-repeat;
    padding-left: 24px;
}

.search-pill {
    width: 160px;
}

.new-stacking-context {
    position: relative;
    z-index: 0;
}

/* Paradise for wiiide tables with sticky header */
.wide-table-container {
    overflow: auto;
    /* max-height is set to make table with its pagination fit inside one full screen */
    /* usually we have no other elements after table, so just try CTRL+END to get the point */
    /* magic-arithmetic here:
          20px (table margin-bottom)
        - 10px (pagination margin-top)
        + 14px (pagination summary height)
        + 35px (pagination height) +
        + 20px (content margin-bottom)
        + 20px (something i failed to find)
        = 99px to subtract from viewport height to get table's max-height */
    max-height: calc(100vh - 99px);
    margin-bottom: 20px;
    --sticky-header-z-index: 20;
    --sticky-column-cell-z-index: 10;
}

.wide-table-container table {
    margin-bottom: 0;
    border-top: 0;
}

.wide-table-container th {
    background-color: var(--table-th-color)
}

/* override bootstrap table borders
   such accurate specificity to make things work
   see also: https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity */
.wide-table-container .table thead:first-child tr:first-child > th:first-child,
.wide-table-container .table thead:first-child tr:first-child > th:last-child,
.wide-table-container .table tbody:last-child tr:last-child > td:first-child,
.wide-table-container .table tbody:last-child tr:last-child > td:last-child {
    border-radius: 0;
}

.wide-table-container table thead th {
    border-bottom: 1px solid var(--table-border-color);
    z-index: var(--sticky-header-z-index);
}

.wide-table-container table thead th.sticky-column-header {
    z-index: var(--sticky-header-in-sticky-column-z-index);
}

/* sticky header. Stick only first thead row */
/* unfortunately `border-collapse: collapse` cannot be used with sticky header */
/* so we have to handle border dublicates manually */
.wide-table-container .table thead tr:first-child th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    border-top: 1px solid var(--table-border-color);
}

/* a class to place on a <th> that should be sticky */
.sticky-column-header {
    position: sticky;
    position: -webkit-sticky;
    border-right: 1px solid var(--table-border-color);
}

th.sticky-column-header + th {
    border-left: 0;
}

/* a class to place on a <tr> that should be sticky */
.sticky-column-cell {
    position: sticky;
    position: -webkit-sticky;
    border-right: 1px solid var(--table-border-color);
    z-index: var(--sticky-column-cell-z-index);
}

td.sticky-column-cell + td {
    border-left: 0;
}

/* remove border-top from second thead row, because sticky row has border-bottom */
.wide-table-container table thead tr:nth-child(2) th {
    border-top: 0;
}

/* remove border-top from first tbody row, because sticky thead row has border-bottom */
.wide-table-container table tbody tr:first-child td {
    border-top: 0;
}

.sticky-header-row {
    position: sticky;
    position: -webkit-sticky;
    top: var(--sticky-header-row-offset);
}

/* Compatibility with Bootstrap Core-Admin theme */

/* bootstrap-select shuld act like .btn-default */
.bootstrap-select .btn {
    color:#686868;
    text-shadow:none;
    border:1px solid #d4d4d4;
    -webkit-box-shadow:inset 0 1px 2px #FFF;
    -moz-box-shadow:inset 0 1px 2px #FFF;
    box-shadow:inset 0 1px 2px #FFF;
    background-color:#e9e9e9;
    background-size:100%;
    background-image:linear-gradient(to bottom,#fdfdfd,#e9e9e9);
}

.bootstrap-select .btn:hover,.bootstrap-select .btn:active {
    color:#505050;
}

.bootstrap-select .btn .caret {
    border-top:4px solid #000;
}

.bootstrap-select .btn:hover {
    background-color:#e6e6e6;
    background-size:100%;
    background-image:linear-gradient(to bottom,#fdfdfd,#e6e6e6);
    -webkit-transition:box-shadow .05s ease-in-out;
    -moz-transition:box-shadow .05s ease-in-out;
    -o-transition:box-shadow .05s ease-in-out;
    transition:box-shadow .05s ease-in-out;
}

.bootstrap-select .btn:active {
    background-color:#f3f3f3;
    -webkit-box-shadow:0 0 5px #f3f3f3 inset;
    -moz-box-shadow:0 0 5px #f3f3f3 inset;
    box-shadow:0 0 5px #f3f3f3 inset;
    border-color:#cfcfcf;
}

.btn-group.open .bootstrap-select .btn.dropdown-toggle {
background-color:#fdfdfd;
}

/* .btn-default had no style for .active class */
.btn.btn-default.active,
.btn.btn-default:active {
  background-color: #e6e6e6;
  background-color: #d9d9d9 \9;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Disabled input */
input[type="text"][disabled] {
    background-color: #f4f4f4;
}

/* Disabled buttons */
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* More bright links */
a {
    /** color: #599CC7;**/
    color: #2288cc;
}

.btn {
    font-size: 12px;
}

.navbar {
    margin-bottom: 5px;
}

.ibutton-container {
    float: right;
    overflow: hidden;
    margin-left: 5px;
    margin-top: 5px;
}

.box .box-header .title {
    font-weight: bold;
}

/** jstree begin */
.jstree li a {
    height: auto;
    vertical-align: middle;
}

.jstree li a .node-content {
    padding-left: 20px;
}

.jstree li a .node-hint {
    display: block;
    color: #777;
    font-size: 10px;
    font-style: italic;
    line-height: 12px;
}

/** jstree end */

div.alert-center {
    float: none;
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 7px;
    padding-bottom: 7px;
}

.link-button {
    width: 110px;
}

/* css for timepicker */
.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
.ui-timepicker-div dl { text-align: left; }
.ui-timepicker-div dl dt { float: left; clear:left; padding: 0 0 0 5px; }
.ui-timepicker-div dl dd { margin: 0 10px 10px 40%; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }

.ui-timepicker-rtl{ direction: rtl; }
.ui-timepicker-rtl dl { text-align: right; padding: 0 5px 0 0; }
.ui-timepicker-rtl dl dt{ float: right; clear: right; }
.ui-timepicker-rtl dl dd { margin: 0 40% 10px 10px; }

/* css for timepicker application tweaks */
.ui-slider-horizontal { clear: none; }
dd > .ui-slider-horizontal{ top: 5px; }


.slash-list > :not(.option-hidden):after {
    content: ' / ';
}

.slash-list > :not(.option-hidden):last-child:after {
    content: '';
}

.options-list > span:not(.option-hidden):after {
    color: #CCC;
}

.options-list > .option-hidden {
    display: none;
}

/* Reports */
.report-select2-container {
    overflow: auto;
    max-height: 250px;
}

.report-select2-container .select2-search-choice {
    width: 332px;
}

.report-row {
    margin-bottom: 10px;
}

/* VAT settings */

.vat-list-table td input{
    margin: 0;
}

.vat-list-table td {
    text-align: center;
}

/* Custom reports */
.report-grid-container{
    margin-left: 30px;
    margin-bottom: 100px;
    width: calc(100% - 30px);
}

.item-animated {
    transition-timing-function: ease-out;
    transition: 150ms;
}

.grid-element.grid-warning {
    box-shadow: 0 0 2px 4px #ffc107;
}

.grid-element.grid-error{
    box-shadow: 0 0 1px 2px #ff0000;
}

.grid-element.grid-error:after{
    content: attr(data-error);
    position: relative;
    top: 5px;
    color: #ff0000;
}

.report-grid-container .grid-list{
    height: 450px;
    overflow: scroll;
    padding: 0;
    border: solid 1px #d2d2d2;
    position: relative;
}

.grid-element{
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    border-radius: 5px;
    box-sizing: border-box;
    z-index: 100;
    /*overflow: hidden;*/
}

.grid-element .report-table-container{
    box-sizing: border-box;
    border: solid 1px #000000;
    overflow: hidden;
    border-radius: 3px;

}

.grid-element .report-element-text{
    transition: all 0.5s ease-in-out;
    border: solid 1px #c3c3c3;
    box-sizing: border-box;
}

.grid-element .report-element-image{
    border: solid 1px #000;
    box-sizing: border-box;
    overflow: hidden;
}

.grid-element .report-element-image .contents{
    width: 100%;
    height: 100%;
    vertical-align: middle;
    font-size: 16px;
}

.grid-element .report-element-image .controls {
    position: absolute;
    top: 5px;
    right: 3px;
    font-size: 16px;
    text-align: center;
}

.imagepicker-control {
    width: 200px;
    height: 200px;
    display: table;
    background-size: 100%;
    position: relative;
}

.imagepicker-control .image-picker{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: .25s ease-in-out;
}

.imagepicker-control .selected {
    transition: all .25s ease-in-out;
    opacity: 0;
}

.imagepicker-control:hover .selected {
    opacity: 1;
}

.imagepicker-control .picker-control {
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    cursor: pointer;
}

.imagepicker-control .imagepicker-trigger {
    font-size: 14px;
    color: #6e6e6e;
    transition: .25s ease-in-out;
}

.imagepicker-control .background{
    width: 100%;
    height: 100%;
    background-size: 100%;
}

.imagepicker-control:hover .image-picker {
    background-color: rgba(216, 216, 216, 0.5);
}

.imagepicker-control:hover .imagepicker-trigger {
    color: #1a1a1a;
    font-size: 15px;
}

.grid-element .report-element-image .imagepicker-control {
    width: 100%;
    height: 100%;
}

.grid-element .report-element-image .background{
    transition: 0.25s ease-in-out;
    width: 100%;
    height: 100%;
}

.grid-animated{
    transition: 100ms, background-color 200ms linear;
}

.grid-element > div {
    width: 100%;
    height: 100%;
}

.sheet-tabs-container {
    display: flex;
    position: relative;
}

.sheet-tabs {
    display: flex;
    overflow-x: auto;
}

.sheet-tab {
    padding: 5px;
    min-width: 60px;
    cursor: pointer;
    display: flex;
    width: 100px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #b6b6b6;
    border-right: 1px solid #b6b6b6;
}
.sheet-tab:first-child {
    border-left: 1px solid #b6b6b6;
}
.sheet-tab:hover, .sheet-tab.active {
    border: 1px solid #6e6e6e;
    border-top: none;
    margin-left: -1px;
}
.sheet-tab:hover:first-child, .sheet-tab.active:first-child {
    margin-left: 0;
}

.sheet-tab.active {
    font-weight: bold;
}

.sheet-tab .settings {
    padding: 3px 5px;
}

.sheet-tab .contents {
    width: 90%;
}
.sheet-tab .contents .text-field {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
}

.sheet-tab .rename-sheet {
    margin: 0;
    padding: 0;
    width: 90%;
}

.add-sheet {
    font-size: 16px;
    color: #b6b6b6;
    width: 50px;
    justify-content: center;
}
.add-sheet:hover {
    color: #6e6e6e;
}

.columns-separated{
    display: flex;
}

.report-element-table .dimension + .metric{
    border-left: solid 1px #808080;
}

.report-element-table{
    height: 100%;
    display: grid;
    grid-template-columns: 120px;
    grid-auto-flow: column;
}

.report-element-table > div{
    width: 120px;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 120px;
    grid-auto-flow: column;
    border-right: solid 1px #d8d8d8;
    box-sizing: border-box;
    overflow: hidden;
}

.report-element-table > div:last-of-type{
    border-right: none;
}

.report-element-table > div > div {
    margin: 0;
    padding: 5px;
    height: 100%;
    width: 100%;
    display: table;
    box-sizing: border-box;
}

.report-element-table .content{
    display: table-cell;
    vertical-align: middle;
}

.report-element-table .cell{
    border-top: solid 1px #c4c4c4;
}

.report-element-table > .column > .cell:nth-child(3){
    border-bottom: solid 1px #c4c4c4;
}

.report-element-table .cell:nth-child(2n + 1){
    background-color: rgba(200,200,200, 0.3);
}

.report-element-table .header {
    position: relative;
}

.report-element-table .footer {
    position: relative;
}

.report-element-table .header .content{
    font-weight: bolder;
    flex-direction: row;
}

.report-element-table .text{
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 75px;
    overflow: hidden;
    padding-top: 3px;
}

.grid-element .controls{
    position: absolute;
    right: 3px;
    top: 6px;
    display: inline;
    float: right;
    transition: 200ms;
    white-space: nowrap;
    font-size: 16px;
    color: #b6b6b6;
}

.report-element-table .footer{
    font-weight: bolder;
}

.cell-items-templates .grid-element{
    margin: 10px;
    display: inline-block;
}

.grid-list canvas{
    padding: 0;
    margin: 0;
}

.grid-list .ui-state-disabled{
    opacity: 1;
}

.grid-list .report-element-text .text-field{
    font-size: 12px;
    display: inline-block;
    transition: .25s;
    word-wrap: break-word;
    word-break: break-all;
    padding: 5px;
}

.report-element-text .text-field[contenteditable=true]:empty::before {
    font-size: 12px;
    content: attr(placeholder);
    color: #949494;
}

.grid-list .report-element-text .text-field.active{
    border: solid 1px #9f9f9f;
}

.report-popup{
    position: absolute;
    z-index: 200;
    width: 250px;
    background-color: #fff;
    box-shadow: 1px 1px 2px 1px #9e9e9e;
    padding: 5px;
    border-radius: 3px;
    display: none;
}

.report-popup .dropdown-header{
    padding: 5px 10px;
    font-weight: bold;
}

.report-popup .dropdown-header > i {
    margin-right: 10px;
    font-size: 16px;
    color: #000;
}

.report-popup .clickable-item{
    color: #000;
    padding: 5px 5px;
    font-weight: bolder;
    cursor: pointer;
    transition: .25s;
}

.report-popup .form-row{
    color: #000;
    margin: 0 5px;
    box-sizing: border-box;
}

.report-popup .form-row label{
    color: #000;
    cursor: default;
}

.report-popup .form-row .sub-text{
    color: #494949;
}

.report-popup .form-row input{
    width: 100%;
    box-sizing: border-box;
}

.report-popup .color-row{
    display: flex;
}

.report-popup .color-row label{
    width: 100%;
    font-weight: bolder;
    color: #000;
}

.report-popup .clickable-item:hover{
    background-color: #ececec;
}

.grid-element .ui-resizable-handle{
    width: 12px;
    height: 13px;
    background-image: none;
    text-indent: 0;
    transition: .25s;
}
.grid-element:hover .ui-resizable-handle, .ui-resizable-resizing .ui-resizable-handle{
    width: 14px;
    height: 15px;
    background-image: none;
    text-indent: 0;
}
.grid-element .ui-resizable-handle:before{
    content: "\f065";
    font-family: FontAwesome;
    color: #acacac;
    text-indent: 0;
    font-size: 12px;
    transform: rotate(90deg);
    display: inline-block;
    transition: .25s;
}

.grid-element:hover .ui-resizable-handle:before{
    font-size: 14px;
}

.grid-element .ui-resizable-handle:hover:before, .ui-resizable-resizing .ui-resizable-handle:before {
    color: #575757;
}

.grid-element .control-item {
    color: #b6b6b6;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    padding-left: 2px;
    padding-right: 2px;
    transition: .25s;
}

.grid-element .control-item:hover{
    color: #6e6e6e;
}

.grid-element .ui-resizable-handle:hover:before, .ui-resizable-resizing{
    z-index: 200;
}

.grid-element .report-element-text {
    display: block;
    overflow: hidden;
}

.grid-element .report-element-text .contents {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
}

.grid-element .report-element-text .controls {
    position: absolute;
    top: 5px;
    right: 3px;
    font-size: 16px;
}

/* end of custom reports */

.custom-report-form {
    align-items: flex-end;
}

.custom-report-form .form-stacked {
    width: 420px;
}

.template-items-column {
    border: solid 1px #c4c4c4;
    width: 100%;
    margin-bottom: 20px;
}

.template-items-column > .header {
    text-align: center;
    padding: 10px;
    color: #6e6e6e;
    font-size: 14px;
}

.template-items-column .cell-items-templates {
    margin-bottom: 20px;
}

.permission-item {
    width: 250px;
}

.help-icon {
    color: #cccccc;
    margin-left: 2px;
}

.help-list {
    margin: 0 0 10px 10px;
}

.help {
    white-space: nowrap;
}

.popover-content {
    font-weight: normal;
}

/* Stacked form */

.form-stacked .field-margin-top {
    margin-top: 52px;
}

.form-stacked .field.field-small {
    width: 100px;
}

.form-stacked .field.field-medium {
    width: 160px;
}

.form-stacked .field.field-large {
    width: 220px;
}

.form-stacked .field.field-xlarge {
    width: 280px;
}

.form-stacked .field.field-xxlarge {
    width: 540px;
}

.form-stacked .field {
    display: block;
    margin-bottom: 10px;
}

.form-stacked .field-inline {
    display: inline-block;
    margin-bottom: 0;
}

.form-stacked .field > label {
    display: inline-block;
}

.form-stacked .field-inline > label {
    margin-left: 4px;
    margin-right: 4px;
}

.form-stacked .field-inline > label:first-child {
    margin-left: 0;
}

.form-stacked .field > input, .form-stacked .field > textarea, .form-stacked .field > .checkbox {
    margin-bottom: 0;
}

.form-stacked .field > input, .form-stacked .field > textarea, .form-stacked .field > .checkbox.inline {
    padding-top: 0;
}

.form-stacked .field > input, .form-stacked .field > textarea, .form-stacked .field .radio.inline {
    padding-top: 0;
}

.form-stacked .field input[type="text"], .form-stacked .field input[type="password"], .form-stacked .field textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-height: 30px;
}

.form-stacked .field-inline input[type="text"], .form-stacked .field-inline textarea {
    width: auto;
}

.form-stacked .field .text-error {
    display: block;
}

.form-stacked .field-checkbox {
    margin-bottom: 5px;
}

.form-stacked .field-checkbox .text-error {
    margin-left: 20px;
}

.form-stacked .field-radiolist label, .form-stacked .field-checklist label {
    margin-bottom: 0;
}

.form-stacked .field-channel-input {
}

.form-stacked .field-channel-input img {
    margin: 3px;
}

.form-stacked .field-channel-input .close {
    float: none;
    margin: 4px;
}

.form-stacked .field-report-dates .field-report-date {
    position: relative;
}

.form-stacked .field-report-dates .field-report-date > input {
    margin-bottom: 0;
}

.form-stacked .field-report-dates .text-error {
    position: absolute;
    left: 0;
    top: 26px;
}

.form-stacked .field-report-entities .radio.inline {
    padding-top: 0;
    margin-bottom: 5px;
    margin-right: 40px;
}

.form-stacked .field-report-format .radio.inline {
    padding-top: 0;
    margin-left: 20px;
}

.form-stacked .field-search {
    width: 212px;
    position: relative;
}

.form-stacked .field-search .icon-search {
    position: absolute;
    right: 10px;
    top: 8px;
    color: #777;
}

/* Table-like form */

.form-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.form-table .form-table-row {
    display: table-row;
}

.form-table .form-table-row .form-table-cell {
    display: table-cell;
    border: 4px solid transparent;
}

.form-table .form-table-row:first-child .form-table-cell {
    border-top: none;
}

.form-table .form-table-row:last-child .form-table-cell {
    border-bottom: none;
}

.form-table .form-table-row .form-table-cell:first-child {
    border-left: none;
}

.form-table .form-table-row .form-table-cell:last-child {
    border-right: none;
}

.form-table .form-table-cell-tiny {
    width: 1%;
}

.form-table .form-table-cell label {
    display: inline;
    white-space: nowrap;
    margin-right: 5px;
}

.form-table .form-table-cell input[type="text"] {
    margin: 0;
    min-height: 30px;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-table .form-table-cell input.select2-input {
    min-height: 26px;
}

.form-table .form-table-cell input[type="radio"] {
    width: auto;
    min-height: 20px;
    margin-left: -20px;
    margin-right: 4px;
}

.form-table .form-table-cell label.radio.inline {
    display: inline-block;
    padding-top: 0;
}

.form-table .form-table-cell .select2-container {
    margin: 0;
}

/* Drop mask */

.drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    /* Beneath bootstrap popover div */
    z-index: 1099;
    /* styles required for IE to work */
    background-color: #fff;
    filter: alpha(opacity=0);
}

.drop-mask-locking {
    position: absolute;
    opacity: 0.5;
    filter: alpha(opacity=0.5);
}

.drop-mask-transparent {
    position: absolute;
}

.drop-mask-spinner {
    background: #fff url('../_legacyAssets/spin.gif') no-repeat center center;
}

/* Column presets */

.preset-row {
    height: 20px;
}

.preset {
    margin-right: 10px;
}

.presets div .delete-preset {
    float: none;
    display: none;
}

.presets div:hover .delete-preset {
    display: inline-block;
    margin-left: -10px;
    margin-right: -5px;
}

.small-height-select2 > ul > li.select2-search-field > input.select2-input {
    height: 22px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.field-small-height-select2 > ul > li.select2-search-field > input.select2-input {
    min-height: 22px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.statistics-select2-container {
    overflow: auto;
    max-height: 250px;
    margin-top: 2px;
}

.statistics-select2-container .select2-choices .select2-search-field input {
    height: 22px;
    padding-top: 2px;
    padding-bottom: 2px;
}

/* Box table */

.box-table {
    display: table;
    border-spacing: 20px;
    margin-left: -20px;
    margin-top: -20px;
}

.box-table-row {
    display: table-row;
}

.box-table-cell {
    display: table-cell;
    border-spacing: 0;
    vertical-align: top;
}

.edited-cell {
    background-color: #f6e1a4 !important;
}

.plot-tooltip {
    font-size: 0.9em;
    position: absolute;
    padding: 2px;
    opacity: 0.94;
}


.legend table {
    border-spacing: 3px;
    border-collapse: separate;
}

/* Status dropdown */

.status-dropdown {
    position: absolute;
    z-index: 9999;
}

.status-dropdown > ul {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;

    background-color: whitesmoke;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkZmRmZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y1ZjVmNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #f5f5f5));
    background-image: -webkit-linear-gradient(to bottom, #fdfdfd, #f5f5f5);
    background-image: -moz-linear-gradient(to bottom, #fdfdfd, #f5f5f5);
    background-image: -o-linear-gradient(to bottom, #fdfdfd, #f5f5f5);
    background-image: linear-gradient(to bottom, #fdfdfd, #f5f5f5);

    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    text-decoration: none;
    outline: none;
    list-style: none;

    border: 1px solid #ccc;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.status-dropdown li > a:hover, .status-dropdown li > a:focus, .status-dropdown li > a:active {
    background-color: #f2f2f2;
    text-decoration: none;
}

.status-dropdown li > a {
    text-decoration: none;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 20px;
    color: #686868;
    white-space: nowrap;
}

/* End of status dropdown */

i.default-cursor {
    cursor: default;
}

/* TabSelect control */

div.tab-select div {
    font-weight: normal;
    letter-spacing: 1px;
    text-decoration: none;
    padding: 2px 4px;
    margin-right: 6px;

    cursor: pointer;

    text-shadow: 0 1px 1px rgba(0,0,0,.3);
    -webkit-border-radius: .2em;
    -moz-border-radius: .2em;
    border-radius: .2em;
    -webkit-box-shadow: 0 1px 2px rgba(160,160,160,1);
    -moz-box-shadow: 0 1px 2px rgba(160,160,160,1);
    box-shadow: 0 1px 2px rgba(160,160,160,1);
}

div.tab-select div.active {
    background-color: #5BA5CB;
    color: #fff;
    border: 1px #337CA1 solid;
}

div.tab-select div.active:hover {
    background-color: #0088CC;
    color: #fff;
    border: 1px #337CA1 solid;
}

div.tab-select div.error {
    background-color: #ff5959;
}

div.tab-select div.error:hover {
    background-color: #ff1d1d;
}

div.tab-select div {
    background-color: #ddd;
    color: #888;
    border: 1px #bbb solid;
    text-align: center;
}

div.tab-select div:hover {
    background-color: #bbb;
    color: #fff;
    border: 1px #888 solid;
}

/* End of TabSelect */

.bottom10 {
    margin-bottom: 10px;
}

.bottom20 {
    margin-bottom: 20px;
}

.bottom30 {
    margin-bottom: 30px;
}

.top10 {
    margin-top: 10px;
}

.top20 {
    margin-top: 20px;
}

.top30 {
    margin-top: 30px;
}

/* Placements */

.create-placement-form__connection-select {
    display: flex;
}

.create-placement-form__connection-select .btn {
    margin-left: 20px;
}

/* End of placements */

/* Booking */

.dropdown-group-placements {
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
}

.dropdown-group-placements li > a {
    margin-right: 30px;
    clear: none;
    color: #0088cc;
    text-decoration: none;
}

.dropdown-group-placements li > a:hover {
    color: #005580;
    text-decoration: underline;
    background: transparent;
}

.dropdown-group-placements li .close {
    display: none;
    padding: 3px 10px;
}

.dropdown-group-placements li:hover .close {
    display: block;
}

/* End of booking */

/* Bidmanager */

.bidmanager-condition .close {
    visibility: hidden;
}

.bidmanager-condition:hover .close {
    visibility: visible;
}

/* End of Bidmanager */

/* Group table */
.group-item-options {
    width: 28px;
}

.group-item-options .close {
    float: none;
}

.group-item-options .remove {
    margin: 8px;
}

.group-item-options .move-up, .group-item-options .move-down {
    margin: 4px 8px;
}
/* End of group table */

.modal-header .header-inline {
    white-space: nowrap;
}

.modal-header .header-inline h1,h2,h3,h4,h5,h6 {
    padding-right: 20px;
}

.modal-block {
    top: 30%;
    padding: 20px 25px;
}

.modal-block > h4 {
    font-size: 20px;
}

.modal-text-body {
    padding: 8px 0px;
    font-size: 14px;
    line-height: 1.5em;
}

.modal-btn {
    padding: 8px 0px;
    margin: 10px -14px 10px 0px;
}

.project-dl-horizontal dt {
    width: 180px;
}

.ym-competitors .popover {
    white-space: nowrap;
    max-width: none;
}

.ym-competitors .popover p {
    margin-bottom: 0;
}

.tooltip-inner {
    white-space: pre-wrap;
}

/* Permissions */
.permissions-select2-container {
    overflow: auto;
}

.permissions-select2-container .select2-search-choice {
    width: 502px;
}

.sync-source-rule-item {
    margin: 0 10px;
    width: 100%;
}

.sync-source-rule-item:first-child {
    margin-left: 0;
}

.sync-source-rule-item:last-child {
    margin-right: 0;
}

.sync-source-rule-item > input {
    margin-bottom: 0;
    display: block;
}

.sync-source-rule-item > .select2-container {
    display: block;
}

.dropdown-max-width {
    max-width: 364px;
}
.generation-table-key {
    width: 99%;
}

.generation-table-templates {
    margin-bottom: 4px;
}

.remove-template {
    position: relative;
    top: 8px;
}

/* Begin of order metrics */
.metric-label {
    width: 100%;
}
/* End of group table */


/* Begin of YM segmentation */
.dl-table {
    display: table;
}

.dl-table > div.dl-row {
    display: table-row;
}

.dl-table > div.dl-row > div.dl-dt {
    font-weight: 700;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}

.dl-table > div.dl-row > div.dl-dd {
    padding-left: 20px;
    display: table-cell;
    vertical-align: middle;
}
/* End of YM segmentation */

/* Generation Segmentation */
.with-underline, .with-underline:hover {
    text-decoration: underline;
}

.code-input {
    padding: 0 3px 2px;
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
    font-size: 12px;
    color: #333333;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.table-vertical-middle.table td {
    vertical-align: middle;
}

input.generation-table-input, textarea.generation-table-input {
    margin-bottom: 0px;
    width: 99%;
}

input.full-width-input {
    width: 100%;
    height: 28px;
    box-sizing: border-box;
}
/* End of Generation Segmentation */


/* Begin of Select2 icon hack*/
.select2-search-choice-image-left div img:first-child {
     position: absolute;
     top: 1.5px;
}

.select2-search-choice-image-left div {
     padding-left: 18px;
}

/* No close element*/
.select2-search-choice-image-left.select2-locked div img:first-child {
     left: 3px;
}

/* Consider close element*/
.select2-search-choice-image-left:not(.select2-locked) div img:first-child {
     left: 18px;
}
/* End of Select2 icon hack*/


.icon-ok-ex {
    display: inline;
    background-image: url('../_legacyAssets/icon-success.png');
    background-position: 0 0;
    background-repeat: no-repeat;
}

.icon-ok-ex::before {
    content: "";
    width: 14px;
    height: 14px;
}

div.assign-budget-orderperiods {
    margin-top: 8px;
}

.assign-budget-orderperiods label, .assign-budget-orderperiods .button {
    display: inline;
}

.icon-connection-inactivity-reason {
    margin-right: 5px;
}

#breadcrumbs {
    padding-right: 0;
}

.required:after {
    content: " *";
    color: #b94a48;
}

.stretch-flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-flow: row wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.stretch-flexbox-nowrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-wrap: nowrap;
    -ms-flex-direction: row;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.stretch-flexbox * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.form-stacked .field .stretch-flexbox input[type="text"],
.form-stacked .field .stretch-flexbox-nowrap input[type="text"] {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: auto;
}

.box-flexbox {
    margin-right: 20px;
    width: 375px;
}

.box-content .divider {
    *width: 100%;
    height: 1px;
    margin: 9px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 1px solid white
}

.statistics-table-container {
    min-height: 240px;
}

/* imageselect control */

.image-select {
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
}

.image-select div.image-select-tab {
    position: relative;
    margin: 10px;
}

.image-select div.image-select-tab div.close-cross {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: 0;
}

/* imagecrop control */

.modal-body > .crop-container {
    max-height: 400px;
    overflow: hidden
}

.modal-body > .sizes {
    padding-top: 10px;
}

.crop-container {
    max-width: 100%;
}

.crop-container > img {
    width: 100%;
}

.icon-blue {
    color: #16ABE0;
}
.icon-pink {
    color: #D93D7A;
}
.icon-gold {
    color: #9D833E;
}
.icon-azure {
    color: #00B0A3;
}
.icon-purple {
    color: #5E1F6A;
}
.icon-grey {
    color: #969696;
}
.icon-black {
    color: #212129;
}
.icon-dark-blue {
    color: #3B5998;
}

/* mass edit orders */
:root {
    --mass-edit-box-width: 305px;
    --mass-edit-order-blocks-number: 9;
    --mass-edit-orders-number: 1;
    --mass-edit-block-header-background-color: #c4c4c4;
    --mass-edit-block-content-background-color: #f1f1f1;
    --table-border-color: #dddddd;
    --table-th-color: #ffffff;
    --table-sorted-th-color: #d9edf7;
    --base-pills-color: #0088cc;
    --sticky-header-row-offset: 0;
    --sticky-header-in-sticky-column-z-index: 30;
    --mass-edit-sticky-block-height: 0;
    --mass-edit-self-sticky-left: 0;
    /*
    * The values below are being changed from JS.
    * The defaults were somehow calculated from a number of CSS rules and blocks. (* v *)
    * */
    --mass-edit-left-curtain-offset: -16px;
    --mass-edit-left-curtain-width: 76px;
}

.semantic-wrapper {
    display: contents;
}

.mass-edit-box {
    width: var(--mass-edit-box-width);
    height: 100%;
    /* layout header and content as flexes */
    display: flex;
    flex-direction: column;
}

.mass-edit-box .box-header .title {
    display: inline-block;
    width: auto;
    font-size: 12px;
}

.mass-edit-box .box-header {
    padding-top: 8px;
    padding-right: 18px;
    padding-left: 20px;
    padding-bottom: 8px;
    background-color: var(--mass-edit-block-header-background-color);
}

.mass-edit-box .box-content {
    border: 1px solid var(--mass-edit-block-header-background-color);
    background-color: var(--mass-edit-block-content-background-color);
    flex: 1;  /* stretch to parent's height */
}

.mass-edit-orders {
    overflow-x: auto;
    display: grid;
    margin-left: 60px;
    margin-right: 59px;
    grid-template-columns: repeat(var(--mass-edit-orders-number), var(--mass-edit-box-width));
    grid-template-rows: repeat(var(--mass-edit-order-blocks-number), auto);
    grid-auto-flow: column;
    grid-column-gap: 12px;
}

.mass-edit-icons {
    float: right;
    display: block;
    font-size: 12px;
}

.mass-edit-icons i {
    padding-left: 5px;
}

.mass-edit-icons i:hover {
    cursor: pointer;
}

.mass-edit-icons .hide {
    display: none;
}

/* a class to add to `#siblinglist-container` from JS to make sure sticky elements and select2's go underneath it */
.top-z-index {
    z-index: 10000;
}

.mass-edit-orders::before, .mass-edit-orders::after {
    content: '';

    position: absolute;
    top: 0;
    z-index: 3;

    display: block;
    background-color: white;
    height: 100%; /* cover all possible overlapping elements */
}

.mass-edit-orders::before {
    left: var(--mass-edit-left-curtain-offset);
    width: var(--mass-edit-left-curtain-width);
}

.mass-edit-orders::after {
    right: -15px; /* `padding-right` of `.main-content` (15px) */
    width: 74px; /* `margin-right` of `.mass-edit-orders` (59px) + `padding-right` of `.main-content` (15px) */
}

.mass-edit-sticky-block-placeholder {
    display: none;
    width: calc(var(--mass-edit-box-width) - 32px); /* subtract padding and border of each side */
    height: 130px; /* height of two inputs with `margin`s */
}

.mass-edit-sticky-block {
    background-color: var(--mass-edit-block-content-background-color);
}

.fixed-mass-edit-sticky-block {
    position: fixed;

    top: 30px; /* that's the height which is mass-editing block header is being rendered to */
    left: calc(16px + var(--mass-edit-self-sticky-left));

    z-index: 1;
    height: var(--mass-edit-sticky-block-height);
    border-bottom: 1px solid var(--mass-edit-block-header-background-color);

    margin: 0 -15px;
    padding: 15px 15px 25px;
}

.mass-edit-sticky-block-header-placeholder {
    display: none;
    width: var(--mass-edit-box-width);
    height: 31px; /* see `top` of `.fixed-mass-edit-sticky-block` */
}

.fixed-mass-edit-sticky-block-header {
    position: fixed;
    top: 0;

    left: calc(16px + var(--mass-edit-self-sticky-left));

    z-index: 2;
}

.button-group-spacer > *:not(:first-child) {
    margin-left: 5px;
}

.nav-pills .multiple-pills a {
    margin-right: 5px;
}

.nav-pills .multiple-pills.active:not(:first-child) a,
.nav-pills .multiple-pills.active:not(:first-child) a:focus {
    background-color: transparent;
    color: var(--base-pills-color);
    box-shadow: 0 0 0.1px 1px var(--base-pills-color);
}

.baseline {
    align-items: baseline;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -moz-box-align: baseline;
    -ms-flex-align: baseline
}

/* Set the same height for text inputs, select2 with multiple choices and select2 with single choice */
.mediaplan input {
    max-width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
}

.mediaplan .select2-container-multi .select2-choices .select2-search-field input {
    padding: 1px 5px 0 5px;
}

.mediaplan .select2-container-multi {
    margin-bottom: 11px;  /* align error message below */
}

.mediaplan .align-middle {
    padding-top: 9px;
}

.mediaplan .template {
    text-decoration: underline;
    text-transform: lowercase;
}

.mediaplan .fileinput-button input {
    /* Turn off weird style from jquery.fileupload-ui.css because it moves input aside from button */
    transform: none;
}

.mediaplan .fileinput-button input::-webkit-file-upload-button {
    /* https://stackoverflow.com/questions/7554845/the-cursorpointer-property-doesnt-apply-to-file-upload-buttons-in-webkit-brows */
    cursor: pointer;
}

.profile {
    padding-bottom: 10px;
}

.step-container .connection-select {
    table-layout: fixed;
}

.step-container .connection-select .form-table-cell:first-child {
    width: 15px;
}

.step-container .connection-select .form-table-cell:last-child {
    width: 330px;
}

.column-resizer {
    display: inline;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: e-resize;
}

/* columnpicker */
.columnpicker-item-container {
    display: flex;
    align-items: center;
}

.columnpicker-item-container > *:not(:last-child) {
    margin-right: 5px;
}

/* for `dots` indicating draggable elements*/
span.grippy {
    color: gray;
    margin-top: -3px;
    cursor: move;
}


/* new css */
/* element to count string width in px, used in  breadcrumbs */
#ruler {
    visibility: hidden;
    position: absolute;
    white-space: nowrap;
    height: 0;
    overflow: hidden;
}

#page-layout {
    display: grid;

    --sidebar-color: #F6F7F9;
    --topbar-height: 63px;

    /* 3rd row is needed when content length is more than one screen
       then sidebar is fixed and content is scrollable */
    grid-template:
            [row1-start] "sidebar topbar"  var(--topbar-height)               [row1-end]
            [row2-start] "sidebar content" calc(100vh - var(--topbar-height)) [row2-end]
            [row3-start] "   .    content" auto                               [row3-end]
            / auto 1fr
}

#page-layout-sidebar {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    grid-area: sidebar;
    background-color: var(--sidebar-color);
    z-index: 5;
}

#page-layout-topbar {
    grid-area: topbar;
    margin-left: 16px;
    margin-right: 16px;
}

#page-layout-content {
    grid-area: content;
    margin-left: 16px;
    margin-right: 16px;
    /* needed to prevent content overflow one screen by x-axis */
    /* more: https://css-tricks.com/preventing-a-grid-blowout/ */
    min-width: 0;
}

.button {
    transition: 200ms ease-in-out;
    cursor: pointer;
    display: inline-block;
    border-radius: 5px;
    padding: 0 14px;
    height: 32px;
    line-height: 11px;
}

.button.button_disabled {
    cursor: not-allowed;
}

#misha:hover {
    transform: translate(0, -180px) rotate(20deg);
    transition: transform 1000ms;
}

#misha {
    transition: transform 1000ms;
    will-change: transform;
}
