<template>
  <div
    class="login"
    @keypress.enter="submit"
  >
    <div class="login__title">
      {{ loginTitle }}
    </div>
    <div class="login__errors">
      {{ getErrorMessage }}
    </div>
    <template v-if="isRestoringPassword">
      <div
        class="login__restore-text"
        :class="[showSuccessRestore && 'login__restore-text_success']"
      >
        {{ showSuccessRestore ? restoreSuccessText : restorePasswordText }}
      </div>
      <div class="login__input-group">
        <MErrorWrapper
          v-if="!showSuccessRestore"
          :error-object="errors"
          error-path="email"
          frontendValidation
        >
          <MInput
            key="restore-email"
            v-model="email"
            :label="emailLabel"
            type="email"
            name="email"
            autocomplete="email"
            :emit-input-on-change="false"
            :large="true"
          />
        </MErrorWrapper>
      </div>
    </template>
    <template v-else-if="isSettingPassword">
      <div class="login__input-group">
        <MErrorWrapper
          :error-object="errors"
          error-path="password"
        >
          <MInput
            v-model="password"
            :label="_('New password')"
            :type="showPassword ? 'text' : 'password'"
            :large="true"
            :emit-input-on-change="false"
            :disabled="!tokenIsValid"
          />
        </MErrorWrapper>
      </div>
      <div class="login__input-group">
        <MErrorWrapper
          :error-object="errors"
          error-path="password2"
        >
          <MInput
            v-model="password2"
            :label="_('Repeat password')"
            :type="showPassword ? 'text' : 'password'"
            :large="true"
            :emit-input-on-change="false"
            :disabled="!tokenIsValid"
          />
        </MErrorWrapper>
      </div>
    </template>
    <template v-else>
      <form id="login-form">
        <div class="login__input-group">
          <MErrorWrapper
            :error-object="errors"
            error-path="email"
            frontendValidation
          >
            <MInput
              v-model="email"
              :label="emailLabel"
              type="email"
              name="email"
              input-id="email"
              autocomplete="email"
              :emit-input-on-change="false"
              :large="true"
            />
          </MErrorWrapper>
        </div>
        <div class="login__input-group">
          <MErrorWrapper
            :error-object="errors"
            error-path="password"
          >
            <MInput
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              :large="true"
              :icon="getEyeIcon"
              name="password"
              input-id="password"
              autocomplete="password"
              :emit-input-on-change="false"
              @icon-click="showPassword = !showPassword"
            >
              <template v-slot:label>
                <label
                  for="password"
                  class="m-input__label login__password-label"
                >
                  <span>{{ passwordLabel }}</span>
                  <span
                    class="login__forgot-password"
                    @click="isRestoringPassword = true"
                  >{{ _('Forgot password?') }}</span>
                </label>
              </template>
            </MInput>
          </MErrorWrapper>
        </div>
      </form>
    </template>
    <div class="login__controls">
      <template v-if="!isRestoringPassword && !isSettingPassword">
        <MCheckbox
          v-model="rememberMe"
          :label-text="_('Remember me')"
        />
      </template>
      <template v-else-if="isRestoringPassword">
        <span
          class="login__back-button"
          @click="isRestoringPassword = false"
        >{{ backButtonText }}</span>
      </template>
      <template v-else>
        <MCheckbox
          v-model="showPassword"
          :label-text="_('Show password')"
        />
      </template>
      <MButton
        v-if="!showSuccessRestore"
        :is-loading="isLoading"
        :disabled="submitDisabled"
        :large="true"
        @click="submit"
      >
        {{ submitButtonText }}
      </MButton>
    </div>
  </div>
</template>

<script>
  import translation from 'translation';
  import MButton from 'components/common/MButton';
  import MInput from 'modules/common/components/MInput';
  import MErrorWrapper from 'legacy/modules/common/components/MErrorWrapper/MErrorWrapper';
  import MCheckbox from 'modules/common/components/MCheckbox';
  import EyeIcon from 'assets/icons/eye.svg';
  import EyeOffIcon from 'assets/icons/eye-off.svg';
  import {userService} from 'services';
  import {SET_AUTH_TOKEN} from 'store/modules/common/common';
  import Cookie from 'js-cookie';
  import ApiBadRequestError from 'utils/api/errors/ApiBadRequestError';
  import {mapActions} from 'vuex';

  export default {
    name: 'LoginFormView',
    components: {MButton, MInput, MCheckbox, MErrorWrapper},
    props: {
      passwordToken: {
        type: String,
        default: ''
      },
      inviteToken: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        email: '',
        password: '',
        password2: '',
        rememberMe: true,
        ttlDays: 1,
        isLoading: false,
        errors: {},
        isRestoringPassword: false,
        showSuccessRestore: false,
        showPassword: false,
        showPassword2: false,
        tokenIsValid: false
      };
    },
    computed: {
      submitDisabled () {
        const isNotValidToken = this.token && !this.tokenIsValid;
        const isValidInputValue = this.isSettingPassword
          ? this.password && this.password2
          : this.email && (this.isRestoringPassword ? true : this.password);

        return Boolean(isNotValidToken || !isValidInputValue);
      },
      token () {
        return this.passwordToken || this.inviteToken;
      },
      isSettingPassword () {
        return Boolean(this.token);
      },
      getEyeIcon () {
        return this.showPassword ? EyeIcon : EyeOffIcon;
      },
      getErrorMessage () {
        return this.errors.message ? this.errors.message : '';
      },
      loginTitle () {
        if (this.isRestoringPassword) {
          return translation.tr('Restore password');
        } else if (this.isSettingPassword) {
          return translation.tr('New password');
        }
        return translation.tr('Sign in');
      },
      restorePasswordText () {
        return translation.tr('Type your email here and we will send you instructions how to restore your password.');
      },
      restoreSuccessText () {
        return translation.tr('Email with instructions was sent. Please, check your mailbox.');
      },
      emailLabel () {
        return translation.tr('Email');
      },
      passwordLabel () {
        return translation.tr('Password');
      },
      submitButtonText () {
        if (this.isRestoringPassword) {
          return translation.tr('Send');
        }
        return translation.tr('Submit');
      },
      backButtonText () {
        return translation.tr('Back');
      }
    },
    watch: {
      isRestoringPassword () {
        this.errors = {};
        this.showSuccessRestore = false;
      },
      email (val) {
        if ((/[а-яёА-ЯЁ]/ig).test(val)) {
          this.$set(this.errors, 'email', translation.tr('Email cannot contain cyrillic characters'));
        } else {
          this.$set(this.errors, 'email', '');
        }
      }
    },
    async mounted () {
      if (this.passwordToken || this.inviteToken) {
        const token = this.passwordToken || this.inviteToken;
        try {
          await userService.testToken(token);
          this.tokenIsValid = true;
        } catch (e) {
          this.$set(this.errors, 'message', translation.tr('Invalid token'));
        }
      }
    },
    methods: {
      ...mapActions('common', {
        setAuthToken: SET_AUTH_TOKEN
      }),
      async submit () {
        this.errors = {};

        if (this.isRestoringPassword) {
          await this.sendRestore();
        } else if (this.isSettingPassword) {
          await this.setNewPassword();
        } else {
          await this.authUser();
        }
      },
      handleError (e) {
        if (e instanceof ApiBadRequestError) {
          this.errors = e.getValidationErrors();
        } else if (e.errors) {
          this.errors = e.errors;
        } else if (e.originalError.response.data.errors) {
          this.errors = e.originalError.response.data.errors;
        } else {
          console.error(e);
        }
      },
      async authUser () {
        this.isLoading = true;

        if (!this.email) {
          this.$set(this.errors, 'email', translation.tr('Email cannot be empty'));
        }
        if (!this.password) {
          this.$set(this.errors, 'password', translation.tr('Password cannot be empty'));
        }

        if (!this.errors.email && !this.errors.password) {
          try {
            const user = await userService.authorizeUser(this.email, this.password, this.rememberMe);
            if (user) {

              // set marilyn_instance cookie to redirect user to right instance from landing page
              const domain = user.base_url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)[2];
              const domainParts = domain.split('.');

              Cookie.set('marilyn_instance', domain,
                         {domain: domainParts.slice(domainParts.length - 2).join('.')}
              );

              const token = user.token;
              const expires = new Date(user.expiration_time);
              await this.setAuthToken({token, expires});
              window.location.reload();
            }
          } catch (e) {
            if (e instanceof ApiBadRequestError) {
              this.errors = e.getValidationErrors();
            } else {
              this.isLoading = false;
              throw e;
            }
          }
        }

        this.isLoading = false;
      },
      async sendRestore () {
        this.isLoading = true;

        if (!this.email) {
          this.$set(this.errors, 'email', translation.tr('Email cannot be empty'));
        } else {
          try {
            const result = await userService.requestPasswordRestore(this.email);

            if (result && result.status === 200) {
              this.error = {};
              this.showSuccessRestore = true;
            }
          } catch (e) {
            this.handleError(e);
          }
        }

        this.isLoading = false;
      },
      async setNewPassword () {
        this.isLoading = true;

        if (!this.password) {
          this.$set(this.errors, 'password', translation.tr('Password cannot be empty'));
        }
        if (!this.password2) {
          this.$set(this.errors, 'password2', translation.tr('Password cannot be empty'));
        }

        if (!this.errors.password && !this.errors.password2) {
          if (this.password !== this.password2) {
            this.$set(this.errors, 'message', translation.tr('Passwords are different'));
          } else {
            try {
              const user = await userService.resetPassword(
                this.token,
                this.password
              );
              if (user) {
                const token = user.token;
                await this.setAuthToken(token);
                window.location.href = '/';
              }
            } catch (e) {
              this.handleError(e);
            }
          }
        }

        this.isLoading = false;
      }
    }
  };
</script>

<style>
  .login__title {
    font-size: 28px;
    font-weight: 500;

    color: #000000;

    text-align: center;

    margin-bottom: 10px;
  }

  .login__errors {
    font-size: 16px;

    color: var(--error-color);

    text-align: center;

    height: 16px;

    margin-bottom: 10px;
  }

  .login__input-group {
    margin-bottom: 26px;
  }

  .login__input-group:last-of-type {
    margin-bottom: 36px;
  }

  .login__input-group input:focus:invalid:focus {
    color: inherit;
    box-shadow: none;
  }

  .login__input-group .m-input__icon {
    cursor: pointer;

    width: 20px;
    height: 20px;

    padding-bottom: 0;

    margin-right: 4px;
    user-select: none;
  }

  .login__password-label {
    display: flex;
    justify-content: space-between;
  }

  .login__controls {
    display: flex;

    justify-content: space-between;
    align-items: center;
  }

  .login .m-checkbox__label {
    font-weight: 500;
  }

  .login__forgot-password {
    color: var(--main-color);
  }

  .login__forgot-password:hover {
    color: var(--action-hover-color);
  }

  .login__restore-text {
    font-size: 16px;

    text-align: center;

    margin-bottom: 16px;
  }

  .login__restore-text_success {
    color: var(--success-color);
  }

  .login__back-button {
    font-size: 16px;
    font-weight: 500;

    color: var(--main-color);
  }

  .login__back-button:hover {
    color: var(--action-hover-color);
    cursor: pointer;
  }
</style>
