/* todo: this is temporary naming while guideline is not finished */
.header-20 {
  font-size: 20px;
  color: var(--text-main-color);
}

.header-description-14 {
  font-size: 14px;

  color: var(--gray-text-color);

  margin-top: 6px;
}

.header_disabled {
  color: var(--gray-text-color);
}
