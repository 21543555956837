.hint-text {
  font-size: 12px;
  color: var(--secondary-text-color);
}

.error-text {
  font-size: 12px;
  color: var(--error-color);
}

.tabless-page-spacer {
  margin-top: 62px;
}
