.m-tooltip {
  /* stylelint-disable-next-line declaration-no-important */
  display: block !important;
  z-index: 10000000;
}

.m-tooltip .m-tooltip-inner {
  color: var(--secondary-text-color);

  padding: 14px;

  background: var(--background-color);
  box-shadow: var(--box-shadow);

  border: 1px solid var(--border-color);

  border-radius: var(--border-radius);
}

.m-tooltip .m-tooltip-arrow {
  position: absolute;
  z-index: 1;

  width: 10px;
  height: 10px;

  margin: 5px;

  background-color: var(--background-color);
  box-shadow: var(--box-shadow);

  border: solid 1px var(--border-color);

  transform: rotate(45deg);
}

.m-tooltip[x-placement^='top'] {
  margin-bottom: 10px;
}

.m-tooltip[x-placement^='top'] .m-tooltip-arrow {
  bottom: -5px;
  left: calc(50% - 5px);

  margin-top: 0;
  margin-bottom: 0;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.m-tooltip[x-placement^='bottom'] {
  margin-top: 10px;
}

.m-tooltip[x-placement^='bottom'] .m-tooltip-arrow {
  top: -5px;
  left: calc(50% - 5px);

  margin-top: 0;
  margin-bottom: 0;
  clip-path: polygon(0 100%, 0 0, 100% 0);
}

.m-tooltip[x-placement^='right'] {
  margin-left: 10px;
}

.m-tooltip[x-placement^='right'] .m-tooltip-arrow {
  top: calc(50% - 10px);
  left: -5px;

  margin-left: 0;
  margin-right: 0;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}

.m-tooltip[x-placement^='left'] {
  margin-right: 10px;
}

.m-tooltip[x-placement^='left'] .m-tooltip-arrow {
  top: calc(50% - 10px);
  right: -5px;

  margin-left: 0;
  margin-right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.m-tooltip.popover .popover-inner {
  color: #000000;

  padding: 24px;

  background: #F9F9F9;
  box-shadow: 0 5px 30px rgba(#000000, 0.1);

  border-radius: 5px;
}

.m-tooltip.popover .popover-arrow {
  border-color: #F9F9F9;
}

.m-tooltip[aria-hidden='true'] {
  visibility: hidden;

  opacity: 0;

  transition: opacity 150ms, visibility 150ms;
}

.m-tooltip[aria-hidden='false'] {
  visibility: visible;

  opacity: 1;

  transition: opacity 150ms;
}
