.bootstrap-select.btn-group, 
.bootstrap-select.btn-group[class*="span"] {
    float:none; 
    display: inline-block;
    margin-bottom: 10px;
    margin-left:0;
}

.bootstrap-select.btn-group.pull-right, 
.bootstrap-select.btn-group[class*="span"].pull-right, 
.row-fluid .bootstrap-select.btn-group[class*="span"].pull-right {
    float:right;
}

.input-append .bootstrap-select.btn-group {
    margin-left: -1px;
}

.input-prepend .bootstrap-select.btn-group {
    margin-right: -1px;
}

.bootstrap-select:not([class*="span"]) {
    width: 220px;
}

.bootstrap-select {
    width: 220px\9; /*IE8 and below*/
}

.bootstrap-select .btn {
    width: 100%;
}

.bootstrap-select.show-menu-arrow.open .btn {
    z-index:1001;
}

.bootstrap-select .btn:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.bootstrap-select.btn-group .btn .filter-option {
    overflow:hidden; 
    position:absolute;
    left:12px; 
    right:25px;
    text-align:left;
}

.bootstrap-select.btn-group .btn .caret {
    position:absolute;
    right:12px;
}

.bootstrap-select.btn-group > .disabled, 
.bootstrap-select.btn-group .dropdown-menu li.disabled > a {
    cursor: not-allowed;
}

.bootstrap-select.btn-group[class*="span"] .btn {
    width:100%;
}

.bootstrap-select.btn-group .dropdown-menu {
    min-width:100%;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}

.bootstrap-select.btn-group .dropdown-menu dt {
    display:block; 
    padding:3px 20px; 
    cursor:default;
}

.bootstrap-select.btn-group .div-contain {
    overflow:hidden;
}

.bootstrap-select.btn-group .dropdown-menu li > a.opt {
    padding-left:35px;
}

.bootstrap-select.btn-group .dropdown-menu li > a {
    min-height:20px;
    cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li > dt small {
    font-weight:normal;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a i.check-mark { 
    display:inline-block;
    position: absolute;
    right: 15px;
    margin-top: 2.5px;
}

.bootstrap-select.btn-group .dropdown-menu li a i.check-mark { 
    display: none;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}

.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left:0.5em;
}

.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:hover small, 
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:focus small {
    color: #64b1d8; 
    color:rgba(255,255,255,0.4);
}

.bootstrap-select.btn-group .dropdown-menu li > dt small {
    font-weight:normal;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #CCC;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid #ccc;
  border-bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.2);
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid #ffffff;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
    right: 12px;
    left: auto;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
    display: block;
}